<template>
  <v-tooltip v-if="display" bottom>
    <template #activator="{ on }">
      <v-btn icon :disabled="disabled" v-on="on" @click="action">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>
      {{ $t(message) }}
    </span>
  </v-tooltip>
</template>

<script>
export default {
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    display: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Function,
      default: null,
    },
    message: {
      type: String,
      default: '',
    },
  },
  data: () => ({}),
  watch: {},
  created() {},
}
</script>
