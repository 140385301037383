import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store'
import en from '@/lang/en'
import ja from '@/lang/ja'

const messages = { en, ja }

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: store.getters.language || 'en',
  messages: messages,
})
