import Vue from 'vue'
// イベント設定用関数
export function noScroll(event) {
  event.preventDefault()
}

// スクロール禁止
Vue.prototype.$noScroll = function() {
  // SP
  document.addEventListener('touchmove', noScroll, { passive: false })
  // PC
  document.addEventListener('mousewheel', noScroll, { passive: false })
}

// スクロール禁止を解除
Vue.prototype.$returnScroll = function() {
  // SP
  document.removeEventListener('touchmove', noScroll, { passive: false })
  // PC
  document.removeEventListener('mousewheel', noScroll, { passive: false })
}
