<template>
  <v-container>
    <v-card class="pa-9" flat>
      <v-subheader>
        基本情報
      </v-subheader>
      <v-divider class="mb-8" />
      <v-row>
        <v-col>
          <v-form>
            <v-container>
              <ValidationObserver ref="obs" v-slot="{ invalid }">
                <v-row>
                  <v-col cols="6">
                    <VTextFieldWithValidation
                      v-model="updateUserInfo.nickName"
                      rules="required|alpha_num"
                      mode="aggressive"
                      :label="$t('nickName')"
                      :disabled="!editNickName"
                    />
                  </v-col>
                  <v-col cols="2">
                    <prefs-button
                      :display="!editNickName"
                      :action="
                        function() {
                          editNickName = !editNickName
                        }
                      "
                      icon="mdi-pencil"
                      message="editNickName"
                    />
                    <prefs-button
                      :display="editNickName"
                      :disabled="invalid"
                      :action="changeNickName"
                      icon="mdi-check"
                      message="confirm"
                    />
                    <prefs-button
                      :display="editNickName"
                      :action="resetValue"
                      icon="mdi-close"
                      message="cancel"
                    />
                  </v-col>
                </v-row>
              </ValidationObserver>
              <!-- Email変更は現状無し
                <ValidationObserver ref="obs" v-slot="{ invalid }">
                <v-row>
                  <v-col cols="6">
                    <VTextFieldWithValidation
                      v-model="updateUserInfo.email"
                      rules="required|email"
                      :label="$t('email')"
                      mode="aggressive"
                      :disabled="!editEmail"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      v-if="!editEmail"
                      icon
                      @click="editEmail = !editEmail"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="editEmail"
                      icon
                      :disabled="invalid"
                      @click="changeEmail"
                    >
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                    <v-btn v-if="editEmail" icon @click="resetValue()">
                      <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </ValidationObserver> -->

              <ValidationObserver ref="obs" v-slot="{ invalid }">
                <v-row>
                  <v-col cols="6">
                    <VTextFieldWithValidation
                      v-model="updateUserInfo.password"
                      rules="required|confirmed:confirmPassword"
                      :label="$t('password')"
                      mode="aggressive"
                      :disabled="!editPassword"
                      :type="showPassword ? 'text' : 'password'"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                    />
                    <VTextFieldWithValidation
                      v-model="confirmPassword"
                      rules="required"
                      vid="confirmPassword"
                      :label="$t('reEnterPassword')"
                      mode="aggressive"
                      type="password"
                      :disabled="!editPassword"
                    />
                  </v-col>
                  <v-col cols="4">
                    <prefs-button
                      :display="!editPassword"
                      :action="
                        function() {
                          editPassword = !editPassword
                        }
                      "
                      icon="mdi-pencil"
                      message="editPassword"
                    />
                    <prefs-button
                      :display="editPassword"
                      :disabled="invalid"
                      :action="changePassword"
                      icon="mdi-check"
                      message="confirm"
                    />
                    <prefs-button
                      :display="editPassword"
                      :action="resetValue"
                      icon="mdi-close"
                      message="cancel"
                    />
                  </v-col>
                </v-row>
              </ValidationObserver>
            </v-container>
          </v-form>
        </v-col>
      </v-row>
      <v-subheader class="mt-6">
        詳細情報
      </v-subheader>
      <v-divider class="mb-5" />
      <v-container fluid>
        <v-row class="mt-5" justify-center>
          <v-col>
            <v-card flat>
              <v-card-title class="justify-center">
                現在の画像
              </v-card-title>
              <v-card-text class="text-center">
                <img :src="updateUserInfo.photoURL" height="200" width="200" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="d-flex justify-center">
            <upload-profile-image @userPhotoURLIsUpdated="resetValue()" />
          </v-col>
        </v-row>
        <v-row class="mt-5" align="center">
          <v-icon class="mr-2">mdi-web</v-icon>
          <span class="caption mr-4">{{ $t('language') }}</span>
          <v-btn
            class="text-decoration-underline blue--text"
            text
            @click="changeLanguage({ lang: 'ja', i18n: $i18n })"
          >
            {{ $t('japanese') }}
          </v-btn>
          <v-btn
            class="text-decoration-underline blue--text"
            text
            @click="changeLanguage({ lang: 'en', i18n: $i18n })"
          >
            {{ $t('english') }}
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
    <v-snackbar v-model="snackbar.display">
      {{ snackbar.text }}

      <template #action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar.display = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { db } from '@/plugins/firebase'
import { ValidationObserver } from 'vee-validate'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'
import UploadProfileImage from '@/components/UploadProfileImage.vue'
import PrefsButton from '@/components/PrefsButton.vue'

export default {
  components: {
    ValidationObserver,
    VTextFieldWithValidation,
    UploadProfileImage,
    PrefsButton,
  },
  data() {
    return {
      editNickName: false,
      editEmail: false,
      editPassword: false,
      updateUserInfo: {},
      confirmPassword: '',
      showPassword: false,
      snackbar: {
        display: false,
        text: '画像をアップロードしました',
      },
    }
  },
  computed: {
    ...mapGetters(['user', 'userInfo']),
  },
  mounted() {
    this.updateUserInfo = { ...this.userInfo }
  },
  methods: {
    ...mapActions(['changeLanguage', 'setLoginUser']),
    changeNickName() {
      db.collection('langxAccountInfoUsers')
        .doc(this.user.uid)
        .update({
          nickName: this.updateUserInfo.nickName,
        })
        .then(() => {
          this.snackbar = {
            display: true,
            text: this.$t('updateNickName'),
          }
          this.setLoginUser(this.user)
          this.editNickName = !this.editNickName
        })
    },
    changeEmail() {
      this.user
        .updateEmail(this.updateUserInfo.email)
        .then(() => {
          db.collection('langxAccountInfoUsers')
            .doc(this.user.uid)
            .update({
              email: this.updateUserInfo.email,
            })
            .then(() => {
              this.snackbar = {
                display: true,
                text: this.$t('updateEmail'),
              }
              this.setLoginUser(this.user)
              this.editEmail = !this.editEmail
            })
        })
        .catch(err => {
          this.snackbar = {
            display: true,
            text: err,
          }
        })
    },
    changePassword() {
      this.user
        .updatePassword(this.updateUserInfo.password)
        .then(() => {
          this.snackbar = {
            display: true,
            text: this.$t('updatePassword'),
          }
          this.setLoginUser(this.user)
          this.editPassword = !this.editPassword
        })
        .catch(err => {
          this.snackbar = {
            display: true,
            text: err,
          }
        })
    },
    resetValue() {
      this.updateUserInfo = { ...this.userInfo }
      this.updateUserInfo.photoURL = this.user.photoURL
      this.editPassword = false
      this.editEmail = false
      this.editNickName = false
    },
  },
}
</script>
