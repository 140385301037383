<template>
  <v-container>
    <v-row color="transparent">
      <v-col cols="12">
        <ValidationObserver ref="obs" v-slot="{ invalid }">
          <v-card class="px-9" flat tile color="transparent">
            <v-row class="mt-1" justify="center">
              <v-col>
                <v-form>
                  <VTextAreaWithValidation
                    v-model="content"
                    rules="required"
                    :label="$t('groupFeedback')"
                    mode="eager"
                    rows="8"
                  />
                </v-form>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end" cols="12">
                <v-btn
                  class="grad-red white--text"
                  large
                  :disabled="invalid"
                  @click="confirmSubmissionDialog = true"
                >
                  {{ $t('sendGroupFeedback') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </ValidationObserver>
      </v-col>
    </v-row>
    <v-dialog v-model="confirmSubmissionDialog" persistent flat width="600">
      <v-card>
        <v-card-title class="d-flex align-center justify-center lighten-2">
          <p class="text-h6 pt-2">
            {{ $t('confirmGroupFeedback') }}
          </p>
        </v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-banner color="grey lighten-2 pa-2 ma-2 pre-wrap-text">
                {{ content }}
              </v-banner>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                class="grad-red white--text"
                block
                x-large
                @click="submitGroupFeedback"
              >
                {{ $t('sendGroupFeedback') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn block x-large @click="confirmSubmissionDialog = false">
                {{ $t('cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { ValidationObserver } from 'vee-validate'
import VTextAreaWithValidation from '@/components/inputs/VTextAreaWithValidation'
import { mapGetters } from 'vuex'
import { db, storage } from '@/plugins/firebase'
import moment from 'moment'

export default {
  components: {
    ValidationObserver,
    VTextAreaWithValidation,
  },
  props: {
    unit: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      content: '',
      confirmSubmissionDialog: false,
    }
  },
  computed: {
    ...mapGetters(['user', 'userInfo']),
  },
  methods: {
    async submitGroupFeedback() {
      const createdAt = Date.now()
      const groupFeedback = {
        unitId: this.unit.unitId,
        tutorId: this.userInfo.userId,
        tutorLangxId: this.userInfo.langxId,
        createdAt: createdAt,
        comments: {},
        content: this.content.replaceAll('\n', '\\n'),
      }
      const headerBar = '================== GROUP FEEDBACK ==================\n'
      const infoBar =
        '\n======================= INFO =======================\n\n'
      const sectionBar =
        '\n====================== CONTENT =====================\n'
      const footerBar =
        '\n\n===================================================='
      const textContent =
        headerBar +
        infoBar +
        `CLASS: ${this.unit.classId}\n` +
        `UNIT: ${this.unit.unitNo}\n` +
        `TITLE: ${this.unit.unitTitle}\n` +
        `DATE: ${this.unit.date} ${this.unit.startAt} - ${this.unit.endAt}\n` +
        `CREATED AT: ${moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}\n` +
        `TUTOR: ${this.unit.tutor.firstName} ${this.unit.tutor.lastName}\n` +
        `LEARNERS:\n${Object.values(this.unit.learners)
          .map(learner => learner.firstName + ' ' + learner.lastName)
          .join('\n')}\n` +
        sectionBar +
        `\n${this.content}` +
        footerBar
      const blob = new Blob([textContent], { type: 'text/plain' })
      await db
        .collection('langxLearningContentGroupFeedbacks')
        .doc(this.unit.unitId)
        .set(groupFeedback)
      await storage
        .child(
          `groupFeedbackRecords/${
            this.unit.classId
          }/Unit_${this.unit.unitNo.toString().padStart(2, '0')}.txt`
        )
        .put(blob)
      groupFeedback.unit = this.unit
      this.$emit('submitGroupFeedback', groupFeedback)
    },
  },
}
</script>

<style scoped>
.pre-wrap-text {
  white-space: pre-wrap;
}
</style>
