<template>
  <v-layout justify-center>
    <v-sheet height="500" width="500">
      <v-avatar color="blue" width="90%" height="90%">
        <v-container>
          <v-layout justify-center>
            <v-card color="blue" flat max-width="100%">
              <v-container>
                <v-row justify="center" align-content="center">
                  <v-col align-self="center" cols="6">
                    <v-img src="@/assets/empty.png" />
                  </v-col>
                </v-row>
                <v-row class="white--text text-h6" justify="center">
                  レッスンがありません。
                </v-row>
                <v-row class="white--text text-subtitle-1" justify="center">
                  クラス登録されるまでお待ち下さい。
                </v-row>
              </v-container>
            </v-card>
          </v-layout>
        </v-container>
      </v-avatar>
    </v-sheet>
  </v-layout>
</template>
<script>
export default {
  props: {},
  data: () => {
    return {}
  },
  mounted() {},
  methods: {},
}
</script>
<style scoped></style>
