import { functions } from '@/plugins/firebase'

// GET ACCESS JSON WEB TOKEN
const getJSONWebToken = async () => {
  const getAccessJSONWebToken = await functions.httpsCallable(
    'getAccessJSONWebTokenFromMMS'
  )
  const accessJSONWebToken = await getAccessJSONWebToken({
    username: process.env.VUE_APP_MMS_USERNAME,
    password: process.env.VUE_APP_MMS_PASSWORD,
  })
  return accessJSONWebToken.data.data.token
}

// CREATE NEW MEETING
export const createNewMeeting = async newMeeting => {
  const createMeetingInMMS = await functions.httpsCallable('createMeetingInMMS')
  if (newMeeting.startAt) {
    newMeeting.startAt = newMeeting.startAt.toISOString()
  }
  const accessJSONWebToken = await getJSONWebToken()
  const createdMeeting = await createMeetingInMMS({
    newMeeting: newMeeting,
    accessJSONWebToken: accessJSONWebToken,
  })
  return createdMeeting.data.data
}

// UPDATE AND RESCHEDULE EDITED MEETING
export const updateEditedMeeting = async (meetingId, editedMeeting) => {
  const updateMeetingInMMS = await functions.httpsCallable('updateMeetingInMMS')
  if (editedMeeting.startAt) {
    editedMeeting.startAt = editedMeeting.startAt
      .toLocaleString({ timeZone: 'UTC' })
      .toISOString()
  }
  const accessJSONWebToken = await getJSONWebToken()
  const updatedMeeting = await updateMeetingInMMS({
    meetingId: meetingId,
    editedMeeting: editedMeeting,
    accessJSONWebToken: accessJSONWebToken,
  })
  return updatedMeeting.data.data
}

// CANCEL MEETING SCHEDULE
export const cancelMeetingSchedule = async meetingId => {
  const cancelMeetingScheduleInMMS = await functions.httpsCallable(
    'cancelMeetingScheduleInMMS'
  )
  const accessJSONWebToken = await getJSONWebToken()
  const resultMessage = await cancelMeetingScheduleInMMS({
    meetingId: meetingId,
    accessJSONWebToken: accessJSONWebToken,
  })
  return resultMessage.data.data.message
}

// STOP MEETING RECORDING IMMEDIATELY
export const stopMeetingRecording = async meetingId => {
  const stopMeetingRecordingInMMS = await functions.httpsCallable(
    'stopMeetingRecordingInMMS'
  )
  const accessJSONWebToken = await getJSONWebToken()
  const resultMessage = await stopMeetingRecordingInMMS({
    meetingId: meetingId,
    accessJSONWebToken: accessJSONWebToken,
  })
  return resultMessage.data.data.message
}
