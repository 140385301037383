<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="8" md="4">
      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <v-card class="elevation-12 pa-9">
          <v-row class="mt-1 mb-5" justify="center">
            <v-toolbar-title>{{ $t('signIn') }}</v-toolbar-title>
          </v-row>
          <v-row>
            <v-col>
              <v-form>
                <VTextFieldWithValidation
                  v-model="user.email"
                  rules="required|email"
                  :label="$t('email')"
                  mode="eager"
                />
                <VTextFieldWithValidation
                  v-model="user.password"
                  rules="required"
                  :label="$t('password')"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  mode="eager"
                  @click:append="showPassword = !showPassword"
                />
              </v-form>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="red--text">
              {{ authError }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="justify-center">
              <v-btn
                class="grad-red white--text"
                large
                block
                :disabled="invalid"
                @click="signInWithEmailAndPassword(user)"
              >
                {{ $t('signIn') }}
              </v-btn>
            </v-col>
          </v-row>
          <!--
            <v-card-text>
              {{ $t('createNewAccount') }}
              <v-btn
                class="text-decoration-underline blue--text"
                text
                @click="changeCurrentView('SignUp')"
              >
                {{ $t('signUp') }}
              </v-btn>
            </v-card-text>
            -->
          <v-row class="mt-5" align="center" justify="center">
            <v-icon class="mr-2">mdi-web</v-icon>
            <span class="caption mr-4">{{ $t('language') }}</span>
            <v-btn
              class="text-decoration-underline blue--text"
              text
              @click="changeLanguage({ lang: 'ja', i18n: $i18n })"
            >
              {{ $t('japanese') }}
            </v-btn>
            <v-btn
              class="text-decoration-underline blue--text"
              text
              @click="changeLanguage({ lang: 'en', i18n: $i18n })"
            >
              {{ $t('english') }}
            </v-btn>
          </v-row>
        </v-card>
      </ValidationObserver>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import VTextFieldWithValidation from './inputs/VTextFieldWithValidation'

export default {
  components: {
    ValidationObserver,
    VTextFieldWithValidation,
  },
  data() {
    return {
      user: {
        email: '',
        password: '',
      },
      showPassword: false,
    }
  },
  computed: {
    ...mapState(['view', 'authError']),
    CurrentView: {
      get() {
        return this.view
      },
      set(value) {
        this.changeCurrentView(value)
      },
    },
  },
  methods: {
    ...mapActions([
      'signInWithEmailAndPassword',
      'changeLanguage',
      'changeCurrentView',
    ]),
  },
}
</script>
