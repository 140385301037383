<template>
  <v-container>
    <v-data-table
      :items="unitInfoList"
      :headers="headers"
      class="elevation-1"
      :loading="loading"
    >
      <template #[`item.isAssessmentOfUnits`]="{ item }">
        <v-simple-checkbox v-model="item.isAssessmentOfUnits" disabled />
      </template>
    </v-data-table>
    <v-btn class="grad-red white--text" @click="getCourseData">
      コース情報取得
    </v-btn>
    <v-btn class="grad-red white--text" @click="submitCourses">
      コースデータ登録
    </v-btn>
  </v-container>
</template>

<script>
import { db } from '@/plugins/firebase'
import courseData from '@/assets/Course.json'
export default {
  name: 'CourseManagement',
  data: () => {
    return {
      unitInfoList: [],
      message: '',
      loading: false,
      headers: [
        { text: 'Unit master ID', value: 'unitMasterId' },
        { text: 'Course title', value: 'courseTitle' },
        { text: 'No.', value: 'unitNo' },
        { text: 'Title', value: 'unitTitle' },
        { text: 'CEFR', value: 'unitCEFR' },
        { text: 'Scale', value: 'unitScale' },
        { text: 'Cando', value: 'unitCanDo' },
        { text: 'Assessment of units', value: 'isAssessmentOfUnits' },
      ],
    }
  },
  methods: {
    getCourseData() {
      this.loading = true
      courseData.courses.forEach(course => {
        course.units.forEach(unit => {
          const unitInfo = unit
          unitInfo.unitMasterId =
            course.courseTitle.toLowerCase() +
            '-' +
            String(unit.unitNo).padStart(2, '0')
          unitInfo.courseTitle = course.courseTitle
          this.unitInfoList.push(unitInfo)
        })
      })
      this.loading = false
    },
    async submitCourses() {
      this.loading = true
      const courseData = this.unitInfoList
      for (const unitData of courseData) {
        await db
          .collection('langxLearningContentCourses')
          .doc(unitData.unitMasterId)
          .set(unitData)
      }
      this.loading = false
    },
  },
}
</script>
