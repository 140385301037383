<template>
  <div>
    <v-card flat>
      <croppa
        v-model="myCroppa"
        :width="200"
        :height="200"
        :show-remove-button="true"
        remove-button-color="black"
        :show-loading="true"
        :file-size-limit="2097152"
        accept=".png,.jpg,.gif"
        @file-type-mismatch="onFileTypeMismatch"
        @file-size-exceed="onFileSizeExceed"
      />
      <v-card-actions class="justify-center">
        <v-btn :disabled="!myCroppa.chosenFile" @click="uploadResizedPhoto">
          {{ $t('uploadFile') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar.display">
      {{ snackbar.text }}

      <template #action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar.display = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Croppa from 'vue-croppa'
import 'vue-croppa/dist/vue-croppa.css'
import firebase, { storage } from '@/plugins/firebase'
Vue.use(Croppa)

export default {
  name: 'UploadResizedPhoto',
  data() {
    return {
      myCroppa: {},
      dataUrl: '',
      snackbar: {
        display: false,
        text: '画像をアップロードしました',
      },
    }
  },
  computed: {
    ...mapGetters(['user', 'userInfo']),
  },
  methods: {
    onFileTypeMismatch(file) {
      alert(this.$t('validateImageType'))
    },
    onFileSizeExceed(file) {
      alert(this.$t('validateImageSizeMax'))
    },
    uploadResizedPhoto() {
      const user = firebase.auth().currentUser
      const path = 'userData/' + this.userInfo.langxId + '/images/photo.png'
      const photoImagesRef = storage.child(path)
      // generateBlobで、画像からblobオブジェクトを作成
      // blobオブジェクトをそのままputメソッドでCloud Storageにアップ
      this.myCroppa.generateBlob(blob => {
        photoImagesRef
          .put(blob)
          .then(snapshot => {
            // アップが完了したら、ダウンロードURLを取得
            // UserプロパティのphotoURLに書き込み
            photoImagesRef.getDownloadURL().then(downloadURL => {
              const photoURL = downloadURL
              if (user) {
                user
                  .updateProfile({
                    photoURL: photoURL,
                  })
                  .then(() => {
                    this.snackbar = {
                      display: true,
                      text: 'プロフィールの更新に成功しました。',
                    }
                    this.$store.dispatch('setLoginUser', user)
                    this.$store.dispatch('updateUserPhotoURL', user)
                    this.myCroppa.remove()
                    this.$emit('userPhotoURLIsUpdated')
                  })
                  .catch(error => {
                    this.snackbar = {
                      display: true,
                      text: 'プロフィールの更新に失敗しました：' + error,
                    }
                  })
              }
            })
          })
          .catch(err => {
            this.snackbar = {
              display: true,
              text: 'プロフィールの更新に失敗しました：' + err,
            }
          })
      })
    },
  },
}
</script>

<style scoped></style>
