import firebase from 'firebase/app'
require('firebase/firestore')
require('firebase/auth')
require('firebase/functions')

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: 'AIzaSyCS0Y9eJL0YmKVYor8FiBYjdTQiU51Mk9c',
    authDomain: 'teai-2019.firebaseapp.com',
    databaseURL: 'https://teai-2019.firebaseio.com',
    projectId: 'teai-2019',
    storageBucket: 'teai-2019.appspot.com',
    messagingSenderId: '1:281518576708:web:39a171b8ec19a12b4b5811',
  })
}
export default firebase
export const db = firebase.firestore()
export const storage = firebase.storage().ref()
export const functions = firebase.app().functions('asia-northeast1')
export const database = firebase.database()
