<template>
  <v-card flat tile color="transparent" class="px-9">
    <v-row>
      <v-col cols="1" class="d-flex align-center justify-center flex-column">
        <v-avatar>
          <img :src="sender.photoURL" alt />
        </v-avatar>
        <span class="text-caption">{{ sender.nickName }}</span>
      </v-col>
      <v-col cols="11">
        <v-card flat tile outlined class="pa-5">
          <span class="pre-wrap-text text-body-2">
            {{ comment.content.replaceAll('\\n', '\n') }}
          </span>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    comment: {
      type: Object,
      default: () => {},
    },
    sender: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
<style scoped>
.pre-wrap-text {
  white-space: pre-wrap;
}
</style>
