<template>
  <v-container>
    <v-row align="center">
      <v-divider />
      <v-subheader>
        {{ $t('userManagement') }}
      </v-subheader>
      <v-divider />
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card-title>
          {{ $t('users') }}
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-card-title>
        <v-data-table
          dense
          :items="userList"
          :headers="headers"
          :items-per-page="5"
          :search="search"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
          }"
        >
          <template #[`item.photoURL`]="{ item }">
            <v-avatar class="ma-2">
              <img :src="item.photoURL" />
            </v-avatar>
          </template>
          <template #[`item.classIds`]="{ item }">
            <v-chip
              v-for="oneClass in item.classIds"
              :key="oneClass"
              color="deep-purple lighten-2"
              class="ma-1 text-caption"
              outlined
              pill
            >
              {{ oneClass }}
            </v-chip>
          </template>
          <template #[`item.userType`]="{ item }">
            <v-edit-dialog
              :return-value.sync="item.userType"
              :cancel-text="$t('cancel')"
              :save-text="$t('update')"
              large
              persistent
              @save="updateUserType(item)"
            >
              {{ item.userType }}
              <template #input>
                <div class="text-caption mt-1">
                  {{ $t('updateUserType') }}
                </div>
                <v-select
                  v-model="item.userType"
                  :items="userTypes"
                  dense
                  autofocus
                />
              </template>
            </v-edit-dialog>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackColor">
      {{ message }}

      <template #action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { db } from '@/plugins/firebase'
export default {
  name: 'UserManagement',
  data: () => {
    return {
      headers: [
        { text: 'LANGX ID', value: 'langxId', align: 'start' },
        { text: 'Photo', value: 'photoURL' },
        { text: 'Nickname', value: 'nickName' },
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Class', value: 'classIds' },
        { text: 'User Type', value: 'userType' },
      ],
      userList: [],
      userTypes: ['Learner', 'Tutor', 'Administrator'],
      search: '',
      message: '',
      snackbar: false,
      snackColor: null,
      timeout: 2000,
    }
  },
  async mounted() {
    const usersRef = db.collection('langxAccountInfoUsers')
    const usersQuerySnapshot = await usersRef.get()
    this.userList = await usersQuerySnapshot.docs.map(doc => doc.data())
  },
  methods: {
    updateUserType(userInfo) {
      const updatedUsersRef = db
        .collection('langxAccountInfoUsers')
        .doc(userInfo.userId)
      updatedUsersRef
        .update({ userType: userInfo.userType })
        .then(() => {
          this.message = `${userInfo.firstName +
            ' ' +
            userInfo.lastName}'s userType has been changed to ${
            userInfo.userType
          }`
          this.snackColor = 'success'
          this.snackbar = true
        })
        .catch(error => {
          this.message = error
          this.snackColor = 'error'
          this.snackbar = true
        })
    },
  },
}
</script>
