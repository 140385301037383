<template color="#f9f9f9">
  <v-card color="transparent" flat tile width="100%">
    <v-row>
      <v-col cols="12">
        <v-card color="transparent" flat tile class="pa-2">
          <v-container>
            <v-row align="center" class="px-2">
              <v-divider />
              <v-subheader>
                Monitor with meetingId
              </v-subheader>
              <v-divider />
            </v-row>
            <v-row class="px-2">
              <v-list flat tile width="100%">
                <v-list-item-group>
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field v-model="meetingId" label="Meeting ID" />
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        icon
                        :disabled="!meetingId"
                        @click="openMonitor(meetingId, dummyUnit)"
                      >
                        <v-icon color="grey lighten-1">
                          mdi-open-in-new
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card color="transparent" flat tile class="pa-2">
          <v-container>
            <v-row align="center" class="px-2">
              <v-divider />
              <v-subheader>
                Ongoing meetings
              </v-subheader>
              <v-divider />
            </v-row>
            <v-row v-if="Object.keys(ongoingUnits).length > 0" class="px-2">
              <v-list two-line width="100%">
                <v-list-item-group>
                  <template v-for="(oneUnit, unitId, index) in ongoingUnits">
                    <v-list-item
                      :key="unitId"
                      @click="openMonitor(oneUnit.meetingId, oneUnit)"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="
                            `${oneUnit.classId}, Unit ${oneUnit.unitNo}: ${oneUnit.unitTitle}`
                          "
                        />
                        <v-list-item-subtitle class="text--primary">
                          <v-icon>
                            mdi-clock-outlined
                          </v-icon>
                          {{ oneUnit.startAtDateTime }} -
                          {{ oneUnit.endAtDateTime }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon color="grey lighten-1">
                          mdi-open-in-new
                        </v-icon>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider
                      v-if="index < Object.keys(ongoingUnits).length - 1"
                      :key="index"
                    />
                  </template>
                </v-list-item-group>
              </v-list>
            </v-row>
            <v-row v-else>
              <v-col cols="12" class="d-flex align-center justify-center">
                <v-progress-circular
                  indeterminate
                  color="#1791b1"
                  size="50"
                  width="2"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card color="transparent" flat tile class="pa-2">
          <v-container>
            <v-row align="center" class="px-2">
              <v-divider />
              <v-subheader>
                Upcoming meetings
              </v-subheader>
              <v-divider />
            </v-row>
            <v-row v-if="Object.keys(upcomingUnits).length > 0" class="px-2">
              <v-list two-line width="100%">
                <v-list-item-group>
                  <template v-for="(oneUnit, unitId, index) in upcomingUnits">
                    <v-list-item
                      :key="unitId"
                      @click="openMonitor(oneUnit.meetingId, oneUnit)"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="
                            `${oneUnit.classId}, Unit ${oneUnit.unitNo}: ${oneUnit.unitTitle}`
                          "
                        />
                        <v-list-item-subtitle class="text--primary">
                          <v-icon>
                            mdi-clock-outlined
                          </v-icon>
                          {{ oneUnit.startAtDateTime }} -
                          {{ oneUnit.endAtDateTime }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon color="grey lighten-1">
                          mdi-open-in-new
                        </v-icon>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider
                      v-if="index < Object.keys(upcomingUnits).length - 1"
                      :key="index"
                    />
                  </template>
                </v-list-item-group>
              </v-list>
            </v-row>
            <v-row v-else>
              <v-col cols="12" class="d-flex align-center justify-center">
                <v-progress-circular
                  indeterminate
                  color="#1791b1"
                  size="50"
                  width="2"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="errorSnackbar" timeout="3000">
      {{ errorMessage }}
      <template #action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="errorSnackbar = false">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { db } from '@/plugins/firebase'
import { mapActions } from 'vuex'
import moment from 'moment'
export default {
  name: 'MonitorMeeting',
  data: () => {
    return {
      meetingId: '',
      allUnits: {},
      ongoingUnits: {},
      upcomingUnits: {},
      dummyUnit: {
        unitId: 'dummy',
      },
      updateTimer: null,
      errorSnackbar: false,
      errorMessage: '',
    }
  },
  beforeDestroy() {
    clearInterval(this.updateTimer)
  },
  mounted() {
    db.collection('langxLearningContentUnits')
      .get()
      .then(async querySnapshot => {
        await querySnapshot.forEach(async doc => {
          const oneUnitId = await doc.id
          const oneUnit = await doc.data()
          this.allUnits[oneUnitId] = oneUnit
        })
        this.setOngoingAndUpcomingUnits()
        this.updateTimer = setInterval(() => {
          this.setOngoingAndUpcomingUnits()
        }, 5000)
      })
      .catch(error => {
        this.errorMessage = error
      })
  },
  methods: {
    ...mapActions(['setCurrentUnitInfo']),
    setOngoingAndUpcomingUnits() {
      const nowDateTime = new Date()
      for (const unitId in this.allUnits) {
        const oneUnit = this.allUnits[unitId]
        const startAtTimeWithSpare = moment(
          oneUnit.date + ' ' + oneUnit.startAt
        ).add(-15, 'm')
        const endAtTimeWithExtra = moment(oneUnit.date + ' ' + oneUnit.startAt)
          .add(1, 'h')
          .add(45, 'm')
        const willStartAtTime = moment(
          oneUnit.date + ' ' + oneUnit.startAt
        ).add(-1, 'h')
        oneUnit.startAtDateTime = moment(
          oneUnit.date + ' ' + oneUnit.startAt
        ).format('YYYY/MM/DD HH:mm')
        oneUnit.endAtDateTime = moment(oneUnit.date + ' ' + oneUnit.startAt)
          .add(1, 'h')
          .add(30, 'm')
          .format('HH:mm')
        if (
          nowDateTime >= startAtTimeWithSpare &&
          nowDateTime <= endAtTimeWithExtra
        ) {
          if (
            this.upcomingUnits &&
            Object.prototype.hasOwnProperty.call(this.upcomingUnits, unitId)
          ) {
            delete this.upcomingUnits[unitId]
          }
          if (
            this.ongoingUnits &&
            !Object.prototype.hasOwnProperty.call(this.ongoingUnits, unitId)
          ) {
            this.$set(this.ongoingUnits, unitId, oneUnit)
          }
        } else if (
          nowDateTime >= willStartAtTime &&
          nowDateTime <= startAtTimeWithSpare
        ) {
          if (
            this.upcomingUnits &&
            !Object.prototype.hasOwnProperty.call(this.upcomingUnits, unitId)
          ) {
            this.$set(this.upcomingUnits, unitId, oneUnit)
          }
        } else {
          if (
            this.ongoingUnits &&
            Object.prototype.hasOwnProperty.call(this.ongoingUnits, unitId)
          ) {
            delete this.ongoingUnits[unitId]
          }
          if (
            this.upcomingUnits &&
            Object.prototype.hasOwnProperty.call(this.upcomingUnits, unitId)
          ) {
            delete this.upcomingUnits[unitId]
          }
        }
      }
    },
    openMonitor(meetingId, unit) {
      this.setCurrentUnitInfo(unit)
      const resolvedRoute = this.$router.resolve({
        name: 'Monitor',
        params: { id: meetingId },
      })
      const sw = screen.width
      const sh = screen.height
      window.open(
        resolvedRoute.href,
        'Monitor',
        'left=0, top=0, width=' + sw + ', height=' + sh + ''
      )
    },
  },
}
</script>
