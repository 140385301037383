<template>
  <v-card flat tile color="transparent" class="px-9">
    <ValidationObserver ref="obs" v-slot="{ invalid }">
      <v-row class="mt-1" justify="center">
        <v-col cols="1" class="d-flex align-center justify-center flex-column">
          <v-avatar>
            <img :src="userInfo.photoURL" alt />
          </v-avatar>
          <span class="text-caption">{{ userInfo.nickName }}</span>
        </v-col>
        <v-col>
          <v-form>
            <VTextAreaWithValidation
              v-model="content"
              rules="required"
              :label="$t('comment')"
              mode="lazy"
              rows="3"
            />
          </v-form>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0">
        <v-col class="d-flex justify-end" cols="12">
          <v-btn
            class="grad-blue white--text ma-0"
            :disabled="invalid"
            @click="submitComment"
          >
            <v-icon>
              mdi-send
            </v-icon>
            {{ $t('sendComment') }}
          </v-btn>
        </v-col>
      </v-row>
    </ValidationObserver>
  </v-card>
</template>
<script>
import { ValidationObserver } from 'vee-validate'
import VTextAreaWithValidation from '@/components/inputs/VTextAreaWithValidation'
import { mapGetters } from 'vuex'
import firebase, { db } from '@/plugins/firebase'

export default {
  components: {
    ValidationObserver,
    VTextAreaWithValidation,
  },
  props: {
    groupFeedback: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      content: '',
    }
  },
  computed: {
    ...mapGetters(['user', 'userInfo']),
  },
  methods: {
    submitComment() {
      const createdAt = Date.now()
      const comment = {
        userId: this.userInfo.userId,
        content: this.content.replaceAll('\n', '\\n'),
        createdAt: createdAt,
      }
      db.collection('langxLearningContentGroupFeedbacks')
        .doc(this.groupFeedback.unitId)
        .update({ comments: firebase.firestore.FieldValue.arrayUnion(comment) })
        .then(() => {
          this.content = ''
          this.$emit('sentComment')
        })
        .catch(error => {
          alert(error)
        })
    },
  },
}
</script>
