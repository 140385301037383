<template>
  <v-app>
    <v-app-bar app class="white--text" color="#312f30" flat>
      <h3>
        Interview
      </h3>
      <v-spacer />
    </v-app-bar>
    <v-main class="bg">
      <v-container fill-height>
        <div
          id="videoContainer"
          v-resize="onResize"
          class="d-flex flex-wrap align-content-center justify-center mb-6 video--container"
        />
      </v-container>
    </v-main>
    <!-- footer tool -->
    <v-footer app absolute color="#312f30">
      <v-card color="transparent" flat tile width="100%">
        <v-row>
          <v-col class="d-flex justify-end" cols="12">
            <v-btn
              class="ma-2 px-5 grad-red white--text"
              depressed
              plain
              @click="confirmExitMeetingDialog = true"
            >
              <v-card color="transparent" flat tile>
                <div class="white--text">
                  {{ $t('leaveMeeting') }}
                </div>
              </v-card>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-footer>
    <v-dialog v-model="checkAudioVideoDialog" persistent dark flat width="500">
      <v-card>
        <v-card-title class="text-h5 lighten-2">
          {{ $t('testMicrophoneAndCamera') }}
        </v-card-title>
        <v-container>
          <v-row class="test--row">
            <v-col
              :cols="6"
              class="d-flex flex-wrap align-content-center justify-center"
            >
              <div id="cameraCheckWindow" />
            </v-col>
            <v-col
              :cols="6"
              class="d-flex flex-wrap align-content-center justify-center"
            >
              <v-select
                v-model="selectedCameraId"
                :items="cameras"
                item-text="label"
                item-value="deviceId"
                :rules="[v => !!v || $t('cameraIsRequired')]"
                :label="$t('selectCamera')"
                required
                @change="createTestStream()"
              />
            </v-col>
          </v-row>
          <v-row class="test--row">
            <v-col
              id="microphoneCheckWindow"
              :cols="6"
              class="d-flex flex-wrap align-content-center justify-space-around"
            >
              <v-icon color="#ffffff">
                {{
                  selectedMicrophoneId ? 'mdi-microphone' : 'mdi-microphone-off'
                }}
              </v-icon>
              <div v-for="n in parseInt(10)" :key="n" class="sound--bar" />
            </v-col>
            <v-col
              :cols="6"
              class="d-flex flex-wrap align-content-center justify-center"
            >
              <v-select
                v-model="selectedMicrophoneId"
                :items="microphones"
                item-text="label"
                item-value="deviceId"
                :rules="[v => !!v || $t('microphoneIsRequired')]"
                :label="$t('selectMicrophone')"
                required
                @change="createTestStream()"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="joinMeetingRoom()">
            {{ $t('join') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmExitMeetingDialog"
      persistent
      dark
      flat
      width="600"
    >
      <v-card>
        <v-card-title class="d-flex align-center justify-center lighten-2">
          <p class="text-h6 pt-2">
            退出する場合にはウィンドウをそのまま閉じてください。
          </p>
        </v-card-title>
        <v-container>
          <v-row>
            <v-col :cols="12">
              <v-btn block x-large @click="confirmExitMeetingDialog = false">
                {{ $t('cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="errorSnackbar" timeout="3000">
      {{ errorMessage }}
      <template #action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="errorSnackbar = false">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import AgoraRTC from 'agora-rtc-sdk'

export default {
  name: 'Interview',
  data: () => {
    return {
      checkAudioVideoDialog: true,
      selectedCameraId: null,
      cameras: [],
      selectedMicrophoneId: null,
      microphones: [],
      testStreamId: 123456789,
      testStream: null,
      audioTestTimer: null,
      microphoneDeviceEnabled: {
        type: Boolean,
        default: true,
      },
      cameraDeviceEnabled: {
        type: Boolean,
        default: true,
      },
      meetingId: '',
      localStreamId: 0,
      client: AgoraRTC.createClient({
        mode: 'live',
        codec: 'h264',
      }),
      localStream: null,
      errorSnackbar: false,
      errorMessage: '',
      confirmExitMeetingDialog: false,
    }
  },
  mounted() {
    this.$noScroll()
    this.meetingId = this.$route.params.id
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(() => {
        AgoraRTC.getDevices(devices => {
          this.microphones = devices.filter(device => {
            return device.kind === 'audioinput'
          })
          this.cameras = devices.filter(device => {
            return device.kind === 'videoinput'
          })
        })
      })
      .catch(() => {
        this.errorMessage =
          'ブラウザの設定でこのサイトのカメラとマイクへのアクセスを許可してください。'
        this.errorSnackbar = true
      })
    this.onResize()
  },
  destroyed() {
    this.$returnScroll()
  },
  updated() {
    if (this.newMessage) {
      this.resizeChatWindow()
      this.newMessage = false
    }
  },
  methods: {
    createTestStream() {
      if (this.testStream) {
        this.testStream.close()
        const testVideoDiv = document.getElementById('testVideo')
        testVideoDiv.remove()
      }
      if (this.audioTestTimer) {
        clearInterval(this.audioTestTimer)
      }
      this.testStream = AgoraRTC.createStream({
        streamID: this.testStreamId,
        audio: !!this.selectedMicrophoneId,
        microphoneId: this.selectedMicrophoneId
          ? this.selectedMicrophoneId
          : null,
        video: !!this.selectedCameraId,
        cameraId: this.selectedCameraId ? this.selectedCameraId : null,
        screen: false,
      })
      this.testStream.init(() => {
        const microphoneCheckWindow = document.getElementById(
          'microphoneCheckWindow'
        )
        this.audioTestTimer = setInterval(() => {
          const soundLevel = Math.floor(this.testStream.getAudioLevel() * 10)
          let level = 0
          for (level; level < soundLevel; level++) {
            microphoneCheckWindow.children[level + 1].style.backgroundColor =
              '#b80e65'
          }
          for (level; level < 10; level++) {
            microphoneCheckWindow.children[level + 1].style.backgroundColor =
              '#312f30'
          }
        }, 100)
        const cameraCheckWindow = document.getElementById('cameraCheckWindow')
        const testVideoDiv = document.createElement('div')
        testVideoDiv.id = 'testVideo'
        testVideoDiv.style.transform = 'rotateY(180deg)'
        testVideoDiv.style.width = '200px'
        testVideoDiv.style.height = '150px'
        testVideoDiv.style.margin = '5px'
        cameraCheckWindow.appendChild(testVideoDiv)
        this.testStream.setScreenProfile('480p_2')
        this.testStream.play('testVideo')
      })
    },
    joinMeetingRoom() {
      if (this.selectedCameraId && this.selectedMicrophoneId) {
        this.testStream.close()
        const testVideoDiv = document.getElementById('testVideo')
        testVideoDiv.remove()
        clearInterval(this.audioTestTimer)
        this.checkAudioVideoDialog = false
        this.localStream = AgoraRTC.createStream({
          streamID: 'local',
          audio: true,
          microphoneId: this.selectedMicrophoneId,
          video: true,
          cameraId: this.selectedCameraId,
        })
        this.joinMeeting()
        this.onResize()
      }
    },
    onResize() {
      this.resizeVideos()
    },
    resizeVideos() {
      const videoContainer = document.getElementById('videoContainer')
      const baseWidth =
        videoContainer.clientHeight * 4 > videoContainer.clientWidth * 3
          ? videoContainer.clientWidth
          : (videoContainer.clientHeight / 3) * 4
      const numberOfColumns = Math.ceil(
        Math.sqrt(videoContainer.children.length)
      )
      const oneVideoWidth = baseWidth / numberOfColumns
      const oneVideoHeight = (oneVideoWidth / 4) * 3
      Array.from(videoContainer.children).forEach(oneChild => {
        oneChild.style.width = oneVideoWidth + 'px'
        oneChild.style.height = oneVideoHeight + 'px'
      })
    },
    joinMeeting() {
      const videoContainer = document.getElementById('videoContainer')
      this.client.init(process.env.VUE_APP_AGORA_APP_ID)
      this.client.join(null, this.meetingId, null, () => {
        this.localStream.init(() => {
          const localDiv = document.createElement('div')
          localDiv.id = 'localVideo'
          localDiv.style.transform = 'rotateY(180deg)'
          localDiv.style.width = (videoContainer.clientHeight / 3) * 4 + 'px'
          localDiv.style.height = videoContainer.clientHeight + 'px'
          localDiv.style.margin = '5px'
          videoContainer.appendChild(localDiv)
          this.localStream.setScreenProfile('480p_2')
          this.localStream.play('localVideo')
          this.client.publish(this.localStream)
          this.localStreamId = this.localStream.getId()
          this.resizeVideos()
        })
        this.client.on('stream-added', evt => {
          this.client.subscribe(evt.stream)
        })
        this.client.on('stream-subscribed', evt => {
          const stream = evt.stream
          const streamId = String(stream.getId())
          this.addVideoStream(streamId)
          stream.play(streamId)
          this.resizeVideos()
        })
        this.client.on('stream-removed', evt => {
          const stream = evt.stream
          const streamId = String(stream.getId())
          stream.close()
          this.removeVideoStream(streamId)
          this.resizeVideos()
        })
        this.client.on('peer-leave', evt => {
          const stream = evt.stream
          const streamId = String(stream.getId())
          stream.close()
          this.removeVideoStream(streamId)
          this.resizeVideos()
        })
      })
    },
    addVideoStream(elementId) {
      const videoContainer = document.getElementById('videoContainer')
      const streamDiv = document.createElement('div')
      streamDiv.id = elementId
      streamDiv.style.width = '400px'
      streamDiv.style.height = '300px'
      streamDiv.style.margin = '5px'
      videoContainer.appendChild(streamDiv)
    },
    removeVideoStream(elementId) {
      const remoteDiv = document.getElementById(elementId)
      if (remoteDiv) remoteDiv.parentNode.removeChild(remoteDiv)
    },
  },
}
</script>

<style scoped>
.bg {
  height: 100vh; /* 全画面表示 */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url('@/static/bg.png');
}

.video--container {
  width: 100%;
  height: 100%;
}

.chat-card-actions {
  position: fixed;
  bottom: 0;
}

.chat-window {
  overflow-y: scroll;
}
</style>
