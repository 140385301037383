<template>
  <v-fade-transition>
    <div v-if="isSetData">
      <v-container fluid>
        <v-row justify="start">
          <v-col cols="12" sm="6" lg="3">
            <v-btn
              class="ma-2 px-5 grad-blue white--text"
              depressed
              plain
              :disabled="!isSuccessfullySaved"
              @click="saveTemporarily"
            >
              {{ $t('saveTemporarily') }}
            </v-btn>

            <v-btn
              class="ma-2 px-5 grad-red white--text"
              depressed
              plain
              :disabled="!isSuccessfullySaved"
              @click="confirmExitDialog = true"
            >
              <v-card color="transparent" flat tile>
                <div class="white--text">
                  {{ $t('submitClassRecord') }}
                </div>
              </v-card>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid>
        <v-row align="center">
          <v-divider />
          <v-subheader>{{ $t('classInformation') }}</v-subheader>
          <v-divider />
        </v-row>
      </v-container>
      <v-container fluid>
        <group-feedback-info
          v-if="learnerData !== null"
          :unit="unitRecordInfo"
          :tutor="tutorData"
          :learners="learnerData"
        />
      </v-container>
      <v-container fluid>
        <v-row align="center">
          <v-divider />
          <v-subheader>{{ $t('evaluations') }}</v-subheader>
          <v-divider />
        </v-row>
      </v-container>
      <v-container fluid>
        <v-row justify="center">
          <v-col
            v-for="learner in learnerData"
            :key="learner.firstName"
            cols="12"
            sm="6"
            lg="3"
          >
            <v-card>
              <v-container>
                <v-row class="mt-2" justify="center" align="center">
                  <v-avatar>
                    <img :src="learner.photoURL" />
                  </v-avatar>
                  <v-card-title v-text="learner.firstName" />
                </v-row>
              </v-container>
              <v-row class="my-3" justify="center">
                <v-radio-group
                  v-model="classRecordResult[learner.userId]['attendance']"
                  hide-details
                  @click="isSuccessfullySaved = false"
                  @change="updateRecord(learner.userId, 'drafted')"
                >
                  <v-radio
                    v-for="step in attendance.steps"
                    :key="step"
                    :value="step"
                    :label="step"
                  />
                </v-radio-group>
              </v-row>
              <v-list subheader>
                <v-list-item
                  v-for="recordItem in recordItems"
                  :key="recordItem.title"
                >
                  <v-list-item-content>
                    <v-list-item-title class="mb-5">
                      {{ recordItem.title }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-container>
                        <v-row justify="center">
                          <v-btn-toggle
                            v-model="
                              classRecordResult[learner.userId][
                                recordItem.title
                              ]
                            "
                            class="align-center"
                            dense
                            @click="isSuccessfullySaved = false"
                            @change="updateRecord(learner.userId, 'drafted')"
                          >
                            <v-btn
                              v-for="(step, index) in recordItem.steps"
                              :key="index"
                              :value="step"
                              :min-width="minWidth"
                              shaped
                              borderless
                            >
                              {{ step }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-row>
                      </v-container>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-textarea
                    v-model="classRecordResult[learner.userId].notes"
                    class="mt-5"
                    name="input-7-1"
                    label="Notes"
                    auto-grow
                    filled
                    @click="isSuccessfullySaved = false"
                    @blur="updateRecord(learner.userId, 'drafted')"
                  />
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-dialog v-model="confirmExitDialog" persistent flat width="300">
          <v-card>
            <v-card-title class="d-flex align-center justify-center lighten-2">
              <p class="text-h6 pt-2">
                {{ $t('confirmSubmitClassRecord') }}
              </p>
            </v-card-title>
            <v-container>
              <v-row>
                <v-col :cols="12">
                  <v-btn
                    class="grad-red"
                    dark
                    block
                    x-large
                    @click="submitClassRecord()"
                  >
                    {{ $t('submitClassRecord') }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="12">
                  <v-btn block x-large @click="confirmExitDialog = false">
                    {{ $t('cancel') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </v-container>
    </div>
  </v-fade-transition>
</template>
<script>
import { db } from '@/plugins/firebase'
import { mapGetters } from 'vuex'
import GroupFeedbackInfo from '@/components/GroupFeedbackInfo.vue'
export default {
  components: {
    GroupFeedbackInfo,
  },
  data() {
    return {
      classIds: [],
      unitIds: [],
      units: [],
      unitData: [],
      tutorData: null,
      learnerData: null,
      selected: null,
      selectedClassId: null,
      selectedUnitMasterId: null,
      selectedUnitData: null,
      selectedUnitLearners: [],
      selectedUnitLearnerIds: [],
      classRecordResult: {},
      isSetData: false,
      recordItems: null,
      isSuccessfullySaved: false,
      confirmExitDialog: false,
      attendance: {
        steps: [
          'On time',
          '15 min',
          '30 min',
          '45 min',
          'Over 45 min',
          'Absent',
        ],
      },
      firstHalfAssessmentUnit: [
        {
          title: 'CanDo 1',
          steps: [15, 10, 5, 0],
          value: null,
        },
        {
          title: 'CanDo 2',
          steps: [15, 10, 5, 0],
          value: null,
        },
        {
          title: 'CanDo 3',
          steps: [15, 10, 5, 0],
          value: null,
        },
        {
          title: 'CanDo 4',
          steps: [15, 10, 5, 0],
          value: null,
        },
        {
          title: 'CEFR Proficiency',
          steps: ['C2', 'C1', 'B2', 'B1', 'A2', 'A1'],
          value: null,
        },
      ],
      latterHalfAssessmentUnit: [
        {
          title: 'CanDo 6',
          steps: [15, 10, 5, 0],
          value: null,
        },
        {
          title: 'CanDo 7',
          steps: [15, 10, 5, 0],
          value: null,
        },
        {
          title: 'CanDo 8',
          steps: [15, 10, 5, 0],
          value: null,
        },
        {
          title: 'CanDo 9',
          steps: [15, 10, 5, 0],
          value: null,
        },
        {
          title: 'CEFR Proficiency',
          steps: ['C2', 'C1', 'B2', 'B1', 'A2', 'A1'],
          value: null,
        },
      ],
      advancedAssessmentUnit: [
        {
          title: 'CanDo 1',
          steps: [30, 20, 10, 0],
          value: null,
        },
        {
          title: 'CanDo 2',
          steps: [30, 20, 10, 0],
          value: null,
        },
        {
          title: 'CEFR Proficiency',
          steps: ['C2', 'C1', 'B2', 'B1', 'A2', 'A1'],
          value: null,
        },
      ],
      normalUnit: [
        {
          title: 'Turn-taking',
          steps: [6, 3, 0],
          value: null,
        },
        {
          title: 'Active Listening',
          steps: [6, 3, 0],
          value: null,
        },
        {
          title: 'Working with others',
          steps: [6, 3, 0],
          value: null,
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['user', 'userInfo', 'unitRecordInfo']),
    minWidth() {
      switch (
        this.$vuetify.breakpoint.name //
      ) {
        case 'sm':
          return '40'
        case 'lg':
          return '50'
        default:
          return '45'
      }
    },
  },
  async created() {
    if (this.unitRecordInfo.isAssessmentOfUnits) {
      if (this.unitRecordInfo.level === 'advanced') {
        this.recordItems = this.advancedAssessmentUnit
      } else if (this.unitRecordInfo.unitNo === 5) {
        this.recordItems = this.firstHalfAssessmentUnit
      } else if (this.unitRecordInfo.unitNo === 10) {
        this.recordItems = this.latterHalfAssessmentUnit
      }
    } else {
      this.recordItems = this.normalUnit
    }
    const learners = {}
    await this.unitRecordInfo.learnerIds.forEach(async learnerId => {
      const learnerInfoRef = await db
        .collection('langxAccountInfoUsers')
        .doc(learnerId)
        .get()
      const learnerInfo = learnerInfoRef.data()
      learners[learnerId] = learnerInfo
      await this.initClassRecords(learnerId)
      this.updateRecord(learnerId, 'unrecorded')
    })

    const tutorInfoRef = await db
      .collection('langxAccountInfoUsers')
      .doc(this.unitRecordInfo.tutorId)
      .get()
    this.learnerData = { ...this.learnerData, ...learners }
    this.tutorData = await tutorInfoRef.data()
    await db
      .collection('langxLearningContentUnits')
      .doc(this.unitRecordInfo.unitId)
      .update({
        classRecordState: 'unrecorded',
      })
    this.isSetData = true
  },
  methods: {
    async initClassRecords(learnerId) {
      // Firestoreからデータ取得
      await db
        .collection('langxLearningContentClassRecords')
        .doc(`${this.unitRecordInfo.unitId}_${learnerId}`)
        .get()
        .then(async classRecordDoc => {
          if (classRecordDoc.exists) {
            this.$set(this.classRecordResult, learnerId, {
              ...this.classRecordResult[learnerId],
              ...classRecordDoc.data(),
            })
          } else {
            const defaultClassRecord = {}
            this.recordItems.forEach(item => {
              defaultClassRecord[learnerId] = {
                ...defaultClassRecord[learnerId],
                [item.title]: null,
                notes: '',
              }
              this.classRecordResult = {
                ...this.classRecordResult,
                ...defaultClassRecord,
                classRecordState: 'ungraded',
              }
            })
          }
        })
    },
    updateRecord(learnerId) {
      const classRecordRef = db
        .collection('langxLearningContentClassRecords')
        .doc(`${this.unitRecordInfo.unitId}_${learnerId}`)
      const classRecordResult = {
        ...this.classRecordResult,
      }
      classRecordResult[learnerId] = {
        ...classRecordResult[learnerId],
        ...{
          updatedAt: new Date(),
          learnerId: learnerId,
          unitId: this.unitRecordInfo.unitId,
          tutorId: this.unitRecordInfo.tutorId,
          isAssessmentOfUnits: this.unitRecordInfo.isAssessmentOfUnits,
          tutorLangxId: this.unitRecordInfo.tutorLangxId,
          classId: this.unitRecordInfo.classId,
        },
      }

      if (!classRecordResult[learnerId].createdAt)
        classRecordResult[learnerId].createdAt = new Date()
      classRecordRef
        .set(classRecordResult[learnerId], { merge: true })
        .then(
          db
            .collection('langxLearningContentUnits')
            .doc(this.unitRecordInfo.unitId)
            .update({
              classRecordState: 'drafted',
            })
            .then(() => {
              this.isSuccessfullySaved = true
            })
        )
        .catch(err => {
          alert(err)
        })
    },
    async submitClassRecord() {
      await db
        .collection('langxLearningContentUnits')
        .doc(this.unitRecordInfo.unitId)
        .update({
          classRecordState: 'recorded',
        })
      window.close()
    },
    saveTemporarily() {
      window.close()
    },
  },
}
</script>
