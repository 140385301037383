<template>
  <div>
    <v-fade-transition>
      <div v-if="isRegisteredUnit">
        <unit-card
          v-for="(unit, index) in units"
          :key="index"
          :index="index"
          :unit="unit"
        />
      </div>
      <div v-else>
        <unit-empty />
      </div>
    </v-fade-transition>
  </div>
</template>
<script>
import { db } from '@/plugins/firebase'
import { mapGetters } from 'vuex'
import UnitCard from '@/components/UnitCard.vue'
import UnitEmpty from '@/components/UnitEmpty.vue'
import moment from 'moment'

export default {
  name: 'Schedule',
  components: { UnitCard, UnitEmpty },
  data: () => {
    return {
      units: [],
      users: [],
      message: '更新してください', // 多言語対応する
      isRegisteredUnit: false,
    }
  },
  computed: {
    ...mapGetters(['user', 'userInfo']),
  },
  async created() {
    await this.getUnit()
  },
  methods: {
    dynamicSort(key, order = 'asc') {
      return function(a, b) {
        const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]
        const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]

        let comparison = 0
        if (varA > varB) {
          comparison = 1
        } else if (varA < varB) {
          comparison = -1
        }
        return order === 'desc' ? comparison * -1 : comparison
      }
    },
    async getUnit() {
      const units = []
      const userRef = db.collection('langxAccountInfoUsers').doc(this.user.uid)
      const userDoc = await userRef.get()
      if (!userDoc.data().unitIds) {
        this.message = 'まだクラス登録されていません'
      } else {
        this.message = ''
        this.userInfo.unitIds.forEach(async unitId => {
          await db
            .collection('langxLearningContentUnits')
            .doc(unitId)
            .get()
            .then(async unitDoc => {
              const unit = await unitDoc.data()
              unit.tutorInfo = null
              unit.learnerInfo = []
              unit.unitStartTime = moment(unit.date + ' ' + unit.startAt)
              // FIXME:現状一時間半と仮定
              unit.unitEndTime = moment(unit.date + ' ' + unit.startAt).add(
                90,
                'm'
              )
              const unitPreparationTime = moment(
                unit.date + ' ' + unit.startAt
              ).add(-15, 'm')
              const nowDateTime = new Date()
              // Unit開始15分前よりミーティングに参加可能
              if (
                nowDateTime >= unitPreparationTime &&
                nowDateTime <= unit.unitEndTime
              ) {
                unit.isAllowedToJoinUnit = true
              } else {
                unit.isAllowedToJoinUnit = false
              }
              // クラスレコードはチューターかつ開始時刻後にオンにする
              if (
                this.userInfo.userType === 'Tutor' &&
                nowDateTime >= unitPreparationTime
              ) {
                unit.isAllowedToClassRecord = true
              } else {
                // 開始時刻前はオフ
                unit.isAllowedToClassRecord = false
              }
              // Group Feedback のオン・オフ
              if (this.userInfo.userType === 'Tutor') {
                // Tutorは開始時刻を過ぎたら、オン
                if (nowDateTime >= unitPreparationTime) {
                  unit.isAllowedToGroupFeedback = true
                } else {
                  unit.isAllowedToGroupFeedback = false
                }
              } else if (this.userInfo.userType === 'Learner') {
                // Learnerはグループフィードバックがされていたらオン
                unit.isAllowedToGroupFeedback = unit.isGroupFeedbacked
              } else {
                // Tutor, Learner以外もグループフィードバックがされていたらオン
                unit.isAllowedToGroupFeedback = unit.isGroupFeedbacked
              }
              // user情報追加
              await db
                .collection('langxAccountInfoUsers')
                .doc(unit.tutorId)
                .get()
                .then(result => {
                  unit.tutorInfo = result.data()
                })
              for await (const learnerId of unit.learnerIds) {
                db.collection('langxAccountInfoUsers')
                  .doc(learnerId)
                  .get()
                  .then(result => {
                    unit.learnerInfo.push(result.data())
                  })
              }
              units.push(unit)
            })
          units.sort(this.dynamicSort('unitStartTime'))
          this.units = null
          this.units = units
          this.isRegisteredUnit = true
        })
      }
    },
  },
}
</script>
<style scoped>
.rounded-card {
  border-radius: 10px;
}
.course-title {
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
}
.course-body {
  padding-top: 10px;
  padding-right: 50px;
}
</style>
