<template>
  <v-row>
    <v-col cols="14">
      <v-subheader v-if="unit.date" :key="unit.date" inset>
        {{ unit.date }}
      </v-subheader>

      <v-divider :key="index" inset />
      <v-card class="mx-auto my-5 rounded-card" max-width="85%">
        <v-card-title class="pa-3 grad-red white--text">
          <v-row class="align-center" justify="space-between">
            <v-col class="ml-2">
              <span v-text="String(unit.unitNo).padStart(2, '0')" /> -
              <span v-text="unit.unitTitle" />
            </v-col>
            <v-col class="text-right">
              <span class="mr-5 subtitle-1" v-text="unit.courseTitle" />
              <span class="subtitle-2" v-text="unit.quarter" />
            </v-col>
          </v-row>
        </v-card-title>
        <v-row class="py-3" align="center">
          <v-col class="ml-4" cols="2">
            <v-icon>mdi-clock-time-four-outline</v-icon>
            {{ unit.unitStartTime.format('HH:mm') }}
            -
            {{ unit.unitEndTime.format('HH:mm') }}
          </v-col>
          <v-col cols="2">
            <span class="ml-3">
              Tutor:
              <span class="mr-8" v-text="unit.tutorInfo.nickName" />
            </span>
          </v-col>
          <v-col cols="3">
            Students:
            <span
              v-for="(learner, learnerIndex) in unit.learnerInfo"
              :key="learner.nickName"
              class="body-1"
              cols="3"
            >
              {{ learner.nickName }}

              <span
                v-if="learnerIndex != Object.keys(unit.learnerInfo).length - 1"
              >
                ,
              </span>
            </span>
          </v-col>
          <v-spacer />
          <v-card-actions class="mx-6">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <div v-on="on">
                  <v-btn
                    class="grad-red white--text"
                    depressed
                    :disabled="!unit.isAllowedToJoinUnit"
                    @click="openMeetingWindow()"
                  >
                    <v-icon left dark>mdi-account-outline</v-icon>
                    {{ $t('join') }}
                  </v-btn>
                </div>
              </template>
              <span>
                {{ $t('joinMeetingTooltip') }}
              </span>
            </v-tooltip>
            <v-btn
              v-if="userInfo.userType === 'Tutor'"
              :class="
                unit.classRecordState === 'recorded'
                  ? 'ml-2 grad-blue white--text'
                  : 'ml-2 white--text'
              "
              color="grey"
              :disabled="!unit.isAllowedToClassRecord"
              depressed
              @click="openClassRecordWindow()"
            >
              <v-icon left dark>mdi-poll-box-outline</v-icon>
              {{ $t('classRecord') }}
            </v-btn>
            <v-btn
              depressed
              :disabled="!unit.isAllowedToGroupFeedback"
              class="ml-2 white--text"
              :color="unit.isGroupFeedbacked ? 'orange' : 'grey'"
              @click="openGroupFeedbackWindow()"
            >
              <v-icon left dark>mdi-file-document-outline</v-icon>
              {{ $t('groupFeedback') }}
            </v-btn>
          </v-card-actions>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { db } from '@/plugins/firebase'
export default {
  props: {
    unit: {
      type: Object,
      require: true,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      unsubscribeUnitClassRecordState: null,
      unsubscribeUnitIsGroupFeedbacked: null,
    }
  },
  computed: {
    ...mapGetters(['user', 'userInfo']),
  },
  beforeDestroy() {
    if (this.unsubscribeUnitClassRecordState !== null)
      this.unsubscribeUnitClassRecordState()
    if (this.unsubscribeUnitIsGroupFeedbacked !== null)
      this.unsubscribeUnitIsGroupFeedbacked()
  },
  methods: {
    ...mapActions(['setCurrentUnitInfo', 'setUnitRecordInfo']),
    openMeetingWindow() {
      this.setCurrentUnitInfo(this.unit)
      const resolvedRoute = this.$router.resolve({
        name: 'Meeting',
        params: { id: this.unit.meetingId },
      })
      const sw = screen.width
      const sh = screen.height
      window.open(
        resolvedRoute.href,
        'Meeting',
        'left=0, top=0, width=' + sw + ', height=' + sh + ''
      )
    },
    openClassRecordWindow() {
      const unit = {
        endAt: this.unit.unitEndTime.format('HH:mm'),
        ...this.unit,
      }
      this.unsubscribeUnitClassRecordState = db
        .collection('langxLearningContentUnits')
        .doc(this.unit.unitId)
        .onSnapshot(doc => {
          const unit = doc.data()
          this.$set(this.unit, 'classRecordState', unit.classRecordState)
          if (this.unit.classRecordState === 'recorded') {
            this.unsubscribeUnitClassRecordState()
          }
        })
      this.setUnitRecordInfo(unit)
      const resolvedRoute = this.$router.resolve({
        name: 'ClassRecord',
        params: { id: this.unit.unitId },
      })
      const sw = screen.width
      const sh = screen.height
      window.open(
        resolvedRoute.href,
        'ClassRecord',
        'left=0, top=0, width=' + sw + ', height=' + sh + ''
      )
    },
    openGroupFeedbackWindow() {
      this.unsubscribeUnitIsGroupFeedbacked = db
        .collection('langxLearningContentUnits')
        .doc(this.unit.unitId)
        .onSnapshot(doc => {
          const unit = doc.data()
          this.$set(this.unit, 'isGroupFeedbacked', unit.isGroupFeedbacked)
          if (this.unit.isGroupFeedbacked) {
            this.unsubscribeUnitIsGroupFeedbacked()
          }
        })
      const resolvedRoute = this.$router.resolve({
        name: 'GroupFeedbackViewer',
        params: { id: this.unit.unitId },
      })
      const sw = screen.width
      const sh = screen.height
      window.open(
        resolvedRoute.href,
        'GroupFeedbackViewer',
        'left=0, top=0, width=' + sw + ', height=' + sh + ''
      )
    },
  },
}
</script>
<style scoped>
.rounded-card {
  border-radius: 10px;
}
a {
  text-decoration: none;
}
</style>
