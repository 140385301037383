<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="8" md="4">
      <v-card class="py-12" color="transparent" flat tile>
        <v-card-title class="ma-10">
          <v-img src="@/assets/LOGO-LANGX.png" />
        </v-card-title>
        <v-card-actions class="mx-10">
          <v-btn
            class="grad-red white--text"
            color="normal"
            large
            block
            @click="changeCurrentView('SignIn')"
          >
            {{ $t('signIn') }}
          </v-btn>
        </v-card-actions>
        <!--
        <v-card-actions class="mx-10">
          <v-btn
            color="blue"
            class="grad-blue white--text"
            large
            block
            @click="changeCurrentView('SignUp')"
          >
            {{ $t('signUp') }}
          </v-btn>
        </v-card-actions>
        -->
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['view']),
    CurrentView: {
      get() {
        return this.view
      },
      set(value) {
        this.changeCurrentView(value)
      },
    },
  },
  methods: {
    ...mapActions(['changeCurrentView']),
    transitSignIn() {
      this.showSignIn()
    },
    // transitSignUp() {
    //  this.showSignUp()
    // },
    transitUserRegistration() {
      this.showUserRegistration()
    },
  },
}
</script>
