<template>
  <v-row align="center" justify="center">
    <v-col cols="6" sm="6" md="4">
      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <v-card class="elevation-12 pa-9">
          <v-card-actions>
            <v-btn icon @click="changeCurrentView('signUp')">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-card-actions>
          <v-row class="mt-1 mb-5" justify="center">
            <v-toolbar-title>{{ $t('enterInformation') }}</v-toolbar-title>
          </v-row>
          <v-row>
            <v-col>
              <v-form>
                <VTextFieldWithValidation
                  v-model="user.firstName"
                  rules="required|alpha"
                  mode="eager"
                  :label="$t('firstName')"
                />
                <VTextFieldWithValidation
                  v-model="user.lastName"
                  rules="required|alpha"
                  :label="$t('lastName')"
                />
                <VTextFieldWithValidation
                  v-model="user.nickName"
                  rules="required|alpha_num"
                  :label="$t('nickName')"
                />
                <VTextFieldWithValidation
                  v-model="user.langxId"
                  rules="required|alpha_num"
                  :label="$t('langxId')"
                />
              </v-form>
            </v-col>
          </v-row>
          <v-card-text>
            {{ $t('agreement') }}
          </v-card-text>
          <v-row>
            <v-col class="justify-center">
              <v-btn
                class="grad-red white--text"
                large
                block
                :disabled="invalid"
                @click="signUpWithEmailAndPassword(user)"
              >
                {{ $t('signUpWithEmail') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </ValidationObserver>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import VTextFieldWithValidation from './inputs/VTextFieldWithValidation'

export default {
  components: {
    ValidationObserver,
    VTextFieldWithValidation,
  },

  data() {
    return {
      user: {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        nickName: '',
        langxId: '',
        userType: 'Learner',
        agreement: '',
      },
    }
  },
  mounted() {
    // 戻るボタンを押したときにデータ保持
    const userTmpData = JSON.parse(sessionStorage.getItem('userTmpData'))
    this.user = Object.assign(this.user, userTmpData)
  },
  beforeDestroy() {
    const userTmpData = this.user
    sessionStorage.setItem('userTmpData', JSON.stringify(userTmpData))
  },
  methods: {
    ...mapActions(['signUpWithEmailAndPassword', 'changeCurrentView']),
  },
}
</script>
