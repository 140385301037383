<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    :mini-variant="sidebarOpen"
    class="z-top"
    disable-route-watcher
    dark
    src="@/static/bg.png"
    square
    expand-on-hover
    permanent
  >
    <template #prepend>
      <div class="pa-2">
        <v-list-item :v-if="sidebarOpen" class="pa-5">
          <v-list-item-title>
            <v-img src="@/assets/LOGO-LANGX.png" />
          </v-list-item-title>
        </v-list-item>
      </div>
    </template>

    <v-divider />

    <v-list dense>
      <v-list-item
        v-for="item in resultItems"
        :id="item.id"
        :key="item.title"
        :to="item.path"
        link
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template #append :v-if="sidebarOpen">
      <div class="pa-2">
        <v-divider />
        {{ $t('copyRights') }}
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      drawer: true,
      sidebarOpen: false,
      items: [
        {
          title: this.$i18n.tc('home'),
          id: 'Home',
          path: '/',
          icon: 'mdi-home-outline',
          auth: ['Learner', 'Tutor'],
        },
        {
          title: this.$i18n.tc('schedule'),
          id: 'Schedule',
          path: '/schedule',
          icon: 'mdi-clock-outline',
          auth: ['Learner', 'Tutor'],
        },
        {
          title: this.$i18n.tc('groupFeedback'),
          id: 'GroupFeedback',
          path: '/group-feedback',
          icon: 'mdi-pencil-box-outline',
          auth: ['Learner', 'Tutor'],
        },
        {
          title: this.$i18n.tc('classRecord'),
          id: 'ClassRecordList',
          path: '/class-record-list',
          icon: 'mdi-poll-box-outline',
          auth: ['Tutor'],
        },
        {
          title: this.$i18n.tc('profile'),
          id: 'Profile',
          path: '/prefs',
          icon: 'mdi-account-circle-outline',
          auth: ['Learner', 'Tutor'],
        },
        {
          title: this.$i18n.tc('monitorMeeting'),
          id: 'MonitorMeeting',
          path: '/monitor-meeting',
          icon: 'mdi-television',
          auth: ['Administrator'],
        },
        {
          title: this.$i18n.tc('userManagement'),
          id: 'UserManagement',
          path: '/usermanagement',
          icon: 'mdi-account-box-multiple-outline',
          auth: ['Administrator'],
        },
        {
          title: this.$i18n.tc('unitRegistration'),
          id: 'UnitRegistration',
          path: '/unitregistration',
          icon: 'mdi-plus-box-multiple-outline',
          auth: ['Administrator'],
        },
        {
          title: this.$i18n.tc('courseManagement'),
          id: 'CourseManagement',
          path: '/coursemanagement',
          icon: 'mdi-calendar-multiple-check',
          auth: ['Administrator'],
        },
        {
          title: this.$i18n.tc('dataWareHouse'),
          id: 'DataWareHouse',
          path: '/datawarehouse',
          icon: 'mdi-database-outline',
          auth: ['Administrator'],
        },
      ],
      mini: true,
    }
  },
  computed: {
    ...mapGetters(['sidebar', 'userInfo']),
    sidebarItem: {
      get() {
        return this.sidebar.selectSidebarItem
      },
      set(value) {
        this.selectSidebarItem(value)
      },
    },
    resultItems: function() {
      return this.items.filter(item => {
        return item.auth.includes(this.userInfo.userType)
      })
    },
  },
  methods: {
    ...mapActions(['selectSidebarItem']),
  },
}
</script>

<style scoped>
.logo {
  margin: 30px;
  margin-right: 30px;
}
</style>
