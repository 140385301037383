<template>
  <v-card flat tile color="transparent" class="px-9">
    <v-row align="center" class="pt-2">
      <span class="left-title mr-2">
        Class
      </span>
      <span class="mr-2">
        <text-highlighted :text="unit.courseTitle + ' Unit ' + unit.unitNo" />
      </span>
      <span class="mr-2">
        Title
      </span>
      <span class="mr-2">
        <text-highlighted :text="unit.unitTitle" />
      </span>
      <span class="mr-2">
        <v-icon>mdi-clock-outline</v-icon>
      </span>
      <span class="mr-1">
        {{ unit.date }}
      </span>
      <span class="font-weight-medium">
        {{ unit.startAt }}
        -
        {{ unit.endAt }}
      </span>
    </v-row>
    <v-row v-if="!unit.isAssessmentOfUnits" align="center" class="pt-2">
      <span class="left-title mr-2">
        Cando
      </span>
      <span>
        <text-highlighted :text="unit.unitCanDo" />
      </span>
    </v-row>
    <v-row align="center" class="pt-4">
      <div class="left-title mr-2">
        To
      </div>
      <template v-for="(oneLearner, learnerId) in learners">
        <div
          :key="learnerId"
          class="mr-5 d-flex align-center justify-center flex-column"
        >
          <v-avatar>
            <img :src="oneLearner.photoURL" alt />
          </v-avatar>
          <span class="text-caption">{{ oneLearner.nickName }}</span>
        </div>
      </template>
    </v-row>
    <v-row align="center" class="pt-4">
      <div class="left-title mr-2">
        From
      </div>
      <div class="mr-5 d-flex align-center justify-center flex-column">
        <v-avatar>
          <img :src="tutor.photoURL" alt />
        </v-avatar>
        <span class="text-caption">{{ tutor.nickName }}</span>
      </div>
    </v-row>
  </v-card>
</template>
<script>
import TextHighlighted from '@/components/TextHighlighted.vue'
export default {
  components: {
    TextHighlighted,
  },
  props: {
    unit: {
      type: Object,
      default: () => {},
    },
    tutor: {
      type: Object,
      default: () => {},
    },
    learners: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
<style scoped>
.left-title {
  width: 50px;
}
</style>
