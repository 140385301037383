import {
  required,
  email,
  confirmed,
  alpha,
  // eslint-disable-next-line
  alpha_num,
} from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'
import { i18n } from '@/plugins/i18n'

// パスワードは8文字以上、最低1文字以上の英字と数字
const regexPassword = /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,100}$/i
const password = {
  validate(value) {
    // eslint-disable-next-line
    return regexPassword.test(value)
  },
  message: i18n.tc('errorPassword'),
}
extend('password', password)

extend('alpha', {
  ...alpha,
  message: 'The field under validation may contain alphabetic characters',
})

extend('alpha_num', {
  // eslint-disable-next-line
  ...alpha_num,
  message:
    'The field under validation may contain alphabetic characters or numbers.',
})

extend('required', {
  ...required,
  message: i18n.tc('enterInformation'),
})

extend('confirmed', {
  ...confirmed,
  message: '',
})

extend('email', {
  ...email,
  message: i18n.tc('errorEmail'),
})
