<template color="#f9f9f9">
  <v-container>
    <v-row align="center">
      <v-divider />
      <v-subheader>{{ $t('classInformation') }}</v-subheader>
      <v-divider />
    </v-row>
    <v-card color="transparent" flat tile width="100%">
      <v-row class="mt-3" align="center" justify="center">
        <v-col cols="6">
          <v-select
            v-model="selectedClassId"
            :items="classIds"
            label="Class"
            @change="updateSelectedClass"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="userInfo.userType === 'Tutor'" cols="12">
          <v-list v-if="units !== []" two-line>
            <v-subheader>
              {{ $t('unit') }}
            </v-subheader>
            <template v-for="(unit, index) in units">
              <v-divider :key="index" />
              <v-list-item :key="unit.unitId" @click="editClassRecord(unit)">
                <v-list-item-icon>
                  <div v-show="typeof unit.classRecordState === 'undefined'">
                    <v-icon color="grey lighten-1">
                      mdi-pencil-outline
                    </v-icon>
                  </div>
                  <div v-show="unit.classRecordState === 'drafted'">
                    <v-icon color="grey lighten-1">
                      mdi-file-outline
                    </v-icon>
                  </div>
                  <div v-show="unit.classRecordState === 'recorded'">
                    <v-icon color="green">
                      mdi-check
                    </v-icon>
                  </div>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="
                      `${unit.courseTitle}, Unit ${unit.unitNo}: ${unit.unitTitle}`
                    "
                  />
                  <v-list-item-subtitle
                    class="text--primary"
                    v-text="unit.date + ' ' + unit.startAt"
                  />
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-divider />
          </v-list>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar.display" timeout="3000">
        {{ snackbar.message }}
        <template #action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar.display = false"
          >
            {{ $t('close') }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { db } from '@/plugins/firebase'
import moment from 'moment'

export default {
  data() {
    return {
      classIds: [],
      unitIds: [],
      units: [],
      unitData: [],
      selected: null,
      selectedClassId: null,
      selectedUnitData: null,
      selectedUnitLearners: [],
      selectedUnitLearnerIds: [],
      evaluationResult: {},
      unsubscribe: null,
      snackbar: {
        display: false,
        message: '',
      },
    }
  },
  computed: {
    ...mapGetters(['user', 'userInfo']),
  },
  mounted() {
    this.classData = db
      .collection('langxAccountInfoUsers')
      .doc(this.userInfo.userId)
      .get()
      .then(userDoc => {
        this.classIds = userDoc.data().classIds
      })
  },
  beforeDestroy() {
    if (this.unsubscribe !== null) this.unsubscribe()
  },
  methods: {
    ...mapActions(['setUnitRecordInfo']),
    updateSelectedClass() {
      if (this.unsubscribe !== null) this.unsubscribe()
      this.units = []
      this.unitIds = db
        .collection('langxLearningContentClasses')
        .doc(this.selectedClassId)
        .get()
        .then(classDoc => {
          this.unitIds = classDoc.data().unitIds
          this.unitIds.forEach(unitId => {
            this.unsubscribe = db
              .collection('langxLearningContentUnits')
              .doc(unitId)
              .onSnapshot(unitDoc => {
                if (
                  unitDoc.exists &&
                  unitDoc.data().tutorId === this.userInfo.userId
                ) {
                  const unit = {
                    endAt: moment(
                      unitDoc.data().date + ' ' + unitDoc.data().startAt
                    )
                      .add(90, 'm')
                      .format('HH:mm'),
                    ...unitDoc.data(),
                  }
                  const unitIndex = this.units.findIndex(
                    ({ unitId }) => unitId === unit.unitId
                  )
                  if (unitIndex === -1) {
                    this.units.push(unit)
                  } else {
                    this.$set(this.units, unitIndex, unit)
                  }
                }
              })
          })
        })
    },
    editClassRecord(unit) {
      this.setUnitRecordInfo(unit)
      const resolvedRoute = this.$router.resolve({
        name: 'ClassRecord',
        params: { id: unit.unitId },
      })
      const sw = screen.width
      const sh = screen.height
      window.open(
        resolvedRoute.href,
        'ClassRecord',
        'left=0, top=0, width=' + sw + ', height=' + sh + ''
      )
    },
  },
}
</script>
<style scoped>
.pink-red-icon {
  color: #b80e65 !important;
}
.left-title {
  width: 50px;
}
.pre-wrap-text {
  white-space: pre-wrap;
}
</style>
