<template #header>
  <div>
    <Sidebar />
    <Navbar :name="name" />
    <v-main>
      <router-view />
    </v-main>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
// import i18n from ''
// const i18n = new VueI18n({
//   locale: 'ja',
//   messages,
// })
export default {
  name: 'Portal',
  components: {
    Sidebar,
    Navbar,
  },
  data() {
    return {
      name: '',
    }
  },
  computed: {},
  watch: {
    $route: function(to, from) {
      this.name = this.$route.name
    },
  },
  mounted() {
    this.name = this.$route.name
  },
  methods: {},
}
</script>
<style>
.content {
  margin-top: 60px;
}
</style>
