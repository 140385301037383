var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-divider'),_c('v-subheader',[_vm._v(" "+_vm._s(_vm.$t('userManagement'))+" ")]),_c('v-divider')],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('users'))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"dense":"","items":_vm.userList,"headers":_vm.headers,"items-per-page":5,"search":_vm.search,"footer-props":{
          showFirstLastPage: true,
          firstIcon: 'mdi-page-first',
          lastIcon: 'mdi-page-last',
          prevIcon: 'mdi-chevron-left',
          nextIcon: 'mdi-chevron-right',
        }},scopedSlots:_vm._u([{key:"item.photoURL",fn:function(ref){
        var item = ref.item;
return [_c('v-avatar',{staticClass:"ma-2"},[_c('img',{attrs:{"src":item.photoURL}})])]}},{key:"item.classIds",fn:function(ref){
        var item = ref.item;
return _vm._l((item.classIds),function(oneClass){return _c('v-chip',{key:oneClass,staticClass:"ma-1 text-caption",attrs:{"color":"deep-purple lighten-2","outlined":"","pill":""}},[_vm._v(" "+_vm._s(oneClass)+" ")])})}},{key:"item.userType",fn:function(ref){
        var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.userType,"cancel-text":_vm.$t('cancel'),"save-text":_vm.$t('update'),"large":"","persistent":""},on:{"update:returnValue":function($event){return _vm.$set(item, "userType", $event)},"update:return-value":function($event){return _vm.$set(item, "userType", $event)},"save":function($event){return _vm.updateUserType(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"text-caption mt-1"},[_vm._v(" "+_vm._s(_vm.$t('updateUserType'))+" ")]),_c('v-select',{attrs:{"items":_vm.userTypes,"dense":"","autofocus":""},model:{value:(item.userType),callback:function ($$v) {_vm.$set(item, "userType", $$v)},expression:"item.userType"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.userType)+" ")])]}}],null,true)})],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
        var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }