<template>
  <v-app-bar app flat color="#ffffff">
    <v-row class="align-center" justify="space-between">
      <span>
        <v-row class="align-center" justify="space-between">
          <v-col class="ml-3"> {{ $t(name) }}｜ </v-col>
        </v-row>
      </span>
      <span>
        <v-row class="align-center" justify="space-between">
          <v-col>
            <div>
              {{ userInfo.nickName }}
            </div>
          </v-col>
          <v-col>
            <v-avatar :src="user.photoURL" size="45">
              <img :src="user.photoURL" />
            </v-avatar>
          </v-col>
          <v-col>
            <v-btn
              class="white--text grad-red depressed rounded-btn"
              @click="signOut()"
            >
              <v-icon class="return" left>mdi-share</v-icon>
              {{ $t('signOut') }}
            </v-btn>
          </v-col>
        </v-row>
      </span>
    </v-row>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    activeSidebar: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['user', 'userInfo']),
  },
  mounted() {},
  methods: {
    ...mapActions(['toggleSidebar', 'signOut']),
  },
}
</script>
<style scoped>
.rounded-btn {
  border-radius: 15px;
}
.return {
  transform: scaleX(-1);
}
</style>
