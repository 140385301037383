<template>
  <v-app>
    <v-app-bar app class="white--text" color="#312f30" flat>
      <h3>
        Meeting Monitor /
        {{
          currentUnitInfo.unitId !== 'dummy'
            ? ` Class: ${currentUnitInfo.classId}, Unit ${currentUnitInfo.unitNo}: "${currentUnitInfo.unitTitle}",  ${currentUnitInfo.startAtDateTime} - ${currentUnitInfo.endAtDateTime} `
            : ` Meeting ID: ${$route.params.id}`
        }}
      </h3>
      <v-spacer />
    </v-app-bar>
    <v-main class="bg">
      <v-container fill-height>
        <div
          id="videoContainer"
          v-resize="onResize"
          class="d-flex flex-wrap align-content-center justify-center mb-6 video--container"
        />
      </v-container>
    </v-main>
    <!-- footer tool -->
    <v-footer app absolute color="#312f30">
      <v-card color="transparent" flat tile width="100%">
        <v-row>
          <v-col cols="3" />
          <v-col class="d-flex justify-center" cols="6">
            <v-btn class="pa-7" depressed plain @click="openParticipants()">
              <v-card color="transparent" flat tile>
                <v-icon color="#ffffff">
                  mdi-account-multiple
                </v-icon>
                <div class="white--text">
                  {{ $t('participants') }}
                </div>
              </v-card>
            </v-btn>
            <v-btn class="pa-7" depressed plain @click="openChat()">
              <v-card color="transparent" flat tile>
                <v-icon color="#ffffff">
                  mdi-chat
                </v-icon>
                <div class="white--text">
                  {{ $t('chat') }}
                </div>
              </v-card>
            </v-btn>
          </v-col>
          <v-col class="d-flex justify-end" cols="3">
            <v-btn
              class="ma-2 px-5 grad-red white--text"
              depressed
              plain
              @click="leaveMeeting()"
            >
              <v-card color="transparent" flat tile>
                <div class="white--text">
                  {{ $t('leaveMeeting') }}
                </div>
              </v-card>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-footer>
    <v-navigation-drawer
      id="participantsDrawer"
      v-model="participants"
      max-width="700"
      app
      color="#312f30"
      right
    >
      <v-list-item dark flat tile />
      <v-card id="participantsWindow" color="transparent" dark flat tile>
        <v-card-text>
          <v-row
            v-for="oneParticipant in allParticipants"
            :key="oneParticipant.streamId"
            :class="[
              oneParticipant.userType === 'Learner'
                ? 'my-5 px-2 bg-gray rounded'
                : 'my-5 px-2 grad-red rounded',
            ]"
          >
            <v-col cols="12">
              <p class="white--text text-subtitle-2 ma-0 pa-0">
                {{ oneParticipant.nickName }}
              </p>
              <p class="white--text text-caption ma-0 pa-0">
                ( {{ oneParticipant.firstName }} {{ oneParticipant.lastName }} )
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-navigation-drawer
      id="chatDrawer"
      v-model="chat"
      max-width="500"
      app
      color="#312f30"
      right
    >
      <v-card
        id="chatWindow"
        color="transparent"
        class="chat-window"
        dark
        flat
        tile
      >
        <v-card-text>
          <v-row
            v-for="oneMessage in chatMessages"
            :key="oneMessage.messageId"
            dense
            class="my-1 px-1"
          >
            <v-col v-if="oneMessage.isChangedState" cols="12">
              <p class="white--text text-caption ma-0 pa-0">
                {{
                  oneMessage.sentAtTime +
                    ' ' +
                    oneMessage.nickName +
                    oneMessage.message
                }}
              </p>
            </v-col>
            <v-col v-else cols="12">
              <p class="white--text text-caption ma-0 pa-0">
                {{ oneMessage.sentAtTime + ' ' + oneMessage.nickName }}
              </p>
              <p class="white--text text-body-1 pre-wrap-text ma-0 pa-0">
                {{ oneMessage.message.replaceAll('\\n', '\n') }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-snackbar v-model="errorSnackbar" timeout="3000">
      {{ errorMessage }}
      <template #action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="errorSnackbar = false">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import AgoraRTC from 'agora-rtc-sdk'
import { mapGetters } from 'vuex'
import moment from 'moment'
import firebase from '@/plugins/firebase'

export default {
  name: 'Monitor',
  data: () => {
    return {
      meetingId: '',
      client: AgoraRTC.createClient({
        mode: 'live',
        codec: 'h264',
      }),
      errorSnackbar: false,
      errorMessage: '',
      participants: false,
      allParticipants: {},
      chat: false,
      chatMessages: [],
      realTimeDB: firebase.database(),
    }
  },
  computed: {
    ...mapGetters(['user', 'userInfo', 'currentUnitInfo']),
  },
  mounted() {
    this.meetingId = this.$route.params.id
    this.joinMeeting()
    this.listenParticipants()
    this.listenChat()
    this.listenLikes()
    this.listenRaiseHands()
    this.onResize()
  },
  destroyed() {
    this.$returnScroll()
  },
  beforeDestroy() {
    this.meetingParticipantsListener.off()
    this.meetingChatListener.off()
    this.meetingLikesListener.off()
    this.meetingRaiseHandsListener.off()
    this.meetingLayHandsListener.off()
  },
  updated() {
    if (this.newMessage) {
      this.resizeChatWindow()
      this.newMessage = false
    }
  },
  methods: {
    onResize() {
      this.resizeChatWindow()
      this.resizeVideos()
    },
    resizeVideos() {
      const videoContainer = document.getElementById('videoContainer')
      const baseWidth =
        videoContainer.clientHeight * 4 > videoContainer.clientWidth * 3
          ? videoContainer.clientWidth
          : (videoContainer.clientHeight / 3) * 4
      const numberOfColumns = Math.ceil(
        Math.sqrt(videoContainer.children.length)
      )
      const oneVideoWidth = baseWidth / numberOfColumns
      const oneVideoHeight = (oneVideoWidth / 4) * 3
      Array.from(videoContainer.children).forEach(oneChild => {
        oneChild.style.width = oneVideoWidth + 'px'
        oneChild.style.height = oneVideoHeight + 'px'
      })
    },
    resizeChatWindow() {
      const chatWindow = document.getElementById('chatWindow')
      const chatDrawer = chatWindow.parentNode
      chatWindow.style.height = chatDrawer.clientHeight + 'px'
      chatWindow.scrollTop = chatWindow.scrollHeight
    },
    joinMeeting() {
      this.client.init(process.env.VUE_APP_AGORA_APP_ID)
      this.client.join(null, this.meetingId, null, () => {
        this.client.on('stream-added', evt => {
          this.client.subscribe(evt.stream)
        })
        this.client.on('stream-subscribed', evt => {
          const stream = evt.stream
          const streamId = String(stream.getId())
          this.addVideoStream(streamId)
          stream.play(streamId)
          const meetingParticipantsRef = this.realTimeDB.ref(
            `/realTimeInteractions/${this.meetingId}/meetingParticipants/${streamId}`
          )
          meetingParticipantsRef.once('value').then(snapshot => {
            const onePlayer = document.getElementById(`player_${streamId}`)
            const oneParticipant = snapshot.val()
            const currentNameTag = document.getElementById(
              `nameTag_${streamId}`
            )
            if (currentNameTag) {
              currentNameTag.remove()
            }
            const nameTag = document.createElement('div')
            nameTag.id = `nameTag_${streamId}`
            nameTag.textContent =
              oneParticipant.userType === 'Learner'
                ? ` ${oneParticipant.nickName} ( ${oneParticipant.firstName} ${oneParticipant.lastName} ) `
                : ` ${oneParticipant.userType}: ${oneParticipant.nickName} ( ${oneParticipant.firstName} ${oneParticipant.lastName} ) `
            nameTag.className =
              oneParticipant.userType === 'Learner'
                ? 'name--tag bg-gray white--text'
                : 'name--tag grad-red white--text'
            onePlayer.appendChild(nameTag)
            this.allParticipants[streamId] = {
              streamId: streamId,
              ...oneParticipant,
            }
          })
          this.resizeVideos()
        })
        this.client.on('stream-removed', evt => {
          const stream = evt.stream
          const streamId = String(stream.getId())
          stream.close()
          this.removeVideoStream(streamId)
          this.resizeVideos()
        })
        this.client.on('peer-leave', evt => {
          const stream = evt.stream
          const streamId = String(stream.getId())
          stream.close()
          this.removeVideoStream(streamId)
          this.resizeVideos()
        })
        this.client.on('mute-audio', evt => {
          const streamId = evt.uid
          const muteIcon = document.createElement('i')
          muteIcon.className = 'mdi mdi-microphone-off'
          muteIcon.id = `muteIcon_${streamId}`
          document
            .getElementById(`nameTag_${streamId}`)
            .insertAdjacentElement('afterbegin', muteIcon)
        })
        this.client.on('unmute-audio', evt => {
          const streamId = evt.uid
          document.getElementById(`muteIcon_${streamId}`).remove()
        })
        this.client.on('mute-video', evt => {
          const streamId = evt.uid
          const userPhoto = document.createElement('img')
          userPhoto.className = 'rounded-circle user-photo'
          userPhoto.src = this.allParticipants[streamId].photoURL
          userPhoto.id = `userPhoto_${streamId}`
          document
            .getElementById(`player_${streamId}`)
            .insertAdjacentElement('afterBegin', userPhoto)
        })
        this.client.on('unmute-video', evt => {
          const streamId = evt.uid
          document.getElementById(`userPhoto_${streamId}`).remove()
        })
      })
    },
    addVideoStream(elementId) {
      const videoContainer = document.getElementById('videoContainer')
      const streamDiv = document.createElement('div')
      streamDiv.id = elementId
      streamDiv.style.width = '400px'
      streamDiv.style.height = '300px'
      streamDiv.style.margin = '5px'
      videoContainer.appendChild(streamDiv)
    },
    removeVideoStream(elementId) {
      const remoteDiv = document.getElementById(elementId)
      if (remoteDiv) remoteDiv.parentNode.removeChild(remoteDiv)
    },
    openParticipants() {
      this.chat = false
      this.participants = !this.participants
    },
    openChat() {
      this.participants = false
      this.chat = !this.chat
    },
    listenParticipants() {
      const meetingParticipantsRef = this.realTimeDB.ref(
        `/realTimeInteractions/${this.meetingId}/meetingParticipants`
      )
      meetingParticipantsRef.on('value', snapshot => {
        this.allParticipants = {}
        const allParticipants = snapshot.val()
        for (const oneParticipantStreamId in allParticipants) {
          const onePlayer = document.getElementById(
            `player_${oneParticipantStreamId}`
          )
          const oneParticipant = allParticipants[oneParticipantStreamId]
          if (oneParticipant.userType !== 'Learner' && onePlayer) {
            const currentNameTag = document.getElementById(
              `nameTag_${oneParticipantStreamId}`
            )
            if (currentNameTag) {
              currentNameTag.remove()
            }
            const nameTag = document.createElement('div')
            nameTag.id = `nameTag_${oneParticipantStreamId}`
            nameTag.textContent =
              oneParticipant.userType === 'Learner'
                ? ` ${oneParticipant.nickName} ( ${oneParticipant.firstName} ${oneParticipant.lastName} ) `
                : ` ${oneParticipant.userType}: ${oneParticipant.nickName} ( ${oneParticipant.firstName} ${oneParticipant.lastName} ) `
            nameTag.className =
              oneParticipant.userType === 'Learner'
                ? 'name--tag bg-gray white--text'
                : 'name--tag grad-red white--text'
            onePlayer.appendChild(nameTag)
            this.allParticipants[oneParticipantStreamId] = {
              streamId: oneParticipantStreamId,
              ...oneParticipant,
            }
            delete allParticipants[oneParticipantStreamId]
          }
        }
        for (const oneParticipantStreamId in allParticipants) {
          const onePlayer = document.getElementById(
            `player_${oneParticipantStreamId}`
          )
          const oneParticipant = allParticipants[oneParticipantStreamId]
          if (onePlayer) {
            const currentNameTag = document.getElementById(
              `nameTag_${oneParticipantStreamId}`
            )
            if (currentNameTag) {
              currentNameTag.remove()
            }
            const nameTag = document.createElement('div')
            nameTag.id = `nameTag_${oneParticipantStreamId}`
            nameTag.textContent =
              oneParticipant.userType === 'Learner'
                ? ` ${oneParticipant.nickName} ( ${oneParticipant.firstName} ${oneParticipant.lastName} ) `
                : ` ${oneParticipant.userType}: ${oneParticipant.nickName} ( ${oneParticipant.firstName} ${oneParticipant.lastName} ) `
            nameTag.className =
              oneParticipant.userType === 'Learner'
                ? 'name--tag bg-gray white--text'
                : 'name--tag grad-red white--text'
            onePlayer.appendChild(nameTag)
            this.allParticipants[oneParticipantStreamId] = {
              streamId: oneParticipantStreamId,
              ...oneParticipant,
            }
          }
        }
      })
    },
    listenChat() {
      const meetingChatRef = this.realTimeDB.ref(
        `/realTimeInteractions/${this.meetingId}/meetingChat`
      )
      meetingChatRef.once('value').then(snapshot => {
        const allChatMessages = snapshot.val()
        if (allChatMessages) {
          this.chatMessages = Object.entries(allChatMessages).map(
            ([key, value]) => ({
              messageId: key,
              sentAtTime: moment(value.sentAt).format('HH:mm:ss'),
              ...value,
            })
          )
        }
      })
      meetingChatRef.on('child_added', snapshot => {
        const oneMessage = snapshot.val()
        const messageId = snapshot.key
        this.chatMessages.push({
          messageId: messageId,
          sentAtTime: moment(oneMessage.sentAt).format('HH:mm:ss'),
          ...oneMessage,
        })
        this.newMessage = true
      })
    },
    listenLikes() {
      const meetingLikesRef = this.realTimeDB.ref(
        `/realTimeInteractions/${this.meetingId}/meetingLikes`
      )
      meetingLikesRef.on('child_added', snapshot => {
        const oneLike = snapshot.val()
        const onePlayer = document.getElementById(
          `player_${oneLike.receivedStreamId}`
        )
        if (onePlayer) {
          const fallingLike = document.createElement('div')
          fallingLike.textContent = '👍'
          fallingLike.style.right = Math.random() * 10 + 40 + 'px'
          fallingLike.className = 'falling--like'
          onePlayer.appendChild(fallingLike)
        }
      })
    },
    listenRaiseHands() {
      const meetingRaiseHandsRef = this.realTimeDB.ref(
        `/realTimeInteractions/${this.meetingId}/meetingRaiseHands`
      )
      meetingRaiseHandsRef.on('child_added', snapshot => {
        const newRaiserId = snapshot.key
        const onePlayer = document.getElementById(`player_${newRaiserId}`)
        if (onePlayer) {
          const raisedHand = document.createElement('div')
          raisedHand.textContent = '✋'
          raisedHand.className = 'raised--hand grad-blue'
          raisedHand.id = `hand_${newRaiserId}`
          raisedHand.style.left = 0
          onePlayer.appendChild(raisedHand)
        }
      })
      meetingRaiseHandsRef.on('child_removed', snapshot => {
        const removedRaiserId = snapshot.key
        const removedRaisedHand = document.getElementById(
          `hand_${removedRaiserId}`
        )
        if (removedRaisedHand) {
          removedRaisedHand.remove()
        }
      })
    },
    leaveMeeting() {
      window.close()
    },
  },
}
</script>

<style scoped>
.bg {
  height: 100vh; /* 全画面表示 */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url('@/static/bg.png');
}

.video--container {
  width: 100%;
  height: 100%;
}

.chat-card-actions {
  position: fixed;
  bottom: 0;
}

.chat-window {
  overflow-y: scroll;
}

.pre-wrap-text {
  white-space: pre-wrap;
}
</style>
