var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"pa-9",attrs:{"flat":""}},[_c('v-subheader',[_vm._v(" 基本情報 ")]),_c('v-divider',{staticClass:"mb-8"}),_c('v-row',[_c('v-col',[_c('v-form',[_c('v-container',[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('VTextFieldWithValidation',{attrs:{"rules":"required|alpha_num","mode":"aggressive","label":_vm.$t('nickName'),"disabled":!_vm.editNickName},model:{value:(_vm.updateUserInfo.nickName),callback:function ($$v) {_vm.$set(_vm.updateUserInfo, "nickName", $$v)},expression:"updateUserInfo.nickName"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('prefs-button',{attrs:{"display":!_vm.editNickName,"action":function() {
                        _vm.editNickName = !_vm.editNickName
                      },"icon":"mdi-pencil","message":"editNickName"}}),_c('prefs-button',{attrs:{"display":_vm.editNickName,"disabled":invalid,"action":_vm.changeNickName,"icon":"mdi-check","message":"confirm"}}),_c('prefs-button',{attrs:{"display":_vm.editNickName,"action":_vm.resetValue,"icon":"mdi-close","message":"cancel"}})],1)],1)]}}])}),_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('VTextFieldWithValidation',{attrs:{"rules":"required|confirmed:confirmPassword","label":_vm.$t('password'),"mode":"aggressive","disabled":!_vm.editPassword,"type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.updateUserInfo.password),callback:function ($$v) {_vm.$set(_vm.updateUserInfo, "password", $$v)},expression:"updateUserInfo.password"}}),_c('VTextFieldWithValidation',{attrs:{"rules":"required","vid":"confirmPassword","label":_vm.$t('reEnterPassword'),"mode":"aggressive","type":"password","disabled":!_vm.editPassword},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('prefs-button',{attrs:{"display":!_vm.editPassword,"action":function() {
                        _vm.editPassword = !_vm.editPassword
                      },"icon":"mdi-pencil","message":"editPassword"}}),_c('prefs-button',{attrs:{"display":_vm.editPassword,"disabled":invalid,"action":_vm.changePassword,"icon":"mdi-check","message":"confirm"}}),_c('prefs-button',{attrs:{"display":_vm.editPassword,"action":_vm.resetValue,"icon":"mdi-close","message":"cancel"}})],1)],1)]}}])})],1)],1)],1)],1),_c('v-subheader',{staticClass:"mt-6"},[_vm._v(" 詳細情報 ")]),_c('v-divider',{staticClass:"mb-5"}),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-5",attrs:{"justify-center":""}},[_c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"justify-center"},[_vm._v(" 現在の画像 ")]),_c('v-card-text',{staticClass:"text-center"},[_c('img',{attrs:{"src":_vm.updateUserInfo.photoURL,"height":"200","width":"200"}})])],1)],1),_c('v-col',{staticClass:"d-flex justify-center"},[_c('upload-profile-image',{on:{"userPhotoURLIsUpdated":function($event){return _vm.resetValue()}}})],1)],1),_c('v-row',{staticClass:"mt-5",attrs:{"align":"center"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-web")]),_c('span',{staticClass:"caption mr-4"},[_vm._v(_vm._s(_vm.$t('language')))]),_c('v-btn',{staticClass:"text-decoration-underline blue--text",attrs:{"text":""},on:{"click":function($event){return _vm.changeLanguage({ lang: 'ja', i18n: _vm.$i18n })}}},[_vm._v(" "+_vm._s(_vm.$t('japanese'))+" ")]),_c('v-btn',{staticClass:"text-decoration-underline blue--text",attrs:{"text":""},on:{"click":function($event){return _vm.changeLanguage({ lang: 'en', i18n: _vm.$i18n })}}},[_vm._v(" "+_vm._s(_vm.$t('english'))+" ")])],1)],1)],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar.display = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar.display),callback:function ($$v) {_vm.$set(_vm.snackbar, "display", $$v)},expression:"snackbar.display"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }