import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import { vuexfireMutations } from 'vuexfire'
import createPersistedState from 'vuex-persistedstate'
// eslint-disable-next-line
import firebase, { db } from '@/plugins/firebase'

Vue.use(Vuex)
const currentLanguage = 'language'
const initLang = (() => {
  let language =
    (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language ||
    window.navigator.userLanguage ||
    window.navigator.browserLanguage

  // 表記揺れを吸収
  language = language.toLowerCase()
  if (['ja', 'ja-jp'].includes(language)) language = 'ja'
  else language = 'en'

  return language
})()

// デフォルトのステートを変数に格納
const getDefaultState = () => {
  return {
    language: initLang,
    user: null,
    view: 'Entry',
    userInfo: null,
    currentUnitInfo: null,
    unitRecordInfo: null,
    userTmpData: null,
    authError: null,
    sidebar: {
      selectSidebarItem: 'Dashboard',
    },
  }
}

export default new Vuex.Store({
  state: getDefaultState(),
  mutations: {
    ...vuexfireMutations,
    // 言語切替
    onLangChanged(state, lang) {
      window.localStorage.setItem(currentLanguage, lang)
      lang.i18n.locale = lang.lang
      state.language = lang.lang
    },
    signInWithEmailAndPassword(state, user) {
      state.user = user
    },
    changeCurrentView(state, view) {
      state.view = view
    },
    setLoginUser(state, user) {
      state.user = user
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    setAuthError(state, err) {
      state.authError = err
    },
    deleteLoginUser(state) {
      state.user = null
    },
    selectSidebarItem(state, item) {
      state.sidebar.selectSidebarItem = item
    },
    reset(state) {
      Object.assign(state, getDefaultState())
    },
    setCurrentUnitInfo(state, currentUnitInfo) {
      state.currentUnitInfo = currentUnitInfo
    },
    setUnitRecordInfo(state, unitRecordInfo) {
      state.unitRecordInfo = unitRecordInfo
    },
  },
  actions: {
    changeCurrentView({ commit }, view) {
      commit('changeCurrentView', view)
    },
    signUpWithEmailAndPassword({ commit }, user) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(user.email, user.password)
        .then(async userCredential => {
          user.userId = userCredential.user.uid
          db.collection('langxAccountInfoUsers')
            .doc(user.userId)
            .set({
              email: user.email,
              userId: user.userId,
              lastName: user.lastName,
              firstName: user.firstName,
              nickName: user.nickName,
              langxId: user.langxId,
              userType: user.userType,
            })
          db.collection('langxIds')
            .doc(user.langxId)
            .set({
              userId: user.userId,
            })
          commit('setLoginUser', userCredential.user)
          commit('setUserInfo', user)
          router.push({ path: '/' })
        })
        .catch()
    },
    signInWithEmailAndPassword({ dispatch, commit }, user) {
      firebase
        .auth()
        .signInWithEmailAndPassword(user.email, user.password)
        .then(async userCredential => {
          commit('signInWithEmailAndPassword', userCredential.user)
          await dispatch('setLoginUser', userCredential.user)
          router.push({ path: '/' })
        })
        .catch(err => {
          commit('setAuthError', err)
        })
    },
    async setLoginUser({ commit }, user) {
      commit('setLoginUser', user)
      await db
        .collection('langxAccountInfoUsers')
        .doc(user.uid)
        .update({ email: user.email, photoURL: user.photoURL })
      await db
        .collection('langxAccountInfoUsers')
        .doc(user.uid)
        .get()
        .then(doc => {
          commit('setUserInfo', doc.data())
        })
    },
    updateUserPhotoURL({ commit }, user) {
      const userInfoRef = db.collection('langxAccountInfoUsers').doc(user.uid)
      userInfoRef
        .update({
          photoURL: user.photoURL,
        })
        .then(() => {
          commit('setLoginUser', user)
        })
    },
    setSkillCheckedAt({ commit }, updateData) {
      return new Promise(function(resolve, reject) {
        const updateUserRef = db
          .collection('langxAccountInfoUsers')
          .doc(updateData.user.uid)
        updateUserRef
          .update({
            skillCheckedAt: updateData.skillCheckedAt,
          })
          .then(() => {
            updateUserRef.get().then(doc => {
              const userInfo = doc.data()
              commit('setUserInfo', userInfo)
              resolve()
            })
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    setUserInfo({ commit }, userInfo) {
      commit('setUserInfo', userInfo)
    },
    login() {
      const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
      firebase.auth().signInWithRedirect(googleAuthProvider)
    },
    signOut() {
      firebase.auth().signOut()
      this.commit('reset')
    },
    deleteLoginUser({ commit }) {
      commit('deleteLoginUser')
    },
    selectSidebarItem({ commit }, item) {
      commit('selectSidebarItem', item)
    },
    changeLanguage({ commit }, lang) {
      commit('onLangChanged', lang)
    },
    setCurrentUnitInfo({ commit }, currentUnitInfo) {
      commit('setCurrentUnitInfo', currentUnitInfo)
    },
    setUnitRecordInfo({ commit }, unitRecordInfo) {
      commit('setUnitRecordInfo', unitRecordInfo)
    },
  },
  getters: {
    user: state => (state.user ? state.user : null),
    userInfo: state => (state.userInfo ? state.userInfo : null),
    sidebar: state => (state.sidebar ? state.sidebar : null),
    language: state => (state.language ? state.language : 'en'),
    currentUnitInfo: state =>
      state.currentUnitInfo ? state.currentUnitInfo : null,
    unitRecordInfo: state =>
      state.unitRecordInfo ? state.unitRecordInfo : null,
  },
  // リロードしてもStateの中身を消さずに維持
  plugins: [
    createPersistedState({
      key: 'langx',
      storage: window.localStorage,
      paths: [
        'user',
        'userInfo',
        'language',
        'sidebar.selectSidebarItem',
        'currentUnitInfo',
        'unitRecordInfo',
      ],
    }),
  ],
})
