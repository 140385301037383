import Vue from 'vue'
import VueRouter from 'vue-router'
import Top from '@/views/Top.vue'
import store from '@/store'
import firebase from 'firebase/app'
import Portal from '@/views/Portal.vue'
import Prefs from '@/views/Prefs.vue'
import GroupFeedback from '@/views/GroupFeedback.vue'
import GroupFeedbackViewer from '@/views/GroupFeedbackViewer.vue'
import History from '@/views/History.vue'
import Meeting from '@/views/Meeting.vue'
import Dashboard from '@/views/Dashboard.vue'
import Schedule from '@/views/Schedule.vue'
import UnitRegistration from '@/views/Administrator/UnitRegistration.vue'
import UserManagement from '@/views/Administrator/UserManagement.vue'
import CourseManagement from '@/views/Administrator/CourseManagement.vue'
import SkillCheck from '@/views/SkillCheck.vue'
import MonitorMeeting from '@/views/Administrator/MonitorMeeting.vue'
import Interview from '@/views/Interview.vue'
import ClassRecordList from '@/views/Tutor/ClassRecordList.vue'
import ClassRecord from '@/views/Tutor/ClassRecord.vue'
import Monitor from '@/views/Administrator/Monitor.vue'
import DataWareHouse from '@/views/Administrator/DataWareHouse.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/welcome',
    name: 'Top',
    component: Top,
  },
  {
    path: '/',
    name: '',
    component: Portal,
    meta: {
      requiresAuth: true,
      title: 'LANGX',
      desc: '',
    },
    children: [
      {
        path: '',
        name: 'home',
        component: Dashboard,
      },
      {
        path: 'group-feedback',
        name: 'groupFeedback',
        component: GroupFeedback,
        meta: { requireUserType: ['Learner', 'Tutor', 'Administrator'] },
      },
      {
        path: 'prefs',
        name: 'profile',
        component: Prefs,
      },
      {
        path: 'history',
        name: 'history',
        component: History,
      },
      {
        path: 'schedule',
        name: 'schedule',
        component: Schedule,
      },
      {
        path: 'usermanagement',
        name: 'userManagement',
        component: UserManagement,
        meta: { requireUserType: ['Administrator'] },
      },
      {
        path: 'unitregistration',
        name: 'unitRegistration',
        component: UnitRegistration,
        meta: { requireUserType: ['Administrator'] },
      },
      {
        path: 'coursemanagement',
        name: 'courseManagement',
        component: CourseManagement,
        meta: { requireUserType: ['Administrator'] },
      },
      {
        path: 'class-record-list',
        name: 'classRecordList',
        component: ClassRecordList,
        meta: { requireUserType: ['Tutor', 'Administrator'] },
      },
      {
        path: 'monitor-meeting',
        name: 'monitorMeeting',
        component: MonitorMeeting,
        meta: { requireUserType: ['Administrator'] },
      },
      {
        path: 'datawarehouse',
        name: 'dataWareHouse',
        component: DataWareHouse,
        meta: { requireUserType: ['Administrator'] },
      },
    ],
  },
  {
    path: '/meeting/:id',
    name: 'Meeting',
    component: Meeting,
    meta: {
      requiresAuth: true,
      title: 'LESSON',
      desc: '',
    },
  },
  {
    path: '/interview/:id',
    name: 'Interview',
    component: Interview,
    meta: {
      title: 'INTERVIEW',
      desc: '',
    },
  },
  {
    path: '/class-record-list/:id',
    name: 'ClassRecord',
    component: ClassRecord,
    meta: {
      requiresAuth: true,
      requireUserType: ['Tutor', 'Administrator'],
      title: 'CLASSRECORD',
      desc: '',
    },
  },
  {
    path: '/skillcheck',
    name: 'SkillCheck',
    component: SkillCheck,
    meta: {
      requiresAuth: true,
      requireUserType: ['Learner', 'Administrator'],
    },
  },
  {
    path: '/monitor/:id',
    name: 'Monitor',
    component: Monitor,
    meta: {
      requiresAuth: true,
      requireUserType: ['Administrator'],
      title: 'MONITOR',
      desc: '',
    },
  },
  {
    path: '/group-feedback-viewer/:id',
    name: 'GroupFeedbackViewer',
    component: GroupFeedbackViewer,
    meta: {
      requiresAuth: true,
      requireUserType: ['Learner', 'Tutor', 'Administrator'],
      title: 'GROUP FEEDBACK',
      desc: '',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresUserType = to.matched.some(record =>
    record.meta.requireUserType
      ? record.meta.requireUserType.includes(store.state.userInfo.userType)
      : true
  )
  if (requiresAuth) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        if (requiresUserType) {
          next()
        } else {
          next({ path: '' })
        }
      } else {
        next({ name: 'Top' })
      }
    })
  } else {
    if (requiresUserType) {
      next()
    } else {
      next({ path: '' })
    }
  }
})

export default router
