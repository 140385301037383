<template>
  <v-app>
    <v-app-bar app class="white--text" color="#312f30" flat>
      <h3>
        Unit {{ currentUnitInfo.unitNo }} - {{ currentUnitInfo.unitTitle }}
      </h3>
      <v-spacer />
    </v-app-bar>
    <v-main class="bg">
      <v-container fill-height>
        <div
          id="videoContainer"
          v-resize="onResize"
          class="d-flex flex-wrap align-content-center justify-center mb-6 video--container"
        />
      </v-container>
    </v-main>
    <!-- footer tool -->
    <v-footer app absolute color="#312f30">
      <v-card color="transparent" flat tile width="100%">
        <v-row>
          <v-col class="d-flex justify-start" cols="3">
            <v-btn class="pa-7" depressed plain @click="toggleMicrophone()">
              <v-card color="transparent" flat tile>
                <v-icon color="#ffffff">
                  {{
                    microphoneDeviceEnabled
                      ? 'mdi-microphone'
                      : 'mdi-microphone-off'
                  }}
                </v-icon>
                <div class="white--text">
                  {{ microphoneDeviceEnabled ? $t('mute') : $t('unmute') }}
                </div>
              </v-card>
            </v-btn>
            <v-btn class="pa-7" depressed plain @click="toggleCamera()">
              <v-card color="transparent" flat tile>
                <v-icon color="#ffffff">
                  {{ cameraDeviceEnabled ? 'mdi-video' : 'mdi-video-off' }}
                </v-icon>
                <div class="white--text">
                  {{ cameraDeviceEnabled ? $t('videoOff') : $t('videoOn') }}
                </div>
              </v-card>
            </v-btn>
          </v-col>
          <v-col class="d-flex justify-center" cols="6">
            <v-btn class="pa-7" depressed plain @click="openParticipants()">
              <v-card color="transparent" flat tile>
                <v-icon color="#ffffff">
                  mdi-account-multiple
                </v-icon>
                <div class="white--text">
                  {{ $t('participants') }}
                </div>
              </v-card>
            </v-btn>
            <v-btn class="pa-7" depressed plain @click="openChat()">
              <v-card color="transparent" flat tile>
                <v-icon color="#ffffff">
                  mdi-chat
                </v-icon>
                <div class="white--text">
                  {{ $t('chat') }}
                </div>
              </v-card>
            </v-btn>
            <v-btn class="pa-7" depressed plain @click="raiseHand()">
              <v-card color="transparent" flat tile>
                <v-icon color="#ffffff">
                  mdi-hand-right
                </v-icon>
                <div class="white--text">
                  {{ handIsRaised ? $t('layHand') : $t('raiseHand') }}
                </div>
              </v-card>
            </v-btn>
          </v-col>
          <v-col class="d-flex justify-end" cols="3">
            <v-btn
              class="ma-2 px-5 grad-red white--text"
              depressed
              plain
              @click="confirmExitMeetingDialog = true"
            >
              <v-card color="transparent" flat tile>
                <div class="white--text">
                  {{ $t('leaveMeeting') }}
                </div>
              </v-card>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-footer>
    <v-navigation-drawer
      id="participantsDrawer"
      v-model="participants"
      max-width="700"
      app
      color="#312f30"
      right
    >
      <v-list-item dark flat tile />
      <v-card id="participantsWindow" color="transparent" dark flat tile>
        <v-card-text>
          <v-row class="my-5 px-2 grad-blue rounded">
            <v-col cols="12">
              <p class="white--text text-subtitle-2 ma-0 pa-0">
                {{ userInfo.nickName }}
              </p>
              <p class="white--text text-caption ma-0 pa-0">
                ( {{ userInfo.firstName }} {{ userInfo.lastName }} )
              </p>
            </v-col>
          </v-row>
          <v-divider dark />
          <v-row
            v-for="oneParticipant in allParticipants"
            :key="oneParticipant.streamId"
            :class="[
              oneParticipant.userType === 'Learner'
                ? 'my-5 px-2 bg-gray rounded'
                : 'my-5 px-2 grad-red rounded',
            ]"
          >
            <v-col cols="12">
              <p class="white--text text-subtitle-2 ma-0 pa-0">
                {{ oneParticipant.nickName }}
              </p>
              <p class="white--text text-caption ma-0 pa-0">
                ( {{ oneParticipant.firstName }} {{ oneParticipant.lastName }} )
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-navigation-drawer
      id="chatDrawer"
      v-model="chat"
      max-width="500"
      app
      color="#312f30"
      right
    >
      <v-card
        id="chatWindow"
        color="transparent"
        class="chat-window"
        dark
        flat
        tile
      >
        <v-card-text>
          <v-row
            v-for="oneMessage in chatMessages"
            :key="oneMessage.messageId"
            dense
            class="my-1 px-1"
          >
            <v-col v-if="oneMessage.isChangedState" cols="12">
              <p class="white--text text-caption ma-0 pa-0">
                {{
                  oneMessage.sentAtTime +
                    ' ' +
                    oneMessage.nickName +
                    oneMessage.message
                }}
              </p>
            </v-col>
            <v-col v-else cols="12">
              <p class="white--text text-caption ma-0 pa-0">
                {{ oneMessage.sentAtTime + ' ' + oneMessage.nickName }}
              </p>
              <p class="white--text text-body-1 pre-wrap-text ma-0 pa-0">
                {{ oneMessage.message.replaceAll('\\n', '\n') }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card color="transparent" dark flat tile>
        <v-card-actions id="chatForm" class="chat-card-actions">
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="message"
                rows="1"
                hide-details
                auto-grow
                :append-outer-icon="'mdi-send'"
                filled
                type="text"
                @click:append-outer="sendMessage()"
                @keydown.enter.shift="sendMessage()"
              />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-navigation-drawer>
    <v-dialog v-model="checkAudioVideoDialog" persistent dark flat width="500">
      <v-card>
        <v-card-title class="text-h5 lighten-2">
          {{ $t('testMicrophoneAndCamera') }}
        </v-card-title>
        <v-container>
          <v-row class="test--row">
            <v-col
              :cols="6"
              class="d-flex flex-wrap align-content-center justify-center"
            >
              <div id="cameraCheckWindow" />
            </v-col>
            <v-col
              :cols="6"
              class="d-flex flex-wrap align-content-center justify-center"
            >
              <v-select
                v-model="selectedCameraId"
                :items="cameras"
                item-text="label"
                item-value="deviceId"
                :rules="[v => !!v || $t('cameraIsRequired')]"
                :label="$t('selectCamera')"
                required
                @change="createTestStream()"
              />
            </v-col>
          </v-row>
          <v-row class="test--row">
            <v-col
              id="microphoneCheckWindow"
              :cols="6"
              class="d-flex flex-wrap align-content-center justify-space-around"
            >
              <v-icon color="#ffffff">
                {{
                  selectedMicrophoneId ? 'mdi-microphone' : 'mdi-microphone-off'
                }}
              </v-icon>
              <div v-for="n in parseInt(10)" :key="n" class="sound--bar" />
            </v-col>
            <v-col
              :cols="6"
              class="d-flex flex-wrap align-content-center justify-center"
            >
              <v-select
                v-model="selectedMicrophoneId"
                :items="microphones"
                item-text="label"
                item-value="deviceId"
                :rules="[v => !!v || $t('microphoneIsRequired')]"
                :label="$t('selectMicrophone')"
                required
                @change="createTestStream()"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="joinMeetingRoom()">
            {{ $t('join') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmExitMeetingDialog"
      persistent
      dark
      flat
      width="300"
    >
      <v-card>
        <v-card-title class="d-flex align-center justify-center lighten-2">
          <p class="text-h6 pt-2">
            {{ $t('confirmLeave') }}
          </p>
        </v-card-title>
        <v-container>
          <v-row>
            <v-col :cols="12">
              <v-btn class="grad-red" block x-large @click="leaveMeeting()">
                {{ $t('leaveMeeting') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="12">
              <v-btn block x-large @click="confirmExitMeetingDialog = false">
                {{ $t('cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="errorSnackbar" timeout="3000">
      {{ errorMessage }}
      <template #action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="errorSnackbar = false">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import AgoraRTC from 'agora-rtc-sdk'
import { mapGetters } from 'vuex'
import firebase from '@/plugins/firebase'
import moment from 'moment'

export default {
  name: 'Meeting',
  data: () => {
    return {
      checkAudioVideoDialog: true,
      selectedCameraId: null,
      cameras: [],
      selectedMicrophoneId: null,
      microphones: [],
      testStreamId: 123456789,
      testStream: null,
      audioTestTimer: null,
      microphoneDeviceEnabled: {
        type: Boolean,
        default: true,
      },
      cameraDeviceEnabled: {
        type: Boolean,
        default: true,
      },
      meetingId: '',
      chat: false,
      chatMessages: [],
      message: '',
      newMessage: false,
      participants: false,
      allParticipants: {},
      handIsRaised: false,
      localStreamId: 0,
      client: AgoraRTC.createClient({
        mode: 'live',
        codec: 'h264',
      }),
      localStream: null,
      realTimeDB: firebase.database(),
      errorSnackbar: false,
      errorMessage: '',
      confirmExitMeetingDialog: false,
      meetingParticipantsListener: null,
      meetingChatListener: null,
      meetingLikesListener: null,
      meetingRaiseHandsListener: null,
      meetingLayHandsListener: null,
    }
  },
  computed: {
    ...mapGetters(['user', 'userInfo', 'currentUnitInfo']),
  },
  mounted() {
    this.meetingId = this.$route.params.id
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(() => {
        AgoraRTC.getDevices(devices => {
          this.microphones = devices.filter(device => {
            return device.kind === 'audioinput'
          })
          this.cameras = devices.filter(device => {
            return device.kind === 'videoinput'
          })
        })
      })
      .catch(() => {
        this.errorMessage = this.$t('pleaseAllowAccessCameraAndMicrophone')
        this.errorSnackbar = true
      })
    this.onResize()
  },
  destroyed() {
    this.$returnScroll()
  },
  beforeDestroy() {
    this.meetingParticipantsListener.off()
    this.meetingChatListener.off()
    this.meetingLikesListener.off()
    this.meetingRaiseHandsListener.off()
    this.meetingLayHandsListener.off()
  },
  updated() {
    if (this.newMessage) {
      this.resizeChatWindow()
      this.newMessage = false
    }
  },
  methods: {
    createTestStream() {
      if (this.testStream) {
        this.testStream.close()
        const testVideoDiv = document.getElementById('testVideo')
        testVideoDiv.remove()
      }
      if (this.audioTestTimer) {
        clearInterval(this.audioTestTimer)
      }
      this.testStream = AgoraRTC.createStream({
        streamID: this.testStreamId,
        audio: !!this.selectedMicrophoneId,
        microphoneId: this.selectedMicrophoneId
          ? this.selectedMicrophoneId
          : null,
        video: !!this.selectedCameraId,
        cameraId: this.selectedCameraId ? this.selectedCameraId : null,
        screen: false,
      })
      this.testStream.init(() => {
        const microphoneCheckWindow = document.getElementById(
          'microphoneCheckWindow'
        )
        this.audioTestTimer = setInterval(() => {
          const soundLevel = Math.floor(this.testStream.getAudioLevel() * 10)
          let level = 0
          for (level; level < soundLevel; level++) {
            microphoneCheckWindow.children[level + 1].style.backgroundColor =
              '#b80e65'
          }
          for (level; level < 10; level++) {
            microphoneCheckWindow.children[level + 1].style.backgroundColor =
              '#312f30'
          }
        }, 100)
        const cameraCheckWindow = document.getElementById('cameraCheckWindow')
        const testVideoDiv = document.createElement('div')
        testVideoDiv.id = 'testVideo'
        testVideoDiv.style.width = '200px'
        testVideoDiv.style.height = '150px'
        testVideoDiv.style.margin = '5px'
        cameraCheckWindow.appendChild(testVideoDiv)
        this.testStream.setScreenProfile('480p_2')
        this.testStream.play('testVideo')
      })
    },
    joinMeetingRoom() {
      if (this.selectedCameraId && this.selectedMicrophoneId) {
        this.testStream.close()
        const testVideoDiv = document.getElementById('testVideo')
        testVideoDiv.remove()
        clearInterval(this.audioTestTimer)
        this.checkAudioVideoDialog = false
        this.localStream = AgoraRTC.createStream({
          streamID: 'local',
          audio: true,
          microphoneId: this.selectedMicrophoneId,
          video: true,
          cameraId: this.selectedCameraId,
        })
        this.joinMeeting()
        this.listenParticipants()
        this.listenChat()
        this.listenLikes()
        this.listenRaiseHands()
        this.onResize()
      }
    },
    listenParticipants() {
      const meetingParticipantsRef = this.realTimeDB.ref(
        `/realTimeInteractions/${this.meetingId}/meetingParticipants`
      )
      this.meetingParticipantsListener = meetingParticipantsRef.on(
        'value',
        snapshot => {
          this.allParticipants = {}
          const allParticipants = snapshot.val()
          for (const oneParticipantStreamId in allParticipants) {
            const onePlayer = document.getElementById(
              `player_${oneParticipantStreamId}`
            )
            const oneParticipant = allParticipants[oneParticipantStreamId]
            if (oneParticipant.userType !== 'Learner' && onePlayer) {
              const currentNameTag = document.getElementById(
                `nameTag_${oneParticipantStreamId}`
              )
              if (currentNameTag) {
                currentNameTag.remove()
              }
              const nameTag = document.createElement('div')
              nameTag.id = `nameTag_${oneParticipantStreamId}`
              nameTag.textContent = ` ${oneParticipant.userType}: ${oneParticipant.nickName} ( ${oneParticipant.firstName} ${oneParticipant.lastName} ) `
              nameTag.className = 'name--tag grad-red white--text'
              onePlayer.appendChild(nameTag)
              const likeButton = document.createElement('a')
              likeButton.href = 'javascript:void(0);'
              likeButton.className = 'like--btn bg-gray'
              likeButton.textContent = '👍'
              likeButton.zIndex = 100
              onePlayer.appendChild(likeButton)
              likeButton.addEventListener('click', () => {
                likeButton.classList.add('liked')
                const meetingLikesRef = this.realTimeDB.ref(
                  `/realTimeInteractions/${this.meetingId}/meetingLikes/`
                )
                const timeStamp = Date.now()
                meetingLikesRef.push({
                  sentUserNickName: this.userInfo.nickName,
                  sentUserId: this.userInfo.userId,
                  receivedUserId: oneParticipant.userId,
                  receivedUserNickName: oneParticipant.nickName,
                  receivedStreamId: oneParticipantStreamId,
                  likedAt: timeStamp,
                })
              })
              likeButton.addEventListener('mouseover', () => {
                likeButton.classList.remove('liked')
              })
              this.allParticipants[oneParticipantStreamId] = {
                oneParticipantStreamId: oneParticipantStreamId,
                ...oneParticipant,
              }
              delete allParticipants[oneParticipantStreamId]
            }
          }
          for (const oneParticipantStreamId in allParticipants) {
            const onePlayer = document.getElementById(
              `player_${oneParticipantStreamId}`
            )
            const oneParticipant = allParticipants[oneParticipantStreamId]
            if (onePlayer) {
              const currentNameTag = document.getElementById(
                `nameTag_${oneParticipantStreamId}`
              )
              if (currentNameTag) {
                currentNameTag.remove()
              }
              const nameTag = document.createElement('div')
              nameTag.id = `nameTag_${oneParticipantStreamId}`
              nameTag.textContent = ` ${oneParticipant.nickName} ( ${oneParticipant.firstName} ${oneParticipant.lastName} ) `
              nameTag.className = 'name--tag bg-gray white--text'
              onePlayer.appendChild(nameTag)
              const likeButton = document.createElement('a')
              likeButton.href = 'javascript:void(0);'
              likeButton.className = 'like--btn bg-gray'
              likeButton.textContent = '👍'
              likeButton.zIndex = 100
              onePlayer.appendChild(likeButton)
              likeButton.addEventListener('click', () => {
                likeButton.classList.add('liked')
                const meetingLikesRef = this.realTimeDB.ref(
                  `/realTimeInteractions/${this.meetingId}/meetingLikes/`
                )
                const timeStamp = Date.now()
                meetingLikesRef.push({
                  sentUserNickName: this.userInfo.nickName,
                  sentUserId: this.userInfo.userId,
                  receivedUserId: oneParticipant.userId,
                  receivedUserNickName: oneParticipant.nickName,
                  receivedStreamId: oneParticipantStreamId,
                  likedAt: timeStamp,
                })
              })
              likeButton.addEventListener('mouseover', () => {
                likeButton.classList.remove('liked')
              })
              this.allParticipants[oneParticipantStreamId] = {
                oneParticipantStreamId: oneParticipantStreamId,
                ...oneParticipant,
              }
            }
          }
        }
      )
    },
    listenChat() {
      const meetingChatRef = this.realTimeDB.ref(
        `/realTimeInteractions/${this.meetingId}/meetingChat`
      )
      meetingChatRef.once('value').then(snapshot => {
        if (snapshot.exists()) {
          const allChatMessages = snapshot.val()
          this.chatMessages = Object.entries(allChatMessages).map(
            ([key, value]) => ({
              messageId: key,
              sentAtTime: moment(value.sentAt).format('HH:mm:ss'),
              ...value,
            })
          )
        }
      })
      this.meetingChatListener = meetingChatRef.on('child_added', snapshot => {
        const oneMessage = snapshot.val()
        const messageId = snapshot.key
        this.chatMessages.push({
          messageId: messageId,
          sentAtTime: moment(oneMessage.sentAt).format('HH:mm:ss'),
          ...oneMessage,
        })
        this.newMessage = true
      })
    },
    listenLikes() {
      const meetingLikesRef = this.realTimeDB.ref(
        `/realTimeInteractions/${this.meetingId}/meetingLikes`
      )
      this.meetingLikesListener = meetingLikesRef.on(
        'child_added',
        snapshot => {
          const oneLike = snapshot.val()
          const onePlayer = document.getElementById(
            `player_${oneLike.receivedStreamId}`
          )
          if (onePlayer) {
            const fallingLike = document.createElement('div')
            fallingLike.textContent = '👍'
            fallingLike.style.right = Math.random() * 10 + 40 + 'px'
            fallingLike.className = 'falling--like'
            onePlayer.appendChild(fallingLike)
            this.likeMessage = `${oneLike.sentUserNickName} sent a like to ${oneLike.receivedUserNickName}.`
            this.likeSnackbar = true
          } else if (
            String(this.localStreamId) === String(oneLike.receivedStreamId)
          ) {
            const fallingLike = document.createElement('div')
            fallingLike.textContent = '👍'
            fallingLike.style.right = Math.random() * 10 + 10 + 'px'
            fallingLike.className = 'falling--like'
            document.getElementById('player_local').appendChild(fallingLike)
            this.likeMessage = `${oneLike.sentUserNickName} sent like to you.`
            this.likeSnackbar = true
          }
        }
      )
    },
    listenRaiseHands() {
      const meetingRaiseHandsRef = this.realTimeDB.ref(
        `/realTimeInteractions/${this.meetingId}/meetingRaiseHands`
      )
      this.meetingRaiseHandsListener = meetingRaiseHandsRef.on(
        'child_added',
        snapshot => {
          const newRaiserId = snapshot.key
          const onePlayer = document.getElementById(`player_${newRaiserId}`)
          if (onePlayer) {
            const raisedHand = document.createElement('div')
            raisedHand.textContent = '✋'
            raisedHand.className = 'raised--hand grad-blue'
            raisedHand.id = `hand_${newRaiserId}`
            raisedHand.style.left = 0
            onePlayer.appendChild(raisedHand)
          } else if (String(this.localStreamId) === String(newRaiserId)) {
            const raisedHand = document.createElement('div')
            raisedHand.textContent = '✋'
            raisedHand.className = 'raised--hand grad-blue'
            raisedHand.id = `hand_${newRaiserId}`
            raisedHand.style.left = 0
            document.getElementById('player_local').appendChild(raisedHand)
          }
        }
      )
      this.meetingLayHandsListener = meetingRaiseHandsRef.on(
        'child_removed',
        snapshot => {
          const removedRaiserId = snapshot.key
          const removedRaisedHand = document.getElementById(
            `hand_${removedRaiserId}`
          )
          if (removedRaisedHand) {
            removedRaisedHand.remove()
          }
        }
      )
    },
    onResize() {
      this.resizeVideos()
      this.resizeChatWindow()
    },
    toggleMicrophone() {
      this.microphoneDeviceEnabled = !this.microphoneDeviceEnabled
      if (this.microphoneDeviceEnabled) {
        this.localStream.unmuteAudio()
        document.getElementById('muteIcon_local').remove()
      } else {
        this.localStream.muteAudio()
        const muteIcon = document.createElement('i')
        muteIcon.className = 'mdi mdi-microphone-off'
        muteIcon.id = 'muteIcon_local'
        document
          .getElementById('nameTag_local')
          .insertAdjacentElement('afterbegin', muteIcon)
      }
    },
    toggleCamera() {
      this.cameraDeviceEnabled = !this.cameraDeviceEnabled
      this.cameraDeviceEnabled
        ? this.localStream.unmuteVideo()
        : this.localStream.muteVideo()
      if (!this.cameraDeviceEnabled) {
        const userPhoto = document.createElement('img')
        userPhoto.className = 'rounded-circle user-photo'
        userPhoto.src = this.userInfo.photoURL
        userPhoto.id = `userPhoto_local`
        document
          .getElementById(`player_local`)
          .insertAdjacentElement('afterBegin', userPhoto)
      } else {
        document.getElementById(`userPhoto_local`).remove()
      }
    },
    openParticipants() {
      this.chat = false
      this.participants = !this.participants
    },
    openChat() {
      this.participants = false
      this.chat = !this.chat
    },
    sendMessage() {
      if (this.message) {
        const meetingChatRef = this.realTimeDB.ref(
          `/realTimeInteractions/${this.meetingId}/meetingChat`
        )
        const unixTimeNow = Date.now()
        meetingChatRef.push({
          nickName: this.userInfo.nickName,
          userId: this.userInfo.userId,
          message: this.message.replaceAll('\n', '\\n'),
          sentAt: unixTimeNow,
          streamId: this.localStreamId,
          isChangedState: false,
        })
        this.message = ''
      }
    },
    raiseHand() {
      this.handIsRaised = !this.handIsRaised
      const meetingRaiseHandRef = this.realTimeDB.ref(
        `/realTimeInteractions/${this.meetingId}/meetingRaiseHands/${this.localStreamId}`
      )
      if (this.handIsRaised) {
        meetingRaiseHandRef.set({
          userId: this.userInfo.userId,
          nickName: this.userInfo.nickName,
        })
      } else {
        meetingRaiseHandRef.remove()
      }
    },
    leaveMeeting() {
      const meetingChatRef = this.realTimeDB.ref(
        `/realTimeInteractions/${this.meetingId}/meetingChat`
      )
      const unixTimeNow = Date.now()
      meetingChatRef.push({
        nickName: this.userInfo.nickName,
        userId: this.userInfo.userId,
        message: ' has left the room',
        sentAt: unixTimeNow,
        streamId: this.localStreamId,
        isChangedState: true,
      })
      window.close()
    },
    resizeVideos() {
      const videoContainer = document.getElementById('videoContainer')
      const baseWidth =
        videoContainer.clientHeight * 4 > videoContainer.clientWidth * 3
          ? videoContainer.clientWidth
          : (videoContainer.clientHeight / 3) * 4
      const numberOfColumns = Math.ceil(
        Math.sqrt(videoContainer.children.length)
      )
      const oneVideoWidth = baseWidth / numberOfColumns
      const oneVideoHeight = (oneVideoWidth / 4) * 3
      Array.from(videoContainer.children).forEach(oneChild => {
        oneChild.style.width = oneVideoWidth + 'px'
        oneChild.style.height = oneVideoHeight + 'px'
      })
    },
    resetParticipantsDrawer() {
      const meetingParticipantsRef = this.realTimeDB.ref(
        `/realTimeInteractions/${this.meetingId}/meetingParticipants`
      )
      meetingParticipantsRef.once('value').then(snapshot => {
        this.allParticipants = {}
        const allParticipants = snapshot.val()
        for (const oneParticipantStreamId in allParticipants) {
          const onePlayer = document.getElementById(
            `player_${oneParticipantStreamId}`
          )
          if (
            allParticipants[oneParticipantStreamId].userType !== 'Learner' &&
            onePlayer
          ) {
            this.allParticipants[oneParticipantStreamId] = {
              streamId: oneParticipantStreamId,
              ...allParticipants[oneParticipantStreamId],
            }
            delete allParticipants[oneParticipantStreamId]
          }
        }
        for (const oneParticipantStreamId in allParticipants) {
          const onePlayer = document.getElementById(
            `player_${oneParticipantStreamId}`
          )
          if (onePlayer) {
            this.allParticipants[oneParticipantStreamId] = {
              streamId: oneParticipantStreamId,
              ...allParticipants[oneParticipantStreamId],
            }
          }
        }
      })
    },
    resizeChatWindow() {
      const chatWindow = document.getElementById('chatWindow')
      const chatForm = document.getElementById('chatForm')
      const chatDrawer = chatWindow.parentNode
      chatWindow.style.height =
        chatDrawer.clientHeight - chatForm.clientHeight + 'px'
      chatWindow.scrollTop = chatWindow.scrollHeight
    },
    joinMeeting() {
      const videoContainer = document.getElementById('videoContainer')
      this.client.init(process.env.VUE_APP_AGORA_APP_ID)
      this.client.join(null, this.meetingId, null, () => {
        this.localStream.init(() => {
          const localDiv = document.createElement('div')
          localDiv.id = 'localVideo'
          localDiv.style.width = (videoContainer.clientHeight / 3) * 4 + 'px'
          localDiv.style.height = videoContainer.clientHeight + 'px'
          localDiv.style.margin = '5px'
          videoContainer.appendChild(localDiv)
          this.localStream.setScreenProfile('480p_2')
          this.localStream.play('localVideo')
          const nameTag = document.createElement('div')
          nameTag.textContent =
            this.userInfo.userType === 'Learner'
              ? ` ${this.userInfo.nickName} ( ${this.userInfo.firstName} ${this.userInfo.lastName} ) `
              : ` ${this.userInfo.userType} : ${this.userInfo.nickName} ( ${this.userInfo.firstName} ${this.userInfo.lastName} ) `
          nameTag.id = 'nameTag_local'
          nameTag.className =
            this.userInfo.userType === 'Learner'
              ? 'name--tag bg-gray white--text'
              : 'name--tag grad-red white--text'
          document.getElementById('player_local').appendChild(nameTag)
          this.client.publish(this.localStream)
          this.localStreamId = this.localStream.getId()
          const meetingParticipantsRef = this.realTimeDB.ref(
            `/realTimeInteractions/${this.meetingId}/meetingParticipants/${this.localStreamId}`
          )
          const participantObject = {
            userId: this.userInfo.userId,
            userType: this.userInfo.userType,
            nickName: this.userInfo.nickName,
            firstName: this.userInfo.firstName,
            lastName: this.userInfo.lastName,
            photoURL: this.userInfo.photoURL,
          }
          meetingParticipantsRef.set(participantObject)
          const meetingChatRef = this.realTimeDB.ref(
            `/realTimeInteractions/${this.meetingId}/meetingChat`
          )
          const unixTimeNow = Date.now()
          meetingChatRef.push({
            nickName: this.userInfo.nickName,
            userId: this.userInfo.userId,
            message: ' joined the room',
            sentAt: unixTimeNow,
            streamId: this.localStreamId,
            isChangedState: true,
          })
          this.resizeVideos()
        })
        this.client.on('stream-added', evt => {
          this.client.subscribe(evt.stream)
        })
        this.client.on('stream-subscribed', evt => {
          const stream = evt.stream
          const streamId = String(stream.getId())
          this.addVideoStream(streamId)
          stream.play(streamId)
          const meetingParticipantsRef = this.realTimeDB.ref(
            `/realTimeInteractions/${this.meetingId}/meetingParticipants/${streamId}`
          )
          meetingParticipantsRef.once('value').then(snapshot => {
            const onePlayer = document.getElementById(`player_${streamId}`)
            const oneParticipant = snapshot.val()
            const currentNameTag = document.getElementById(
              `nameTag_${streamId}`
            )
            if (currentNameTag) {
              currentNameTag.remove()
            }
            const nameTag = document.createElement('div')
            nameTag.id = `nameTag_${streamId}`
            nameTag.textContent =
              oneParticipant.userType === 'Learner'
                ? ` ${oneParticipant.nickName} ( ${oneParticipant.firstName} ${oneParticipant.lastName} ) `
                : ` ${oneParticipant.userType}: ${oneParticipant.nickName} ( ${oneParticipant.firstName} ${oneParticipant.lastName} ) `
            nameTag.className =
              oneParticipant.userType === 'Learner'
                ? 'name--tag bg-gray white--text'
                : 'name--tag grad-red white--text'
            onePlayer.appendChild(nameTag)
            const likeButton = document.createElement('a')
            likeButton.href = 'javascript:void(0);'
            likeButton.className = 'like--btn bg-gray'
            likeButton.textContent = '👍'
            likeButton.zIndex = 100
            onePlayer.appendChild(likeButton)
            likeButton.addEventListener('click', () => {
              likeButton.classList.add('liked')
              const meetingLikesRef = this.realTimeDB.ref(
                `/realTimeInteractions/${this.meetingId}/meetingLikes/`
              )
              const timeStamp = Date.now()
              meetingLikesRef.push({
                sentUserNickName: this.userInfo.nickName,
                sentUserId: this.userInfo.userId,
                receivedUserId: oneParticipant.userId,
                receivedUserNickName: oneParticipant.nickName,
                receivedStreamId: streamId,
                likedAt: timeStamp,
              })
            })
            likeButton.addEventListener('mouseover', () => {
              likeButton.classList.remove('liked')
            })
            this.allParticipants[streamId] = {
              streamId: streamId,
              ...oneParticipant,
            }
          })
          this.resizeVideos()
        })
        this.client.on('stream-removed', evt => {
          const stream = evt.stream
          const streamId = String(stream.getId())
          stream.close()
          this.removeVideoStream(streamId)
          this.resetParticipantsDrawer()
          this.resizeVideos()
        })
        this.client.on('peer-leave', evt => {
          const stream = evt.stream
          const streamId = String(stream.getId())
          stream.close()
          this.removeVideoStream(streamId)
          this.resetParticipantsDrawer()
          this.resizeVideos()
        })
        this.client.on('mute-audio', evt => {
          const streamId = evt.uid
          const muteIcon = document.createElement('i')
          muteIcon.className = 'mdi mdi-microphone-off'
          muteIcon.id = `muteIcon_${streamId}`
          document
            .getElementById(`nameTag_${streamId}`)
            .insertAdjacentElement('afterbegin', muteIcon)
        })
        this.client.on('unmute-audio', evt => {
          const streamId = evt.uid
          document.getElementById(`muteIcon_${streamId}`).remove()
        })
        this.client.on('mute-video', evt => {
          const streamId = evt.uid
          const userPhoto = document.createElement('img')
          userPhoto.className = 'rounded-circle user-photo'
          userPhoto.src = this.allParticipants[streamId].photoURL
          userPhoto.id = `userPhoto_${streamId}`
          document
            .getElementById(`player_${streamId}`)
            .insertAdjacentElement('afterBegin', userPhoto)
        })
        this.client.on('unmute-video', evt => {
          const streamId = evt.uid
          document.getElementById(`userPhoto_${streamId}`).remove()
        })
      })
    },
    addVideoStream(elementId) {
      const videoContainer = document.getElementById('videoContainer')
      const streamDiv = document.createElement('div')
      streamDiv.id = elementId
      streamDiv.style.width = '400px'
      streamDiv.style.height = '300px'
      streamDiv.style.margin = '5px'
      videoContainer.appendChild(streamDiv)
    },
    removeVideoStream(elementId) {
      const remoteDiv = document.getElementById(elementId)
      if (remoteDiv) remoteDiv.parentNode.removeChild(remoteDiv)
    },
  },
}
</script>

<style scoped>
.bg {
  height: 100vh; /* 全画面表示 */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url('@/static/bg.png');
}

.video--container {
  width: 100%;
  height: 100%;
}

.chat-card-actions {
  position: fixed;
  bottom: 0;
}

.chat-window {
  overflow-y: scroll;
}

.pre-wrap-text {
  white-space: pre-wrap;
}
</style>
