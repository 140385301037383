<template color="#f9f9f9">
  <v-card color="transparent" flat tile width="100%">
    <v-row>
      <v-col
        v-if="isGroupFeedbackFormOpened && !isGroupFeedbackDisplayed"
        cols="9"
      >
        <v-row align="center" class="px-9">
          <v-divider />
          <v-subheader>
            {{ $t('sendGroupFeedback') }}
          </v-subheader>
          <v-divider />
        </v-row>
        <group-feedback-info
          :unit="currentWritingGroupFeedbackUnit"
          :tutor="currentWritingGroupFeedbackUnit.tutor"
          :learners="currentWritingGroupFeedbackUnit.learners"
        />
        <group-feedback-form
          :unit="currentWritingGroupFeedbackUnit"
          @submitGroupFeedback="submittedGroupFeedback"
        />
      </v-col>
      <v-col
        v-if="!isGroupFeedbackFormOpened && allGroupFeedbacks.length > 0"
        :cols="isGroupFeedbackDisplayed ? 3 : 9"
      >
        <v-list two-line class="mx-2">
          <v-list-item-group>
            <v-subheader>
              {{ $t('inbox') }}
            </v-subheader>
            <v-divider />
            <template v-for="(oneGroupFeedback, index) in allGroupFeedbacks">
              <v-list-item
                :key="oneGroupFeedback.unitId"
                dense
                @click="toggleGroupFeedback(oneGroupFeedback)"
              >
                <v-list-item-avatar v-if="!isGroupFeedbackDisplayed">
                  <v-img :src="oneGroupFeedback.unit.tutor.photoURL" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-h6"
                    v-text="oneGroupFeedback.unitTitle"
                  />
                  <v-list-item-subtitle
                    v-if="!isGroupFeedbackDisplayed"
                    class="text-caption"
                    v-text="oneGroupFeedback.content.replaceAll('\\n', '\n')"
                  />
                  <v-list-item-subtitle
                    v-if="isGroupFeedbackDisplayed"
                    class="text-caption"
                    v-text="oneGroupFeedback.startAtDate"
                  />
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-subtitle
                    v-if="!isGroupFeedbackDisplayed"
                    class="text-caption"
                    v-text="oneGroupFeedback.startAtDate"
                  />
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="index" />
            </template>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col
        v-if="!isGroupFeedbackFormOpened && allGroupFeedbacks.length === 0"
        cols="9"
        class="d-flex align-center justify-center"
      >
        <p class="text--primary">{{ noGroupFeedbacks }}</p>
      </v-col>
      <v-col
        v-if="userInfo.userType === 'Tutor' && !isGroupFeedbackDisplayed"
        cols="3"
      >
        <v-list two-line>
          <v-list-item-group v-model="activeUnitIndex">
            <v-subheader>
              {{ $t('endedUnitsTitle') }}
            </v-subheader>
            <template v-for="(oneUnit, index) in unwrittenGroupFeedbackUnits">
              <v-divider :key="index" />
              <v-list-item
                :key="oneUnit.unitId"
                v-slot="{ active }"
                @click="createNewGroupFeedback(oneUnit)"
              >
                <v-list-item-icon>
                  <v-icon v-if="!active" color="grey lighten-1">
                    mdi-pencil-outline
                  </v-icon>
                  <v-icon v-else class="pink-red-icon">
                    mdi-pencil
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="
                      `${oneUnit.courseTitle}, Unit ${oneUnit.unitNo}: ${oneUnit.unitTitle}`
                    "
                  />
                  <v-list-item-subtitle
                    class="text--primary"
                    v-text="oneUnit.date + ' ' + oneUnit.startAt"
                  />
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-divider />
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col v-if="isGroupFeedbackDisplayed" cols="9">
        <v-card class="pa-9 ma-4" color="transparent" tile flat outlined>
          <v-row align="center">
            <v-divider />
            <v-subheader>
              {{ $t('groupFeedback') }}
            </v-subheader>
            <v-divider />
          </v-row>
          <group-feedback-info
            :unit="currentDisplayingUnit"
            :tutor="currentDisplayingUnit.tutor"
            :learners="currentDisplayingUnit.learners"
            class="pb-4"
          />
          <v-card flat tile outlined class="mt-5 pa-9">
            <v-row align="center" class="pa-4">
              <span class="pre-wrap-text text-body-1">
                {{
                  currentDisplayingGroupFeedback.content.replaceAll('\\n', '\n')
                }}
              </span>
            </v-row>
          </v-card>
          <v-row
            v-if="currentDisplayingComments.length > 0"
            align="center"
            class="py-4"
          >
            <v-divider />
            <v-subheader>
              {{ $t('comment') }}
            </v-subheader>
            <v-divider />
          </v-row>
          <template v-for="oneComment in currentDisplayingComments">
            <comment-card
              :key="oneComment.createdAt"
              :comment="oneComment"
              :sender="oneComment.sender"
            />
          </template>
          <v-row align="center" class="py-4">
            <v-divider />
            <v-subheader>
              {{ $t('sendComment') }}
            </v-subheader>
            <v-divider />
          </v-row>
          <comment-form
            :group-feedback="currentDisplayingGroupFeedback"
            @sentComment="sentComment"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="messageSnackbar" timeout="3000">
      {{ snackbarMessage }}
      <template #action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="messageSnackbar = false"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :value="pageLoadingOverlay">
      <v-progress-circular
        indeterminate
        color="#1791b1"
        size="100"
        width="10"
      />
    </v-overlay>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { db } from '@/plugins/firebase'
import moment from 'moment'
import GroupFeedbackForm from '@/components/GroupFeedbackForm.vue'
import GroupFeedbackInfo from '@/components/GroupFeedbackInfo.vue'
import CommentForm from '@/components/CommentForm.vue'
import CommentCard from '@/components/CommentCard.vue'

export default {
  components: {
    GroupFeedbackForm,
    GroupFeedbackInfo,
    CommentForm,
    CommentCard,
  },
  data() {
    return {
      allGroupFeedbacks: [],
      unwrittenGroupFeedbackUnits: [],
      isGroupFeedbackFormOpened: false,
      currentWritingGroupFeedbackUnit: { unitId: 'none' },
      currentWritingGroupFeedbackLearners: {},
      currentWritingGroupFeedbackTutor: null,
      currentDisplayingUnit: null,
      currentDisplayingGroupFeedback: { unitId: 'none' },
      currentDisplayingComments: [],
      activeUnitIndex: null,
      isGroupFeedbackDisplayed: false,
      messageSnackbar: false,
      snackbarMessage: '',
      noGroupFeedbacks: '',
      unsubscribeGroupFeedback: null,
      pageLoadingOverlay: true,
    }
  },
  computed: {
    ...mapGetters(['user', 'userInfo']),
  },
  mounted() {
    this.userInfo.unitIds.forEach(async oneUnitId => {
      await db
        .collection('langxLearningContentGroupFeedbacks')
        .doc(oneUnitId)
        .get()
        .then(async doc => {
          if (doc.exists) {
            // フィードバックのロード
            const oneGroupFeedback = doc.data()
            const groupFeedbackWithUsers = await this.getGroupFeedbackWithUsers(
              oneGroupFeedback
            )
            this.allGroupFeedbacks.push(groupFeedbackWithUsers)
          } else if (this.userInfo.userType === 'Tutor') {
            // フィードバック未作成のユニットのロード
            const unitWithUsers = await this.getUnitWithUsers(oneUnitId)
            if (unitWithUsers)
              this.unwrittenGroupFeedbackUnits.push(unitWithUsers)
          }
        })
      this.pageLoadingOverlay = false
      this.noGroupFeedbacks = this.$t('noGroupFeedbacks')
    })
  },
  beforeDestroy() {
    if (this.unsubscribeGroupFeedback) {
      this.unsubscribeGroupFeedback()
    }
  },
  methods: {
    createNewGroupFeedback(unit) {
      this.isGroupFeedbackFormOpened =
        this.currentWritingGroupFeedbackUnit.unitId !== unit.unitId
      if (this.isGroupFeedbackFormOpened) {
        this.currentWritingGroupFeedbackUnit = unit
      } else {
        this.currentWritingGroupFeedbackUnit = { unitId: 'none' }
      }
    },
    async submittedGroupFeedback(groupFeedback) {
      await db
        .collection('langxLearningContentUnits')
        .doc(groupFeedback.unitId)
        .set({ isGroupFeedbacked: true }, { merge: true })
        .then(async () => {
          this.unwrittenGroupFeedbackUnits = this.unwrittenGroupFeedbackUnits.filter(
            oneUnit => oneUnit.unitId !== groupFeedback.unitId
          )
          const groupFeedbackWithUsers = await this.getGroupFeedbackWithUsers(
            groupFeedback
          )
          this.allGroupFeedbacks.push(groupFeedbackWithUsers)
          this.isGroupFeedbackFormOpened = false
          this.currentWritingGroupFeedbackUnit = { unitId: 'none' }
          this.snackbarMessage = this.$t('groupFeedbackSuccessMessage')
          this.messageSnackbar = true
        })
    },
    toggleGroupFeedback(groupFeedback) {
      this.isGroupFeedbackDisplayed =
        this.currentDisplayingGroupFeedback.unitId !== groupFeedback.unitId
      if (this.isGroupFeedbackDisplayed) {
        this.currentDisplayingComments = []
        this.unsubscribeGroupFeedback = db
          .collection('langxLearningContentGroupFeedbacks')
          .doc(groupFeedback.unitId)
          .onSnapshot(async doc => {
            const updatedGroupFeedback = doc.data()
            if (updatedGroupFeedback.comments.length > 0) {
              await updatedGroupFeedback.comments.forEach(
                (oneComment, index) => {
                  updatedGroupFeedback.comments[index].sender =
                    groupFeedback.unit.users[oneComment.userId]
                }
              )
              this.currentDisplayingComments = updatedGroupFeedback.comments
            }
          })
        this.currentDisplayingGroupFeedback = groupFeedback
        this.currentDisplayingUnit = groupFeedback.unit
      } else {
        this.unsubscribeGroupFeedback()
        this.currentDisplayingComments = []
        this.currentDisplayingGroupFeedback = { unitId: 'none' }
        this.currentDisplayingUnit = null
      }
    },
    sentComment() {
      this.snackbarMessage = this.$t('commentSuccessMessage')
      this.messageSnackbar = true
    },
    async getGroupFeedbackWithUsers(groupFeedback) {
      const groupFeedbackUnit = await this.getUnitWithUsers(
        groupFeedback.unitId
      )
      groupFeedback.unit = groupFeedbackUnit
      groupFeedback.unitTitle =
        groupFeedbackUnit.courseTitle +
        ' Unit ' +
        groupFeedbackUnit.unitNo +
        ' - ' +
        groupFeedbackUnit.unitTitle
      groupFeedback.startAtDate = moment(groupFeedback.createdAt).format(
        'YYYY/MM/DD HH:mm'
      )
      return groupFeedback
    },
    async getUnitWithUsers(unitId) {
      const unitInfoDoc = await db
        .collection('langxLearningContentUnits')
        .doc(unitId)
        .get()
      const unitInfo = unitInfoDoc.data()
      const unitStartTime = moment(unitInfo.date + ' ' + unitInfo.startAt)
      const nowDateTime = new Date()
      if (nowDateTime >= unitStartTime) {
        unitInfo.endAt = moment(unitInfo.date + ' ' + unitInfo.startAt)
          .add(1, 'h')
          .add(30, 'm')
          .format('HH:mm')
        unitInfo.learners = {}
        unitInfo.users = {}
        const learnersInfoRef = await db
          .collection('langxAccountInfoUsers')
          .where('userId', 'in', unitInfo.learnerIds)
          .get()
        await learnersInfoRef.forEach(oneLearner => {
          unitInfo.learners[oneLearner.id] = oneLearner.data()
          unitInfo.users[oneLearner.id] = oneLearner.data()
        })
        const tutorInfoRef = await db
          .collection('langxAccountInfoUsers')
          .doc(unitInfo.tutorId)
          .get()
        const tutorInfo = tutorInfoRef.data()
        unitInfo.tutor = tutorInfo
        unitInfo.users[tutorInfo.userId] = tutorInfo
        return unitInfo
      } else {
        return false
      }
    },
  },
}
</script>
<style scoped>
.pink-red-icon {
  color: #b80e65 !important;
}
.left-title {
  width: 50px;
}
.pre-wrap-text {
  white-space: pre-wrap;
}
</style>
