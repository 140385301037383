<template>
  <v-stepper v-model="currentStep" vertical flat height="100%">
    <v-stepper-step :complete="currentStep > 1" step="1">
      {{ $t('dashBoardStepImage') }}
    </v-stepper-step>
    <v-stepper-content step="1">
      <v-container>
        <v-row>
          <v-col cols="3" class="d-flex justify-center">
            <upload-profile-image @userPhotoURLIsUpdated="currentStep++" />
          </v-col>
        </v-row>
      </v-container>
    </v-stepper-content>

    <template v-if="userInfo.userType === 'Learner'">
      <v-stepper-step :complete="currentStep > 2" step="2">
        {{ $t('dashBoardStepSkillCheck') }}
      </v-stepper-step>
      <v-stepper-content step="2">
        <v-container>
          <v-row>
            <v-col cols="3" class="d-flex justify-center">
              <v-btn
                class="ma-2 px-5 grad-red white--text"
                depressed
                plain
                @click="openSkillCheckWindow()"
              >
                <v-card color="transparent" flat tile>
                  <div class="white--text">
                    Start Skill Check
                  </div>
                </v-card>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>
      <v-stepper-step :complete="currentStep > 3" step="3">
        {{ $t('dashBoardStepNextLesson') }}
      </v-stepper-step>
      <v-stepper-content step="3">
        <v-btn icon @click="checkRegisteredUnit">
          <v-icon>
            mdi-reload
          </v-icon>
        </v-btn>
        <v-slide-y-transition>
          <unit-card v-if="isRegisteredUnit" :unit="nextUnit" />
          <unit-empty v-if="isRegisteredUnit === false" />
        </v-slide-y-transition>
      </v-stepper-content>
    </template>

    <template v-else>
      <v-stepper-step :complete="currentStep > 2" step="2">
        {{ $t('dashBoardStepNextLesson') }}
      </v-stepper-step>
      <v-stepper-content step="2">
        <v-btn icon @click="checkRegisteredUnit">
          <v-icon>
            mdi-reload
          </v-icon>
        </v-btn>
        <v-slide-y-transition>
          <unit-card v-if="isRegisteredUnit" :unit="nextUnit" />
          <unit-empty v-if="isRegisteredUnit === false" />
        </v-slide-y-transition>
      </v-stepper-content>
    </template>
  </v-stepper>
</template>
<script>
import { db } from '@/plugins/firebase'
import UnitCard from '@/components/UnitCard.vue'
import UnitEmpty from '@/components/UnitEmpty.vue'
import UploadProfileImage from '@/components/UploadProfileImage.vue'
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Dashboard',
  components: { UploadProfileImage, UnitEmpty, UnitCard },
  data: () => {
    return {
      currentStep: 1,
      image: null,
      imgUrl: null,
      nextUnit: null,
      units: [],
      isRegisteredUnit: null,
      unsubscribeUserInfo: null,
    }
  },
  firestore() {
    return {}
  },
  computed: {
    ...mapGetters(['user', 'userInfo']),
  },
  mounted() {
    this.unsubscribeUserInfo = db
      .collection('langxAccountInfoUsers')
      .doc(this.user.uid)
      .onSnapshot(doc => {
        const userInfo = doc.data()
        this.setUserInfo(userInfo)
        if (!this.userInfo.photoURL) {
          this.currentStep = 1
        } else if (
          !this.userInfo.skillCheckedAt ||
          this.userInfo.userType === 'Tutor'
        ) {
          this.currentStep = 2
        } else {
          this.currentStep = 3
        }
        this.checkRegisteredUnit()
      })
  },
  beforeDestroy() {
    this.unsubscribeUserInfo()
  },
  methods: {
    ...mapActions(['setUserInfo']),
    openSkillCheckWindow() {
      const resolvedRoute = this.$router.resolve({
        name: 'SkillCheck',
      })
      const sw = screen.width
      const sh = screen.height
      window.open(
        resolvedRoute.href,
        'SkillCheck',
        'left=0, top=0, width=' + sw + ', height=' + sh + ''
      )
    },
    checkRegisteredUnit() {
      db.collection('langxAccountInfoUsers')
        .doc(this.user.uid)
        .get()
        .then(async userDoc => {
          // Unit所属確認
          const userData = userDoc.data()
          if (typeof userData.unitIds !== 'undefined') {
            this.nextUnit = await this.getNextUnit(userData.unitIds)
            if (this.nextUnit) {
              this.checkUnitCard()
              this.isRegisteredUnit = true
              const updateUnitCardTimer = setInterval(() => {
                if (this.nextUnit.isAllowedToJoinUnit) {
                  clearInterval(updateUnitCardTimer)
                }
                this.checkUnitCard()
              }, 1000)
            } else {
              this.isRegisteredUnit = false
            }
          } else {
            this.isRegisteredUnit = false
          }
        })
        .catch(() => {
          this.isRegisteredUnit = false
        })
    },
    checkUnitCard() {
      const unitPreparationTime = moment(
        this.nextUnit.date + ' ' + this.nextUnit.startAt
      ).add(-15, 'm')
      const nowDateTime = new Date()
      // Unit開始15分前よりミーティングに参加可能
      if (
        nowDateTime >= unitPreparationTime &&
        nowDateTime <= this.nextUnit.unitEndTime
      ) {
        this.$set(this.nextUnit, 'isAllowedToJoinUnit', true)
      } else {
        this.$set(this.nextUnit, 'isAllowedToJoinUnit', false)
      }
      // クラスレコードはチューターかつ開始時刻後にオンにする
      if (
        this.userInfo.userType === 'Tutor' &&
        nowDateTime >= unitPreparationTime
      ) {
        this.$set(this.nextUnit, 'isAllowedToClassRecord', true)
      } else {
        // 開始時刻前はオフ
        this.$set(this.nextUnit, 'isAllowedToClassRecord', false)
      }
      // Tutorのみ開始15分前からGroup Feedback をオン
      if (
        this.userInfo.userType === 'Tutor' &&
        nowDateTime >= unitPreparationTime
      ) {
        this.$set(this.nextUnit, 'isAllowedToGroupFeedback', true)
      } else {
        this.$set(this.nextUnit, 'isAllowedToGroupFeedback', false)
      }
    },
    async getNextUnit(unitIds) {
      const nowDateTime = new Date()
      for (const unitId of unitIds) {
        await db
          .collection('langxLearningContentUnits')
          .doc(unitId)
          .get()
          .then(unitDoc => {
            if (unitDoc.exists) {
              const unitData = unitDoc.data()
              const unitStartTime = moment(
                unitData.date + ' ' + unitData.startAt
              )
              const unitEndTime = moment(unitStartTime)
                // 現状一時間半と仮定。後々修正
                .add(1, 'h')
                .add(30, 'm')
              this.units.push({
                timeUntilUnit: Math.round((unitEndTime - nowDateTime) / 600000),
                ...unitData,
              })
            }
          })
      }
      // FIXME：現状超遅い。（昇順にソートして授業終了までの時間が0以上をfindする）要修正
      this.units.sort(function(a, b) {
        return a.timeUntilUnit - b.timeUntilUnit
      })
      const nextUnit = this.units.find(unit => unit.timeUntilUnit >= 0)
      nextUnit.unitStartTime = moment(nextUnit.date + ' ' + nextUnit.startAt)
      // FIXME:現状一時間半と仮定
      nextUnit.unitEndTime = moment(nextUnit.date + ' ' + nextUnit.startAt).add(
        90,
        'm'
      )
      // user情報追加
      const tutorRef = db
        .collection('langxAccountInfoUsers')
        .doc(nextUnit.tutorId)
      const tutorDoc = await tutorRef.get()
      nextUnit.tutorInfo = await tutorDoc.data()
      nextUnit.learnerInfo = []
      for (const learnerId of nextUnit.learnerIds) {
        const learnerRef = db.collection('langxAccountInfoUsers').doc(learnerId)
        const learnerDoc = await learnerRef.get()
        nextUnit.learnerInfo.push(learnerDoc.data())
      }
      return nextUnit
    },
  },
}
</script>
<style scoped>
.lesson-name {
  color: #ffffff;
  background: #df4f4f;
}
.button-join {
  color: #ffffff;
  background: linear-gradient(to right, #f36258, #b80e65);
}
</style>
