<template>
  <v-chip class="ma-2" label>
    {{ text }}
  </v-chip>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      require: true,
      default: null,
    },
  },
}
</script>
