<template>
  <v-container>
    <v-file-input
      accept="text/csv"
      label="CSV File input"
      @change="csvToJson"
    />
    <v-data-table
      :items="unitList"
      :headers="headers"
      class="elevation-1"
      :loading="loading"
    />
    <v-btn class="grad-red white--text" @click="addClass">
      クラス追加
    </v-btn>
    <v-btn class="grad-red white--text" @click="reserveUnitMeeting">
      Unitミーティング予約
    </v-btn>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ message }}

      <template #action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import firebase, { db } from '@/plugins/firebase'
import moment from 'moment'
import { createNewMeeting } from '@/plugins/langx-mms'
export default {
  name: 'UnitRegistration',
  data: () => {
    return {
      unitList: [],
      message: '',
      snackbar: '',
      timeout: 2000,
      loading: false,
      headers: [
        { text: 'クラスID', value: 'classId' },
        { text: 'マスターID', value: 'unitMasterId' },
        { text: '年度', value: 'year' },
        { text: 'クォーター', value: 'quater' },
        { text: 'レベル', value: 'level' },
        { text: 'テキスト', value: 'textSection' },
        { text: 'ユニット番号', value: 'unitNo' },
        { text: '日付', value: 'date' },
        { text: '開始時限 (限）', value: 'startAt' },
        { text: 'チューターID', value: 'tutorLangxId' },
        { text: 'ラーナーID', value: 'learnerLangxIds' },
      ],
    }
  },
  methods: {
    // 動的ソート関数
    dynamicSort(key, order = 'asc') {
      return function(a, b) {
        const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]
        const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]

        let comparison = 0
        if (varA > varB) {
          comparison = 1
        } else if (varA < varB) {
          comparison = -1
        }
        return order === 'desc' ? comparison * -1 : comparison
      }
    },
    // 連想配列から指定キーの重複削除、重複なしの値を出力
    deleteDuplicates(data, key) {
      const result = []
      data.filter((item, index, self) => {
        const nameList = self.map(item => item[key])
        if (nameList.indexOf(item[key]) === index) {
          return result.push(item[key])
        } else {
          return 0
        }
      })
      return result
    },

    addClassAndUnitToUser(classId, unitId, userId) {
      db.collection('langxAccountInfoUsers')
        .doc(userId)
        .update({
          classIds: firebase.firestore.FieldValue.arrayUnion(classId),
          unitIds: firebase.firestore.FieldValue.arrayUnion(unitId),
        })
    },

    async addClass() {
      this.loading = true
      const allUnitData = this.unitList.concat()

      allUnitData.sort(this.dynamicSort('classId'))

      const classIds = this.deleteDuplicates(allUnitData, 'classId')

      // 各クラスに処理
      for (const classId of classIds) {
        // すでにクラスが存在している場合はスキップ
        const classDocRef = db
          .collection('langxLearningContentClasses')
          .doc(classId)
        const classSnapshot = await classDocRef.get()
        if (classSnapshot.exists) {
          alert('すでに' + classId + 'クラスを登録しています')
          return
        }

        db.collection('langxLearningContentClasses')
          .doc(classId)
          .set({ exists: true })

        // 各クラスごとにUnitの処理
        for (const unit of allUnitData) {
          // allUnitDataの中から現在のクラスに属するUnitでforループ
          if (unit.classId === classId) {
            // unitId取得
            unit.unitId = db.collection('langxLearningContentUnits').doc().id

            const unitRef = db
              .collection('langxLearningContentUnits')
              .doc(unit.unitId)

            await unitRef.set(unit).then(
              unitRef.update({
                meetingId: null,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
              })
            )

            db.collection('langxIds')
              .doc(unit.tutorLangxId)
              .get()
              .then(result => {
                unitRef.update({ tutorId: result.data().userId })
                this.addClassAndUnitToUser(
                  unit.classId,
                  unit.unitId,
                  result.data().userId
                )
              })

            unit.learnerLangxIds.forEach(async learnerLangxId => {
              await db
                .collection('langxIds')
                .doc(learnerLangxId)
                .get()
                .then(result => {
                  unitRef.update({
                    learnerIds: firebase.firestore.FieldValue.arrayUnion(
                      result.data().userId
                    ),
                  })
                  this.addClassAndUnitToUser(
                    unit.classId,
                    unit.unitId,
                    result.data().userId
                  )
                })
            })

            // Unit情報を取得
            db.collection('langxLearningContentCourses')
              .doc(unit.unitMasterId)
              .get()
              .then(result => {
                unitRef.update(result.data())
              })

            // ClassにunitIdを登録
            db.collection('langxLearningContentClasses')
              .doc(unit.classId)
              .update({
                unitIds: firebase.firestore.FieldValue.arrayUnion(unit.unitId),
              })
          }
        }
      }
      this.loading = false
    },
    csvToJson(csvFile) {
      this.$papa.parse(csvFile, {
        header: true,
        complete: this.showUnitList,
      })
    },
    showUnitList(results) {
      const units = results.data
      // learnerを配列化（現状10列まで）
      const learnerLangxKeys = Object.keys(units[0]).filter(key =>
        key.match(/^learnerLangxId[0-9]/)
      )
      units.forEach(unit => {
        unit.learnerLangxIds = []
        for (let i = 0; i < learnerLangxKeys.length; i++) {
          unit.learnerLangxIds.push(unit[learnerLangxKeys[i]])
          delete unit[learnerLangxKeys[i]]
        }

        // unit情報取得用ID生成
        unit.unitMasterId =
          unit.level.toLowerCase() +
          `-` +
          unit.textSection +
          '-' +
          String(unit.unitNo).padStart(2, '0')
      })
      this.unitList = units
    },
    // moment型でDateを返す
    parseDate(unit) {
      const result = unit.date
        .split('/')
        .concat(unit.startAt.split(':'))
        .map(str => parseInt(str, 10))
      // monthは0始まりなのでずらす
      result[1] -= 1
      return moment(result).toDate()
    },

    async reserveUnitMeeting() {
      this.loading = true
      const unitsRef = db.collection('langxLearningContentUnits')
      const snapshot = await unitsRef.where('meetingId', '==', null).get()
      if (snapshot.empty) {
        this.snackbar = true
        this.message = 'すでにすべてのUnitのミーティングを予約しています'
        return
      }
      let meeting = null
      snapshot.forEach(doc => {
        const unit = doc.data()
        meeting = {
          meetingName: unit.unitTitle,
          meetingType: 'scheduled',
          startAt: this.parseDate(unit),
          recordingLength: 90,
          additionalTime: 15,
          unitId: unit.unitId,
        }
        createNewMeeting(meeting).then(result => {
          db.collection('langxLearningContentUnits')
            .doc(unit.unitId)
            .update({ meetingId: result.id })
        })
      })
      this.loading = false
    },
  },
}
</script>
