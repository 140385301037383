<template>
  <v-app>
    <v-container class="bg fill-height" fluid>
      <transition mode="out-in">
        <component :is="CurrentView" />
      </transition>
    </v-container>
  </v-app>
</template>

<script>
// import firebase from 'firebase/app'
import { mapState, mapActions } from 'vuex'
import Entry from '@/components/Entry.vue'
import SignIn from '@/components/SignIn.vue'
// import SignUp from '@/components/SignUp.vue'
import UserRegistration from '@/components/UserRegistration.vue'

export default {
  name: 'Top',
  components: {
    Entry,
    SignIn,
    // SignUp,
    UserRegistration,
  },
  data() {
    return {
      email: '',
      password: '',
    }
  },
  computed: {
    ...mapState(['view']),
    CurrentView: {
      get() {
        return this.view
      },
      set(value) {
        this.changeCurrentView(value)
      },
    },
  },
  methods: {
    ...mapActions(['changeCurrentView']),
  },
}
</script>

<style scoped>
.bg {
  height: 100vh; /* 全画面表示 */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url('@/static/bg.png');
}
</style>
