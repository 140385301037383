<template>
  <v-app>
    <transition mode="out-in">
      <router-view />
    </transition>
  </v-app>
</template>

<script>
import firebase from 'firebase'
import { mapActions } from 'vuex'
export default {
  name: 'App',
  components: {},
  created() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setLoginUser(user)
      } else {
        this.deleteLoginUser()
      }
    })
  },
  mounted: function() {
    const routeInstance = this.$route
    this.createTitleDesc(routeInstance)
  },
  methods: {
    ...mapActions(['setLoginUser', 'logout', 'deleteLoginUser']),
    createTitleDesc: function(routeInstance) {
      // タイトルを設定
      if (routeInstance.meta.title) {
        const setTitle = routeInstance.meta.title + ' | LANGX'
        document.title = setTitle
      } else {
        document.title = 'LANGX'
      }

      // メタタグdescription設定
      if (routeInstance.meta.desc) {
        const setDesc = routeInstance.meta.desc + ' | LANGX'
        document
          .querySelector("meta[name='description']")
          .setAttribute('content', setDesc)
      } else {
        document
          .querySelector("meta[name='description']")
          .setAttribute('content', 'description is not set')
      }
    },
  },
}
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}
.grad-red {
  background: linear-gradient(45deg, #f36258, #b80e65);
}
.grad-blue {
  background: linear-gradient(45deg, #8125f5, #1791b1);
}

.bg-gray {
  background-color: #312f30;
}

#app {
  font-family: 'Helvetica Neue', 'Helvetica', 'Hiragino Sans',
    'Hiragino Kaku Gothic ProN', 'Arial', 'Yu Gothic', 'Meiryo', sans-serif;
}

.name--tag {
  position: absolute;
  margin: 0;
  bottom: 0;
  padding: 5px 10px 5px;
  left: 0;
}

.like--btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  font-size: 20px;
  position: absolute;
  margin: 5px;
  bottom: 0;
  right: 0;
  opacity: 0.5;
}

.like--btn:hover {
  border: 0.3px #312f30;
  opacity: 1;
}

.liked {
  animation: liked 2s ease 0s 1 normal forwards;
}

@keyframes liked {
  0% {
    opacity: 1;
    background: linear-gradient(to right, #f36258, #b80e65);
  }
  75% {
    opacity: 1;
    background: linear-gradient(to right, #f36258, #b80e65);
  }
  100% {
    opacity: 0.5;
    background-color: #312f30;
  }
}

.falling--like {
  animation: falling--like 2s ease-out 0s 1 normal forwards;
  position: absolute;
  font-size: 40px;
  top: 100%;
  margin: 0;
  padding: 0;
  opacity: 0.7;
}

@keyframes falling--like {
  0% {
    top: 100%;
  }
  100% {
    top: -50px;
  }
}

.raised--hand {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 30px;
  position: absolute;
  top: 0;
  margin: 5px;
  border-radius: 5px;
}

.test--row {
  height: 200px;
}

.sound--bar {
  width: 10px;
  height: 30px;
  border-radius: 2px;
  background-color: #312f30;
}

.sound--bar--light {
  background-color: #f9f9f9;
  width: 8px;
  height: 30px;
  border-radius: 2px;
}
.v-progress-circular__underlay {
  opacity: 0 !important;
  display: none !important;
}

.user-photo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100px;
  height: 100px;
  z-index: 100;
}
</style>
