<template>
  <v-app>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-row align="center" class="px-9">
            <v-divider />
            <v-subheader>
              {{ $t('groupFeedback') }}
            </v-subheader>
            <v-divider />
          </v-row>
          <template v-if="pageLoading">
            <v-overlay :value="pageLoading">
              <v-progress-circular
                indeterminate
                color="#1791b1"
                size="100"
                width="10"
              />
            </v-overlay>
          </template>
          <template v-else-if="userInfo.userType === 'Tutor' && creatorMode">
            <group-feedback-info
              :unit="currentDisplayingGroupFeedbackUnit"
              :tutor="currentDisplayingGroupFeedbackUnit.tutor"
              :learners="currentDisplayingGroupFeedbackUnit.learners"
            />
            <v-row align="center" class="px-9">
              <v-divider />
              <v-subheader>
                {{ $t('sendGroupFeedback') }}
              </v-subheader>
              <v-divider />
            </v-row>
            <group-feedback-form
              :unit="currentDisplayingGroupFeedbackUnit"
              @submitGroupFeedback="submittedGroupFeedback"
            />
          </template>
          <template v-else>
            <group-feedback-info
              :unit="currentDisplayingGroupFeedbackUnit"
              :tutor="currentDisplayingGroupFeedbackUnit.tutor"
              :learners="currentDisplayingGroupFeedbackUnit.learners"
            />
            <v-card flat tile outlined class="mt-5 pa-9">
              <v-row align="center" class="pa-4">
                <span class="pre-wrap-text text-body-1">
                  {{
                    currentDisplayingGroupFeedback.content.replaceAll(
                      '\\n',
                      '\n'
                    )
                  }}
                </span>
              </v-row>
            </v-card>
            <v-row
              v-if="currentDisplayingComments.length > 0"
              align="center"
              class="py-4"
            >
              <v-divider />
              <v-subheader>
                {{ $t('comment') }}
              </v-subheader>
              <v-divider />
            </v-row>
            <template v-for="oneComment in currentDisplayingComments">
              <comment-card
                :key="oneComment.createdAt"
                :comment="oneComment"
                :sender="oneComment.sender"
              />
            </template>
            <v-row align="center" class="py-4">
              <v-divider />
              <v-subheader>
                {{ $t('sendComment') }}
              </v-subheader>
              <v-divider />
            </v-row>
            <comment-form
              :group-feedback="currentDisplayingGroupFeedback"
              @sentComment="sentComment"
            />
          </template>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="messageSnackbar" timeout="3000">
      {{ snackbarMessage }}
      <template #action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="messageSnackbar = false"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import { db } from '@/plugins/firebase'
import moment from 'moment'
import GroupFeedbackForm from '@/components/GroupFeedbackForm.vue'
import GroupFeedbackInfo from '@/components/GroupFeedbackInfo.vue'
import CommentForm from '@/components/CommentForm.vue'
import CommentCard from '@/components/CommentCard.vue'

export default {
  name: 'GroupFeedbackViewer',
  components: {
    GroupFeedbackForm,
    GroupFeedbackInfo,
    CommentForm,
    CommentCard,
  },
  data: () => {
    return {
      unitId: '',
      currentDisplayingGroupFeedbackUnit: {},
      currentDisplayingGroupFeedback: {},
      currentDisplayingComments: [],
      unsubscribeGroupFeedback: null,
      creatorMode: true,
      pageLoading: true,
      snackbarMessage: '',
      messageSnackbar: false,
    }
  },
  computed: {
    ...mapGetters(['user', 'userInfo']),
  },
  async created() {
    this.unitId = this.$route.params.id
    this.currentDisplayingGroupFeedbackUnit = await this.getUnitWithUsers(
      this.unitId
    )
    await db
      .collection('langxLearningContentGroupFeedbacks')
      .doc(this.unitId)
      .get()
      .then(async doc => {
        if (doc.exists) {
          const groupFeedback = doc.data()
          groupFeedback.unit = this.currentDisplayingGroupFeedbackUnit
          this.showGroupFeedback(groupFeedback)
          this.pageLoading = false
          this.creatorMode = false
        } else {
          this.pageLoading = false
        }
      })
  },
  mounted() {},
  beforeDestroy() {
    if (this.unsubscribeGroupFeedback) {
      this.unsubscribeGroupFeedback()
    }
  },
  methods: {
    submittedGroupFeedback(groupFeedback) {
      this.snackbarMessage = this.$t('groupFeedbackSuccessMessage')
      this.messageSnackbar = true
      db.collection('langxLearningContentUnits')
        .doc(groupFeedback.unitId)
        .set({ isGroupFeedbacked: true }, { merge: true })
        .then(() => {
          groupFeedback.unit = this.currentDisplayingGroupFeedbackUnit
          this.creatorMode = false
          this.showGroupFeedback(groupFeedback)
        })
    },
    async showGroupFeedback(groupFeedback) {
      this.currentDisplayingComments = []
      this.unsubscribeGroupFeedback = db
        .collection('langxLearningContentGroupFeedbacks')
        .doc(groupFeedback.unitId)
        .onSnapshot(async doc => {
          const updatedGroupFeedback = doc.data()
          if (updatedGroupFeedback.comments.length > 0) {
            await updatedGroupFeedback.comments.forEach((oneComment, index) => {
              updatedGroupFeedback.comments[index].sender =
                groupFeedback.unit.users[oneComment.userId]
            })
            this.currentDisplayingComments = updatedGroupFeedback.comments
          }
        })
      this.currentDisplayingGroupFeedback = groupFeedback
    },
    sentComment() {
      this.snackbarMessage = this.$t('commentSuccessMessage')
      this.messageSnackbar = true
    },
    async getUnitWithUsers(unitId) {
      const unitInfoDoc = await db
        .collection('langxLearningContentUnits')
        .doc(unitId)
        .get()
      const unitInfo = unitInfoDoc.data()
      unitInfo.endAt = moment(unitInfo.date + ' ' + unitInfo.startAt)
        .add(1, 'h')
        .add(30, 'm')
        .format('HH:mm')
      unitInfo.learners = {}
      unitInfo.users = {}
      const learnersInfoRef = await db
        .collection('langxAccountInfoUsers')
        .where('userId', 'in', unitInfo.learnerIds)
        .get()
      await learnersInfoRef.forEach(oneLearner => {
        unitInfo.learners[oneLearner.id] = oneLearner.data()
        unitInfo.users[oneLearner.id] = oneLearner.data()
      })
      const tutorInfoRef = await db
        .collection('langxAccountInfoUsers')
        .doc(unitInfo.tutorId)
        .get()
      const tutorInfo = tutorInfoRef.data()
      unitInfo.tutor = tutorInfo
      unitInfo.users[tutorInfo.userId] = tutorInfo
      return unitInfo
    },
  },
}
</script>

<style scoped>
.pink-red-icon {
  color: #b80e65 !important;
}
.left-title {
  width: 50px;
}
.pre-wrap-text {
  white-space: pre-wrap;
}
</style>
