<template color="#f9f9f9">
  <v-card
    color="transparent"
    flat
    tile
    width="100%"
    class="d-flex justify-space-around align-content-space-between flex-wrap"
  >
    <v-card v-show="!isOpenedDataWindow" width="300px" class="ma-2">
      <v-card-title class="grad-blue white--text pa-3 rounded-t">
        {{ $t('skillCheck') }}
      </v-card-title>
      <v-card-text class="pa-2">
        <v-list>
          <v-list-item two-line>
            <v-list-item-avatar>
              <v-icon class="grey lighten-1 white--text">
                mdi-file-delimited
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Can-Do Responses</v-list-item-title>
              <v-list-item-subtitle>Format: CSV</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-avatar>
              <v-icon class="grey lighten-1 white--text">
                mdi-file-music
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Read Aloud Responses</v-list-item-title>
              <v-list-item-subtitle>Format: WAV</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider />
      <v-card-actions class="pa-2">
        <v-btn color="deep-purple lighten-2" text @click="openSkillCheckWindow">
          {{ $t('open') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-show="!isOpenedDataWindow" width="300px" class="ma-2">
      <v-card-title class="grad-blue white--text pa-3 rounded-t">
        {{ $t('meetingRecords') }}
      </v-card-title>
      <v-card-text class="pa-2">
        <v-list>
          <v-list-item two-line>
            <v-list-item-avatar>
              <v-icon class="grey lighten-1 white--text">
                mdi-file-video
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Lesson Videos</v-list-item-title>
              <v-list-item-subtitle>Format: MP4</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-avatar>
              <v-icon class="grey lighten-1 white--text">
                mdi-file-music
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Lesson Sounds</v-list-item-title>
              <v-list-item-subtitle>Format: AAC</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider />
      <v-card-actions class="pa-2">
        <v-btn
          color="deep-purple lighten-2"
          text
          @click="openMeetingRecordsWindow"
        >
          {{ $t('open') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-show="!isOpenedDataWindow" width="300px" class="ma-2">
      <v-card-title class="grad-blue white--text pa-3 rounded-t">
        {{ $t('groupFeedback') }}
      </v-card-title>
      <v-card-text class="pa-2">
        <v-list>
          <v-list-item two-line>
            <v-list-item-avatar>
              <v-icon class="grey lighten-1 white--text">
                mdi-file-document
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Group Feedbacks</v-list-item-title>
              <v-list-item-subtitle>Format: TXT</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-avatar>
              <v-icon class="grey lighten-1 white--text">
                mdi-file-document
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Comments</v-list-item-title>
              <v-list-item-subtitle>Format: TXT</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider />
      <v-card-actions class="pa-2">
        <v-btn
          color="deep-purple lighten-2"
          text
          @click="openGroupFeedbackWindow"
        >
          {{ $t('open') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-show="!isOpenedDataWindow" width="300px" class="ma-2">
      <v-card-title class="grad-blue white--text pa-3 rounded-t">
        {{ $t('classRecord') }}
      </v-card-title>
      <v-card-text class="pa-2">
        <v-list>
          <v-list-item two-line>
            <v-list-item-avatar>
              <v-icon class="grey lighten-1 white--text">
                mdi-file-delimited
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Class Records</v-list-item-title>
              <v-list-item-subtitle>Format: CSV</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-avatar>
              <v-icon class="grey lighten-1 white--text">
                mdi-file-delimited
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Class Assessments</v-list-item-title>
              <v-list-item-subtitle>Format: CSV</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider />
      <v-card-actions class="pa-2">
        <v-btn
          color="deep-purple lighten-2"
          text
          @click="openClassRecordsWindow"
        >
          {{ $t('open') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card
      v-show="isOpenedSkillCheckWindow"
      width="100%"
      color="transparent"
      tile
      flat
      class="pa-2 ma-2"
    >
      <v-container>
        <v-row>
          <v-btn
            class="grad-blue white--text pa-2 ma-2"
            @click="closeSkillCheckWindow()"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
            {{ $t('back') }}
          </v-btn>
        </v-row>
        <v-row align="center">
          <v-divider />
          <v-subheader>
            {{ $t('skillCheck') }}
          </v-subheader>
          <v-divider />
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card-title>
              Learners
              <v-spacer />
              <v-text-field
                v-model="skillCheckSearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-card-title>
            <v-data-table
              dense
              :items="learners"
              :headers="skillCheckHeaders"
              :items-per-page="5"
              :search="skillCheckSearch"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
              }"
            >
              <template #[`item.photoURL`]="{ item }">
                <v-avatar class="ma-2">
                  <img :src="item.photoURL" />
                </v-avatar>
              </template>
              <template #[`item.firstName`]="{ item }">
                {{ item.firstName + ' ' + item.lastName }}
              </template>
              <template #[`item.classIds`]="{ item }">
                <v-chip
                  v-for="oneClass in item.classIds"
                  :key="oneClass"
                  color="deep-purple lighten-2"
                  class="ma-1 text-caption"
                  outlined
                  pill
                >
                  {{ oneClass }}
                </v-chip>
              </template>
              <template #[`item.downloadURL`]="{ item }">
                <v-btn
                  v-if="item.downloadURL"
                  class="blue white--text"
                  :href="item.downloadURL"
                >
                  <v-icon class="mr-2">
                    mdi-zip-box-outline
                  </v-icon>
                  DOWNLOAD ZIP
                </v-btn>
                <v-btn
                  v-else-if="item.skillCheckedAt"
                  class="orange white--text"
                  :loading="item.isProcessing"
                  :disabled="item.isProcessing"
                  @click="zipSkillCheckDirectory(item)"
                >
                  <v-icon class="mr-2">
                    mdi-folder-zip
                  </v-icon>
                  CREATE ZIP
                </v-btn>
                <v-btn v-else class="grey white--text" disabled>
                  NO DATA
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card
      v-show="isOpenedMeetingRecordsWindow"
      width="100%"
      color="transparent"
      tile
      flat
      class="pa-2 ma-2"
    >
      <v-container>
        <v-row>
          <v-btn
            class="grad-blue white--text pa-2 ma-2"
            @click="closeMeetingRecordsWindow()"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
            {{ $t('back') }}
          </v-btn>
        </v-row>
        <v-row align="center">
          <v-divider />
          <v-subheader>
            {{ $t('meetingRecords') }}
          </v-subheader>
          <v-divider />
        </v-row>
        <v-row>
          <v-select
            v-model="selectedClassIds"
            :items="meetingRecords"
            item-text="id"
            chips
            multiple
            :label="$t('selectClass')"
            @change="refleshSelectedMeetingRecords"
          />
        </v-row>
        <v-row>
          <v-list width="100%">
            <v-list-group
              v-for="record in selectedMeetingRecords"
              :key="record.id"
              v-model="record.active"
            >
              <template #activator>
                <v-list-item-content>
                  <v-list-item-title v-text="record.id" />
                </v-list-item-content>
              </template>
              <v-list-group
                v-for="endedUnit in record.endedUnits"
                :key="endedUnit.unitId"
                v-model="endedUnit.active"
                sub-group
              >
                <template #activator>
                  <v-list-item-content>
                    <v-list-item-title>
                      Unit {{ endedUnit.unitNo }}, {{ endedUnit.unitTitle }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item
                  v-for="recordedFile in endedUnit.recordedFiles"
                  :key="recordedFile.fileName"
                >
                  <v-list-item-title>
                    <v-btn
                      text
                      :href="recordedFile.downloadURL"
                      download
                      target="_blank"
                    >
                      {{
                        `${recordedFile.userType}: ${recordedFile.firstName} ${recordedFile.lastName} (${recordedFile.nickName}), file: ${recordedFile.fileName}`
                      }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list-group>
            </v-list-group>
          </v-list>
        </v-row>
      </v-container>
    </v-card>

    <v-card
      v-show="isOpenedClassRecordsWindow"
      width="100%"
      color="transparent"
      tile
      flat
      class="pa-2 ma-2"
    >
      <v-container>
        <v-row>
          <v-btn
            class="grad-blue white--text pa-2 ma-2"
            @click="closeClassRecordsWindow()"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
            {{ $t('back') }}
          </v-btn>
        </v-row>
        <v-row align="center">
          <v-divider />
          <v-subheader>
            {{ $t('classRecord') }}
          </v-subheader>
          <v-divider />
        </v-row>
        <v-row>
          <v-select
            v-model="selectedClassIds"
            :items="classRecords"
            item-text="id"
            chips
            multiple
            :label="$t('selectClass')"
            @change="refleshSelectedClassRecords"
          />
        </v-row>
        <v-row />
      </v-container>
    </v-card>

    <v-card
      v-show="isOpenedGroupFeedbacksWindow"
      width="100%"
      color="transparent"
      tile
      flat
      class="pa-2 ma-2"
    >
      <v-container>
        <v-row>
          <v-btn
            class="grad-blue white--text pa-2 ma-2"
            @click="closeGroupFeedbacksWindow()"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
            {{ $t('back') }}
          </v-btn>
        </v-row>
        <v-row align="center">
          <v-divider />
          <v-subheader>
            {{ $t('groupFeedback') }}
          </v-subheader>
          <v-divider />
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card-title>
              {{ $t('class') }}
              <v-spacer />
              <v-text-field
                v-model="groupFeedbackSearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-card-title>
            <v-data-table
              dense
              :items="classes"
              :headers="groupFeedbackHeaders"
              :items-per-page="10"
              :search="groupFeedbackSearch"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
              }"
            >
              <template #[`item.tutors`]="{ item }">
                <v-chip
                  v-for="(tutor, tutorIndex) in item.tutors"
                  :key="tutorIndex"
                  pill
                  color="deep-purple"
                  outlined
                  class="ma-2"
                >
                  <v-avatar left>
                    <v-img :src="tutor.photoURL" />
                  </v-avatar>
                  {{ tutor.firstName + ' ' + tutor.lastName }}
                </v-chip>
              </template>
              <template #[`item.downloadURL`]="{ item }">
                <v-btn
                  v-if="item.downloadURL"
                  class="blue white--text"
                  :href="item.downloadURL"
                >
                  <v-icon class="mr-2">
                    mdi-zip-box-outline
                  </v-icon>
                  DOWNLOAD ZIP
                </v-btn>
                <v-btn
                  v-else-if="item.isGroupFeedbacked"
                  class="orange white--text"
                  :loading="item.isProcessing"
                  :disabled="item.isProcessing"
                  @click="zipGroupFeedbackDirectory(item)"
                >
                  <v-icon class="mr-2">
                    mdi-folder-zip
                  </v-icon>
                  CREATE ZIP
                </v-btn>
                <v-btn v-else class="grey white--text" disabled>
                  NO DATA
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-card>
</template>

<script>
import { database, db, storage } from '@/plugins/firebase'
import { zipDirectory } from '@/plugins/file-zipper'
import moment from 'moment'
export default {
  name: 'DataWareHouse',
  data: () => {
    return {
      isOpenedDataWindow: false,
      isOpenedSkillCheckWindow: false,
      isOpenedMeetingRecordsWindow: false,
      isOpenedClassRecordsWindow: false,
      isOpenedGroupFeedbacksWindow: false,
      selectedClassIds: [],
      meetingRecords: [],
      selectedMeetingRecords: [],
      classRecords: [],
      selectedClassRecords: [],
      learners: [],
      skillCheckHeaders: [
        { text: 'LANGX ID', value: 'langxId', align: 'start' },
        { text: 'Photo', value: 'photoURL' },
        { text: 'Learner', value: 'nickName' },
        { text: 'Class', value: 'classIds' },
        { text: 'File Status', value: 'fileStatus' },
        { text: 'Bulk Download', value: 'downloadURL', sortable: false },
      ],
      skillCheckSearch: '',
      archivingSkillChecksRef: {},
      groupFeedbackHeaders: [
        { text: 'Class', value: 'classId', align: 'start' },
        { text: 'Tutors', value: 'tutors', align: 'start' },
        { text: 'N', value: 'groupFeedbacksLength' },
        { text: 'File Status', value: 'fileStatus' },
        { text: 'Bulk Download', value: 'downloadURL', sortable: false },
      ],
      groupFeedbackSearch: '',
      archivingGroupFeedbacksRef: {},
      classes: [],
    }
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    async openSkillCheckWindow() {
      this.isOpenedDataWindow = true
      this.isOpenedSkillCheckWindow = true
      await this.loadLearners()
    },
    async loadLearners() {
      this.learners = []
      const learnersRef = db.collection('langxAccountInfoUsers')
      const zippedDatabaseRef = database.ref('archivedFiles/skillCheckRecords/')
      const zippedDatabaseRecords = await zippedDatabaseRef.once('value')
      const zippedDatabaseRecordsValue = zippedDatabaseRecords.val()
      const zippedStorageRef = storage.child('archivedFiles/skillCheckRecords')
      const learnersQuerySnapshot = await learnersRef
        .where('userType', '==', 'Learner')
        .get()
      await learnersQuerySnapshot.forEach(async doc => {
        const learner = doc.data()
        // 今回の実験データのみロードする
        if (
          learner.classIds &&
          learner.classIds[0].indexOf('2021-summer') !== -1
        ) {
          // 最新のZIPファイルがあるとき
          if (
            learner.skillCheckedAt &&
            zippedDatabaseRecordsValue &&
            Object.hasOwnProperty.call(
              zippedDatabaseRecordsValue,
              learner.langxId
            ) &&
            learner.skillCheckedAt <
              zippedDatabaseRecordsValue[learner.langxId].createdAt
          ) {
            if (
              zippedDatabaseRecordsValue[learner.langxId].fileStatus === 'done'
            ) {
              learner.downloadURL = await zippedStorageRef
                .child(`${learner.langxId}.zip`)
                .getDownloadURL()
              learner.fileStatus = 'Done'
            } else {
              learner.isProcessing = true
              learner.fileStatus = 'Processing'
            }
          } else if (learner.skillCheckedAt) {
            learner.fileStatus = 'No zip files'
          } else {
            learner.fileStatus = 'No data'
          }
          await this.learners.push(learner)
        }
      })
      await this.learners.sort((a, b) => {
        const nameA = a.classIds[0].toUpperCase()
        const nameB = b.classIds[0].toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
    },
    zipSkillCheckDirectory(learner) {
      const index = this.learners.indexOf(learner)
      const langxId = learner.langxId
      this.$set(this.learners[index], 'isProcessing', true)
      this.$set(this.learners[index], 'fileStatus', 'Processing')
      zipDirectory({
        inputDirectory: `skillCheckRecords/${langxId}`,
        outputDirectory: `skillCheckRecords`,
        outputFileName: langxId,
      })
      this.archivingSkillChecksRef[langxId] = database.ref(
        `archivedFiles/skillCheckRecords/${langxId}`
      )
      this.archivingSkillChecksRef[langxId].on('value', async snapshot => {
        const processingLog = snapshot.val()
        if (processingLog && processingLog.fileStatus === 'done') {
          const zippedStorageRef = storage.child(
            `archivedFiles/skillCheckRecords/${langxId}.zip`
          )
          const downloadURL = await zippedStorageRef.getDownloadURL()
          this.$set(this.learners[index], 'processing', null)
          this.$set(this.learners[index], 'downloadURL', downloadURL)
          this.$set(this.learners[index], 'fileStatus', 'Done')
          this.archivingSkillChecksRef[langxId].off()
        }
      })
    },
    async openGroupFeedbackWindow() {
      this.classes = []
      const classesRef = db.collection('langxLearningContentClasses')
      const groupFeedbacksRef = db.collection(
        'langxLearningContentGroupFeedbacks'
      )
      const unitsRef = db.collection('langxLearningContentUnits')
      const usersRef = db.collection('langxAccountInfoUsers')
      const zippedStorageRef = storage.child(
        'archivedFiles/groupFeedbackRecords'
      )
      const zippedDatabaseRef = database.ref(
        'archivedFiles/groupFeedbackRecords'
      )
      const zippedDatabaseRecords = await zippedDatabaseRef.once('value')
      const zippedDatabaseRecordsValue = zippedDatabaseRecords.val()
      const classesQuerySnapshot = await classesRef.get()
      await classesQuerySnapshot.forEach(async doc => {
        const oneClass = doc.data()
        oneClass.classId = doc.id
        // 今回の実験データのみロードする
        if (oneClass.classId.indexOf('2021-summer') !== -1) {
          const tutorsQuerySnapshot = await usersRef
            .where('userType', '==', 'Tutor')
            .where('classIds', 'array-contains', oneClass.classId)
            .get()
          const tutors = tutorsQuerySnapshot.docs.map(doc => doc.data())
          const tutorIds = tutorsQuerySnapshot.docs.map(doc => doc.id)
          const groupFeedbacksQuerySnapshot = await groupFeedbacksRef
            .where('tutorId', 'in', tutorIds)
            .get()
          const unitsQuerySnapshot = await unitsRef
            .where('classId', '==', oneClass.classId)
            .where('tutorId', 'in', tutorIds)
            .get()
          const unitIds = unitsQuerySnapshot.docs.map(doc => doc.id)
          const groupFeedbacks = await groupFeedbacksQuerySnapshot.docs.map(
            doc => doc.data()
          )
          const filteredGroupFeedbacks = await groupFeedbacks.filter(data =>
            unitIds.includes(data.unitId)
          )
          const groupFeedbackedAtList = await filteredGroupFeedbacks.map(
            item => item.createdAt
          )

          const latestGroupFeedbackedAt = await Math.max(
            ...groupFeedbackedAtList
          )
          if (
            zippedDatabaseRecordsValue &&
            Object.hasOwnProperty.call(
              zippedDatabaseRecordsValue,
              oneClass.classId
            ) &&
            latestGroupFeedbackedAt <
              zippedDatabaseRecordsValue[oneClass.classId].createdAt
          ) {
            if (
              zippedDatabaseRecordsValue[oneClass.classId].fileStatus === 'done'
            ) {
              oneClass.downloadURL = await zippedStorageRef
                .child(`${oneClass.classId}.zip`)
                .getDownloadURL()
              oneClass.fileStatus = 'Done'
            } else {
              oneClass.isProcessing = true
              oneClass.fileStatus = 'Processing'
            }
          } else if (filteredGroupFeedbacks.length > 0) {
            oneClass.isGroupFeedbacked = true
            oneClass.fileStatus = 'No zip files'
          } else {
            oneClass.fileStatus = 'No data'
          }
          oneClass.tutors = tutors
          oneClass.groupFeedbacks = filteredGroupFeedbacks
          oneClass.groupFeedbacksLength = filteredGroupFeedbacks.length
          this.classes.push(oneClass)
        }
      })
      this.isOpenedGroupFeedbacksWindow = true
      this.isOpenedDataWindow = true
    },
    zipGroupFeedbackDirectory(oneClass) {
      const index = this.classes.indexOf(oneClass)
      const classId = oneClass.classId
      this.$set(this.classes[index], 'isProcessing', true)
      this.$set(this.classes[index], 'fileStatus', 'Processing')
      zipDirectory({
        inputDirectory: `groupFeedbackRecords/${classId}`,
        outputDirectory: `groupFeedbackRecords`,
        outputFileName: classId,
      })
      this.archivingGroupFeedbacksRef[classId] = database.ref(
        `archivedFiles/groupFeedbackRecords/${classId}`
      )
      this.archivingGroupFeedbacksRef[classId].on('value', async snapshot => {
        const processingLog = snapshot.val()
        if (processingLog && processingLog.fileStatus === 'done') {
          const zippedStorageRef = storage.child(
            `archivedFiles/groupFeedbackRecords/${classId}.zip`
          )
          const downloadURL = await zippedStorageRef.getDownloadURL()
          this.$set(this.classes[index], 'processing', null)
          this.$set(this.classes[index], 'fileStatus', 'Done')
          this.$set(this.classes[index], 'downloadURL', downloadURL)
          this.archivingGroupFeedbacksRef[classId].off()
        }
      })
    },
    async openClassRecordsWindow() {
      this.classRecords = []
      this.selectedClassIds = []
      this.selectedClassRecords = []
      const allClassesRef = db.collection('langxLearningContentClasses')
      const allClassesQuerySnapshot = await allClassesRef.get()
      await allClassesQuerySnapshot.forEach(async doc => {
        // 今回の実験データのみロードする
        if (doc.id.indexOf('2021-summer') !== -1) {
          const classRecord = { id: doc.id, endedUnits: [], ...doc.data() }
          await classRecord.unitIds.forEach(async oneUnitId => {
            const oneUnitRef = db
              .collection('langxLearningContentUnits')
              .doc(oneUnitId)
            const oneUnit = await oneUnitRef.get()
            const nowDateTime = new Date()
            const unitEndTime = moment(
              oneUnit.data().date + ' ' + oneUnit.data().startAt
            )
              .add(1, 'h')
              .add(30, 'm')
            if (nowDateTime >= unitEndTime) {
              const endedUnitData = oneUnit.data()
              endedUnitData.classRecords = []
              const classRecordsRef = db.collection(
                'langxLearningContentClassRecords'
              )
              const classRecordsQuerySnapshot = await classRecordsRef
                .where('unitId', '==', oneUnit.id)
                .get()
              classRecordsQuerySnapshot.forEach(async doc => {
                await endedUnitData.classRecords.push({
                  id: doc.id,
                  ...doc.data(),
                })
              })
              classRecord.endedUnits.push(endedUnitData)
            }
          })
          await this.classRecords.push(classRecord)
        }
      })
      this.isOpenedClassRecordsWindow = true
      this.isOpenedDataWindow = true
    },
    async openMeetingRecordsWindow() {
      this.meetingRecords = []
      this.selectedClassIds = []
      this.selectedMeetingRecords = []
      const allClassesRef = db.collection('langxLearningContentClasses')
      const allClassesQuerySnapshot = await allClassesRef.get()
      await allClassesQuerySnapshot.forEach(async doc => {
        // 今回の実験データのみロードする
        if (doc.id.indexOf('2021-summer') !== -1) {
          const meetingRecord = { id: doc.id, endedUnits: [], ...doc.data() }
          await meetingRecord.unitIds.forEach(async oneUnitId => {
            const oneUnitRef = db
              .collection('langxLearningContentUnits')
              .doc(oneUnitId)
            const oneUnit = await oneUnitRef.get()
            const nowDateTime = new Date()
            const unitEndTime = moment(
              oneUnit.data().date + ' ' + oneUnit.data().startAt
            )
              .add(1, 'h')
              .add(30, 'm')
            if (nowDateTime >= unitEndTime) {
              const endedUnitData = oneUnit.data()
              endedUnitData.recordedFiles = []
              const meetingParticipantsRef = database.ref(
                `/realTimeInteractions/${endedUnitData.meetingId}/meetingParticipants`
              )
              const meetingParticipants = await meetingParticipantsRef.once(
                'value'
              )
              const participantsData = meetingParticipants.val()
              const meetingVideoRecordsRef = storage.child(
                `meetingVideoRecords/${endedUnitData.meetingId}/`
              )
              const meetingVideoRecordFiles = await meetingVideoRecordsRef.listAll()
              await meetingVideoRecordFiles.items.forEach(async itemRef => {
                const fileName = itemRef.name
                const downloadURL = await itemRef.getDownloadURL()
                const participant = participantsData[fileName.split('_')[0]]
                endedUnitData.recordedFiles.push({
                  fileName: fileName,
                  firstName: participant.firstName,
                  lastName: participant.lastName,
                  nickName: participant.nickName,
                  userType: participant.userType,
                  downloadURL: downloadURL,
                })
              })
              meetingRecord.endedUnits.push(endedUnitData)
            }
          })
          await this.meetingRecords.push(meetingRecord)
        }
      })
      this.isOpenedDataWindow = true
      this.isOpenedMeetingRecordsWindow = true
    },
    closeSkillCheckWindow() {
      this.isOpenedSkillCheckWindow = false
      this.isOpenedDataWindow = false
    },
    closeMeetingRecordsWindow() {
      this.isOpenedMeetingRecordsWindow = false
      this.isOpenedDataWindow = false
    },
    closeClassRecordsWindow() {
      this.isOpenedClassRecordsWindow = false
      this.isOpenedDataWindow = false
    },
    closeGroupFeedbacksWindow() {
      this.isOpenedGroupFeedbacksWindow = false
      this.isOpenedDataWindow = false
    },
    refleshSelectedClassRecords() {
      this.selectedClassRecords = this.classRecords.filter(oneClass =>
        this.selectedClassIds.includes(oneClass.id)
      )
    },
    refleshSelectedMeetingRecords() {
      this.selectedMeetingRecords = this.meetingRecords.filter(oneClass =>
        this.selectedClassIds.includes(oneClass.id)
      )
    },
    downloadCSVFile(dataset) {
      const bom = new Uint8Array([0xef, 0xbb, 0xbf])
      const blob = new Blob([bom, dataset.data], { type: 'text/csv' })
      const url = (window.URL || window.webkitURL).createObjectURL(blob)
      const download = document.createElement('a')
      download.href = url
      download.download = dataset.fileName
      download
        .click()(window.URL || window.webkitURL)
        .revokeObjectURL(url)
    },
  },
}
</script>

<style scoped></style>
