<template>
  <ValidationProvider
    v-slot="{ errors, valid }"
    :name="$attrs.label"
    :rules="rules"
    :vid="vid"
    :mode="mode"
  >
    <v-textarea
      v-model="innerValue"
      :error-messages="errors"
      :success="valid"
      v-bind="$attrs"
      counter
      flat
      outlined
      dense
      :rows="rows"
      v-on="$listeners"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
  },
  props: {
    rules: {
      type: [Object, String],
      default: '',
    },
    // must be included in props
    rows: {
      type: String,
      required: true,
    },
    value: {
      type: null,
      default: '',
    },
    vid: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'eager',
    },
  },
  data: () => ({
    innerValue: '',
  }),
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal)
    },
    value(newVal) {
      this.innerValue = newVal
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },
}
</script>
